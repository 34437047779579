import { useEffect } from "react";
import { Spinner } from "react-bootstrap";

import "./UserMessages.scss";
import { useReactMediaRecorder } from "react-media-recorder";
import { MicIcon, StopIcon } from "@/assets/images/svg/Icons";
import { Control } from "react-hook-form";
import { FormValues } from "./UserMessages";
import TextField from "@/components/Form/TextField";

const AddMessage = ({
  setMediaBlobUrl,
  setClearAudioCallback,
  control,
}: {
  setMediaBlobUrl: (url: string | null) => void;
  setClearAudioCallback: (callback: () => void) => void;
  control: Control<FormValues>;
}) => {
  const { status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl } =
    useReactMediaRecorder({ audio: true });

  useEffect(() => {
    if (mediaBlobUrl) {
      setMediaBlobUrl(mediaBlobUrl);
    }
  }, [mediaBlobUrl, setMediaBlobUrl]);

  const handleClearAudio = () => {
    clearBlobUrl();
    setMediaBlobUrl(null);
  };

  useEffect(() => {
    setClearAudioCallback(() => handleClearAudio);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setClearAudioCallback]);

  return (
    <>
      {status === "recording" && (
        <div className="form-group">
          <div className="form-field audio-field">
            <div className="recording__wrapper">
              <div className="recording__wrapper--spinners">
                <Spinner animation="grow" variant="light" size="sm" />{" "}
                <Spinner animation="grow" variant="light" size="sm" />{" "}
                <Spinner animation="grow" variant="light" size="sm" />
              </div>

              <button
                className="icon-btn stop"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  stopRecording();
                }}
              >
                <StopIcon />
              </button>
            </div>
          </div>
        </div>
      )}

      {mediaBlobUrl && (
        <div className="form-group">
          <div className="form-field audio-field">
            <div className="audio-wrapper">
              <audio src={mediaBlobUrl} controls />

              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleClearAudio();
                }}
                className="btn btn-danger"
              >
                Usuń
              </button>
            </div>
          </div>
        </div>
      )}

      {!mediaBlobUrl && status !== "recording" && (
        <TextField
          control={control}
          name="text"
          label=""
          placeholder="Aa"
          rows={2}
          textarea
          startIcon={
            <button
              className="icon-btn"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                startRecording();
              }}
            >
              <MicIcon />
            </button>
          }
        />
      )}
    </>
  );
};

export default AddMessage;
