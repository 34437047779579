import { ActionWPTypes, ActionUserTypes } from "@/actions/types";
import {
  UpdatePostAction,
  UpdateUserAction,
  PublishPostAction,
  DeletePostAction,
  TestingWPAction,
  FetchGoalsAction,
  FetchGoalAction,
  FetchPlansAction,
  FetchPlanAction,
  FetchProductsAction,
  FetchMessagesAction,
  Goal,
  Plan,
  IMessage,
  PostMessageAction,
  FetchUsersAction,
  FetchCurrentUserAction,
  User,
  Product,
} from "@/actions/wp";

type Actions =
  | FetchCurrentUserAction
  | UpdatePostAction
  | UpdateUserAction
  | PublishPostAction
  | DeletePostAction
  | TestingWPAction
  | FetchGoalsAction
  | FetchGoalAction
  | FetchPlansAction
  | FetchProductsAction
  | FetchMessagesAction
  | FetchPlanAction
  | PostMessageAction
  | FetchUsersAction;

export interface InitialState {
  currentUser?: User;
  goals: Goal[];
  goal?: Goal;
  plans: Plan[];
  products?: Product[];
  messages?: IMessage[];
  plan?: Plan;
  users?: User[];
  product?: Product;
}

export const initialState: InitialState = {
  goals: [],
  plans: [],
  products: [],
};

const wpReducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case ActionWPTypes.fetchCurrentUser:
      return { ...state, currentUser: action.payload };
    case ActionWPTypes.fetchGoals:
      return { ...state, goals: action.payload, goal: undefined };
    case ActionWPTypes.fetchProducts:
      return { ...state, products: action.payload, product: undefined };
    case ActionWPTypes.fetchMessages:
      return { ...state, messages: action.payload };
    case ActionWPTypes.postMessage:
      return { ...state, messages: [] };
    case ActionWPTypes.fetchGoal:
      return { ...state, goal: action.payload };
    case ActionWPTypes.fetchUsers:
      return { ...state, users: action.payload };
    case ActionWPTypes.fetchPlans:
      return { ...state, plans: action.payload, plan: undefined };
    case ActionWPTypes.fetchPlan:
      return { ...state, plan: action.payload };
    case ActionUserTypes.updateUser:
      return { ...state, currentUser: action.payload };
    default:
      return state;
  }
};

export default wpReducer;
