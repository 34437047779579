import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Container } from "react-bootstrap";
import { createUser, NewUserData, StepData } from "@/actions/wp";
import Constants from "@/constants";
import { initialValues } from "./data";
import * as CryptoJS from "crypto-js";
import SignUpSteps from "../SignUpSteps/SignUpSteps";
import StepFour from "./elements/steps/StepFour";
import StepOne from "./elements/steps/StepOne";
import StepThree from "./elements/steps/StepThree";
import StepTwo from "./elements/steps/StepTwo";
import { BackIcon } from "@/assets/images/svg/Icons";
import Loader from "@/components/Loader/Loader";
import FinalStep from "./elements/steps/FinalStep";
import { RegistrationValidationSchema } from "@/utils/validators";
import { createPrzelewy24Transaction, createSubscription } from "./helpers";
import { useCreateOrderHandler } from "@/hooks/useCreateOrderHandler";
import { FormProvider, Resolver, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const discountCodes = ["fittrain", "zdrowycatering"];

const AddUserForm: React.FC = () => {
  const dispatch = useDispatch();
  const [paymentSubmitting, setPaymentSubmitting] = useState(false);
  const [medipaySubmitting, setMedipaySubmitting] = useState(false);
  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState<StepData>(initialValues.stepData);

  const { handleCreateOrder, isError } = useCreateOrderHandler();

  const methods = useForm<NewUserData>({
    defaultValues: initialValues,
    resolver: yupResolver(
      RegistrationValidationSchema[step - 1],
    ) as unknown as Resolver<NewUserData>,
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    trigger,
    getValues,
    formState: { isSubmitting },
  } = methods;

  const nextStep = async (
    e: React.MouseEvent<HTMLButtonElement>,
    values: NewUserData,
  ) => {
    e.preventDefault();
    e.stopPropagation();

    const allowedProductMetaKeys = [1654, 1655];
    const hasAllowedProductMeta = Object.keys(
      values.productMetaData || {},
    ).some((key) => allowedProductMetaKeys.includes(Number(key)));

    if (hasAllowedProductMeta) {
      setStep(4);
    } else {
      const isValid = await trigger();

      if (isValid) {
        setStep(step + 1);
      }
    }
  };

  const prevStep = (values: NewUserData) => {
    const allowedProductMetaKeys = [1654, 1655];
    const hasAllowedProductMeta = Object.keys(
      values.productMetaData || {},
    ).some((key) => allowedProductMetaKeys.includes(Number(key)));

    if (step === 4 && hasAllowedProductMeta) {
      setStep(2);
    } else {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [step]);

  const handleStepDataChange = (newData: Partial<StepData>) => {
    setUserData({ ...userData, ...newData });
  };

  const fetchCartData = async (cartKey: any) => {
    const response = await fetch(
      `${Constants.baseUri}/wp-json/cocart/v2/cart/?cart_key=${cartKey}`,
    );
    if (!response.ok) throw new Error("Problem z pobraniem danych koszyka");
    return await response.json();
  };

  const handleMediPay = async (values: NewUserData) => {
    setMedipaySubmitting(true);

    const discountCode = values.discount?.toLowerCase();

    if (discountCode && !discountCodes.includes(discountCode)) {
      alert("Kod rabatowy nie istnieje");

      setMedipaySubmitting(false);
      return;
    }

    try {
      dispatch(
        createUser(values, async (res) => {
          if (res.status >= 300) {
            console.error("User creation failed:", res);
            setMedipaySubmitting(false);
            alert("Failed to create user.");
            return;
          }

          const userId = res.data.id;
          const cartKey = values.cartKey;

          const cartData = await fetchCartData(cartKey);

          const createdOrder = await handleCreateOrder({
            userId,
            payment: { method_name: "mediPay", method_title: "mediPay" },
            cartData,
            userData: values,
          });

          if (!createdOrder || isError) {
            setMedipaySubmitting(false);
            alert("Failed to create order.");
            return;
          }

          const subscriptionResponse = await createSubscription(
            userId,
            createdOrder.order_key,
            createdOrder.id,
            createdOrder.line_items,
            values,
          );

          if (subscriptionResponse.error) {
            console.error(
              "Błąd podczas tworzenia subskrypcji:",
              subscriptionResponse.data,
            );

            setMedipaySubmitting(false);
            return;
          }

          let amount = subscriptionResponse.data.total;

          if (discountCodes.includes(discountCode)) {
            amount *= 0.9;
          }

          amount = Math.round(amount);

          const mediPayData = {
            userId: userId,
            firstName: values.first_name,
            lastName: values.last_name,
            phoneNumber: values.phone,
            amount,
            callbackUrl: `${Constants.baseUri}/wp-json/myapp/v1/mediPayCallback`,
            webhookUrl: `${window.location.origin}/dziekujemy?externalId=HEAB-${userId}-${Date.now()}`,
          };

          const mediPayResponse = await fetch(
            `${Constants.baseUri}/wp-json/myapp/v1/mediPayPayment`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(mediPayData),
            },
          );

          const mediPayResult = await mediPayResponse.json();

          if (!mediPayResponse.ok || mediPayResult.error) {
            console.error("MediPay Error:", mediPayResult);
            setMedipaySubmitting(false);
            alert(
              `Błąd MediPay: ${mediPayResult.error === "transRejected" ? "Wniosek został odrzucony, klient nie może dostać finansowania" : mediPayResult.error || "Nieznany błąd"}`,
            );
            return;
          }

          if (mediPayResult.clientLink) {
            window.location.href = mediPayResult.clientLink;
          } else {
            setMedipaySubmitting(false);
            alert("Błąd: Brak linku do płatności.");
          }
        }),
      );
    } catch (error) {
      console.error("Błąd w handleMediPay:", error);
      setMedipaySubmitting(false);
      alert("Wystąpił błąd podczas przetwarzania MediPay.");
    }
  };

  const onSubmit = async (values: NewUserData) => {
    setPaymentSubmitting(true);
    try {
      const discountCode = values.discount?.toLowerCase() as string;

      if (discountCode && !discountCodes.includes(discountCode)) {
        alert("Kod rabatowy nie istnieje");
        setPaymentSubmitting(false);

        return;
      }

      dispatch(
        createUser(values, async (res) => {
          if (res.status < 300) {
            const userId = res.data.id;
            const cartKey = values.cartKey;

            const cartData = await fetchCartData(cartKey);

            const createdOrder = await handleCreateOrder({
              userId,
              payment: { method_name: "p24", method_title: "Przelewy24" },
              cartData,
              userData: values,
            });

            if (createdOrder && !isError) {
              const subscriptionResponse = await createSubscription(
                userId,
                createdOrder.order_key,
                createdOrder.id,
                createdOrder.line_items,
                values,
              );

              if (!subscriptionResponse.error) {
                let sessionId: string = `${subscriptionResponse.data.order_key}`;
                let merchantId: number = 235900;
                let amount: number = Math.round(
                  subscriptionResponse.data.total * 100,
                );
                let currency: string = "PLN";
                let crc: string = "929c4f4c66ca54eb";

                let obj = {
                  sessionId: sessionId,
                  merchantId: merchantId,
                  amount: amount,
                  currency: currency,
                  crc: crc,
                };

                if (discountCodes.includes(discountCode)) {
                  amount = Math.ceil(amount * 0.9);
                  amount = Math.ceil(amount / 100) * 100;
                }

                let jsonStr = JSON.stringify(obj);
                let hash = CryptoJS.SHA384(jsonStr);
                let hashedStr = hash.toString(CryptoJS.enc.Hex);

                const transactionData = {
                  merchantId: 235900,
                  posId: 235900,
                  sessionId: sessionId,
                  amount: amount,
                  currency: "PLN",
                  description: sessionId,
                  email: values.email,
                  country: "PL",
                  language: "pl",
                  urlReturn: `https://healthandbodyacademy.com/dziekujemy?id=${createdOrder.id}&sessionId=${sessionId}`,
                  sign: hashedStr,
                };

                await createPrzelewy24Transaction(transactionData);

                setStep(5);
                reset();
                setPaymentSubmitting(false);
              } else {
                console.error(
                  "Błąd podczas tworzenia subskrypcji:",
                  subscriptionResponse.data,
                );
              }
            } else {
              console.error("Błąd podczas tworzenia zamówienia:", createdOrder);
            }
          } else {
            console.error("Błąd podczas tworzenia użytkownika:", res);
          }
        }),
      );
    } catch (error) {
      console.error("Error:", error);
      setPaymentSubmitting(false);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <StepOne onStepDataChange={handleStepDataChange} />;
      case 2:
        return <StepTwo onStepDataChange={handleStepDataChange} />;
      case 3:
        return <StepThree onStepDataChange={handleStepDataChange} />;
      case 4:
        return <StepFour />;
      case 5:
        return <FinalStep />;
      default:
        return null;
    }
  };

  return (
    <Container className="form-register__container">
      <SignUpSteps currentStep={step} />

      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="form-inner"
          data-step={step}
        >
          {renderStep()}

          {step !== 5 ? (
            <div className="form-buttons text-center">
              <Button
                variant="secondary"
                type="button"
                onClick={() => prevStep(getValues())}
                disabled={step <= 1}
              >
                <BackIcon />
                Wróć
              </Button>

              {step < 4 ? (
                <Button
                  disabled={
                    (step === 2 && !getValues().stepData.plan) ||
                    (step === 3 && !getValues().stepData.goal)
                  }
                  variant="primary"
                  type="button"
                  onClick={async (e: React.MouseEvent<HTMLButtonElement>) => {
                    const values = getValues();
                    nextStep(e, values);
                  }}
                >
                  Dalej
                </Button>
              ) : (
                <>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={paymentSubmitting || medipaySubmitting}
                  >
                    {paymentSubmitting ? (
                      <Loader />
                    ) : isSubmitting ? (
                      "TWORZENIE..."
                    ) : (
                      "Przelewy24"
                    )}
                  </Button>

                  <Button
                    className="mediPay-btn"
                    variant="primary-blue"
                    type="button"
                    onClick={async () => {
                      const isValid = await trigger();

                      if (isValid) {
                        const values = getValues();
                        handleMediPay(values);
                      }
                    }}
                    disabled={paymentSubmitting || medipaySubmitting}
                    style={{
                      pointerEvents: medipaySubmitting ? "none" : "initial",
                    }}
                  >
                    {medipaySubmitting ? <Loader variant="light" /> : "MediPay"}
                  </Button>

                  <h5>Utwórz konto i zapłać</h5>
                </>
              )}
            </div>
          ) : (
            <div className="wr-buttons-final-step">
              <Link to="/">
                <Button variant="secondary" className="btn-final-primary">
                  STRONA GŁOWNA
                </Button>
              </Link>
              <Link to="/logowanie">
                <Button variant="primary">PANEL KLIENTA</Button>
              </Link>
            </div>
          )}
        </form>
      </FormProvider>
    </Container>
  );
};

export default AddUserForm;
