import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { Form, InputGroup } from "react-bootstrap";
import { ReactNode } from "react";
import "./InputField.scss";

const disallowedFieldNumberValues = ["-", "+", ".", "e", "E"];

export type UseFormProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object,
> = Omit<
  React.InputHTMLAttributes<HTMLInputElement & HTMLSelectElement>,
  "size"
> &
  React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    name: Path<TFieldValues>;
    label: ReactNode;
    control: Control<TFieldValues, TContext>;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    type?: "password" | "email" | "text" | "number";
    select?: boolean;
    textarea?: boolean;
    clearable?: boolean;
    readOnly?: boolean;
    multiSelect?: boolean;
    helperText?: ReactNode;
    required?: boolean;
    size?: "sm" | "lg";
    fullwidth?: boolean;
    labelIcon?: ReactNode;
  };

const TextField = <TFieldValues extends FieldValues, TContext extends object>({
  name,
  control,
  label,
  startIcon,
  endIcon,
  select,
  textarea,
  children,
  clearable,
  helperText,
  multiSelect,
  required,
  size = "lg",
  fullwidth = false,
  labelIcon,
  ...rest
}: UseFormProps<TFieldValues, TContext>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Form.Group className="form-group">
          <div className={`form-label__wrapper${labelIcon ? " has-icon" : ""}`}>
            {labelIcon && <div className="svg-container">{labelIcon}</div>}

            {label && (
              <Form.Label>{required ? `${label} *` : label}</Form.Label>
            )}
          </div>

          <InputGroup className={`form-field`}>
            {startIcon && <div className="icon-wrapper left">{startIcon}</div>}

            {select ? (
              <Form.Select
                onChange={onChange}
                value={value}
                size={size}
                {...rest}
              >
                {clearable && <option value="">Wyczyść</option>}

                {children}
              </Form.Select>
            ) : textarea ? (
              <Form.Control
                as="textarea"
                onChange={onChange}
                value={Array.isArray(value) ? value.join(", ") : value || ""}
                size={size}
                {...rest}
              />
            ) : (
              <Form.Control
                type={rest.type}
                onChange={onChange}
                value={Array.isArray(value) ? value.join(", ") : value || ""}
                onKeyDown={(event) => {
                  if (
                    rest.type === "number" &&
                    disallowedFieldNumberValues.includes(event.key)
                  ) {
                    event.preventDefault();
                  }
                }}
                size={size}
                {...rest}
              />
            )}

            {endIcon && <div className="icon-wrapper right">{endIcon}</div>}
          </InputGroup>

          {error && (
            <Form.Text className="error-text">{error.message}</Form.Text>
          )}

          {helperText && !error && (
            <Form.Text className="d-block form-field__dsc">
              {helperText}
            </Form.Text>
          )}
        </Form.Group>
      )}
    />
  );
};

export default TextField;
