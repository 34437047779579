import { FileDockIcon } from "@/assets/images/svg/Icons";
import TrainerRaports from "@/components/TrainerRaports/TrainerRaports";
import "./ClientRaports.scss";
import { useGetReportsQuery } from "@/api/plansApi";
import Loader from "@/components/Loader/Loader";
import { Alert } from "react-bootstrap";

interface IClientRaports {
  userId: number;
}

const ClientRaports: React.FC<IClientRaports> = ({ userId }) => {
  const { data: reports, isLoading, isError } = useGetReportsQuery(userId);

  return (
    <div className="user__raports raports">
      <span className="raports__amount">
        <FileDockIcon />
        Raporty ({reports?.length})
      </span>

      {isLoading && <Loader />}

      {isError && <Alert variant="danger">Wystąpił błąd</Alert>}

      {reports && !isLoading && !isError && (
        <TrainerRaports
          reports={reports}
          isPending={isLoading}
          displayName={false}
        />
      )}
    </div>
  );
};

export default ClientRaports;
