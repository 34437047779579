import { Link } from "react-router-dom";
import { formatDate } from "@/functions/formatDate";
import { EyeIcon } from "@/assets/images/svg/Icons";
import links from "@/constants/links";
import { FilteredClientType } from "./TrainerClients";

const renderPackageClassName = (packageName?: string) => {
  switch (packageName) {
    case "SUPER CHARGED":
      return "super-charged";
    case "X-TREME":
      return "xtreme";
    default:
      return "";
  }
};

interface ClientSubscriptionProps {
  client: FilteredClientType;
}

const ClientSubscription: React.FC<ClientSubscriptionProps> = ({ client }) => {
  const {
    last_payment_date_gmt,
    next_payment_date_gmt,
    needs_payment,
    line_items,
  } = client.subscription;

  return (
    <div className="list_item">
      <span className="d-block d-flex align-items-center">
        <span className="info__mobile">Imię i nazwisko:</span>

        <span>{client.name}</span>
      </span>

      <time className="d-block d-flex align-items-center">
        <span className="info__mobile">Dołączył/a:</span>

        <time>{formatDate(client.user_registered)}</time>
      </time>

      <span className="d-block d-flex align-items-center">
        <span className="info__mobile">Pakiet:</span>

        <div
          className={`package ${renderPackageClassName(line_items?.[0]?.name)}`}
        >
          {line_items?.[0]?.name}
        </div>
      </span>

      <span className="d-block d-flex align-items-center">
        <span className="info__mobile">Pakiet wygasa:</span>

        {client.subscription.id ? (
          <span
            className={`item_title ${
              needs_payment ? "item_title--expired" : ""
            }`}
          >
            {needs_payment
              ? formatDate(last_payment_date_gmt)
              : formatDate(next_payment_date_gmt)}
          </span>
        ) : (
          <span>Brak pakietu</span>
        )}
      </span>

      <div className="d-flex justify-content-between align-items-center item__col item__col--right list_item__details">
        <Link
          to={`${links.trainerClients}/${client.id}`}
          className="p-0 text-decoration-none"
        >
          <span>podgląd</span>

          <EyeIcon />
        </Link>
      </div>
    </div>
  );
};

export default ClientSubscription;
