import * as yup from "yup";

yup.setLocale({
  mixed: {
    required: "To pole jest wymagane",
  },
  string: {
    email: "Nieprawidłowy format email",
    min: ({ min }) => `Minimum ${min} znaków`,
    max: ({ max }) => `Maksymalnie ${max} znaków`,
    matches: "Hasła nie zgadzają się",
    length: ({ length }) => `Wymagana ilość znaków: ${length}`,
  },
});

export default yup;
