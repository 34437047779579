import { FC } from "react";
import { StepProps } from "../../interfaces";
import Goals from "@/components/Goals/Goals";
import { NewUserData } from "@/actions/wp";
import { useFormContext } from "react-hook-form";

const StepThree: FC<StepProps> = () => {
  const { setValue } = useFormContext<NewUserData>();

  const handleClickGoal = (name: string) => {
    setValue("stepData.goal", name);
  };

  return (
    <div>
      <h2 className="form-heading text-center">CO CHCESZ OSIĄGNĄĆ?</h2>
      <Goals onClick={handleClickGoal} />
    </div>
  );
};

export default StepThree;
