import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import "./DetailsForm.scss";
import { User, updateUser } from "@/actions/wp";
import { useForm } from "react-hook-form";
import TextField from "@/components/Form/TextField";
import {
  InfoIcon,
  HeightIcon,
  ScaleIcon,
  BirthdayIcon,
  BoneIcon,
  RestaurantFillIcon,
} from "@/assets/images/svg/Icons";

interface IDetailsForm {
  currentUser: User;
}

const DetailsForm: React.FC<IDetailsForm> = ({ currentUser }) => {
  const dispatch = useDispatch();

  const initialValues = {
    age: currentUser.meta.age || ("" as unknown as number),
    height: currentUser.meta.height || ("" as unknown as number),
    weight: currentUser.meta.weight || ("" as unknown as number),
    injury: currentUser.meta.injury || "",
    preferences: currentUser.meta.preferences || "",
    important: currentUser.meta.important || "",
  };

  const handleUpdateUser = (values: typeof initialValues) => {
    const updatedUserData = {
      meta: {
        age: values.age,
        height: values.height,
        weight: values.weight,
        injury: values.injury,
        preferences: values.preferences,
        important: values.important,
      },
    };

    dispatch(updateUser(currentUser.id.toString(), updatedUserData));
  };

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<typeof initialValues>({
    defaultValues: initialValues,
  });

  return (
    <form onSubmit={handleSubmit(handleUpdateUser)} className="form">
      <div className="form__wrapper">
        <TextField
          control={control}
          type="number"
          name="height"
          label="WZROST"
          labelIcon={<HeightIcon />}
          placeholder={`${currentUser ? currentUser.meta.height : "[CM]"}`}
        />

        <TextField
          control={control}
          type="number"
          name="weight"
          label="WAGA bieżąca"
          labelIcon={<ScaleIcon />}
          placeholder="[KG]"
        />

        <TextField
          control={control}
          type="number"
          name="age"
          label="WIEK"
          labelIcon={<BirthdayIcon />}
          placeholder="[LATA]"
        />

        <TextField
          control={control}
          name="injury"
          label="Kontuzje"
          labelIcon={<BoneIcon />}
          placeholder="Wpisz Twoje kontuzje"
          helperText="Tutaj wypisz wszystkie kontuzje, które mogą mieć wpływ na treningi."
        />

        <TextField
          control={control}
          name="preferences"
          label="PREFERENCJE ŻYWNOŚCIOWE"
          labelIcon={<RestaurantFillIcon />}
          placeholder="Wpisz Twoje preferencje"
          helperText="Jeśli jesteś wegetarianinem, weganem lub preferujesz konkretny rodzaj diety - to dobre miejsce aby o tym wspomnieć."
        />

        <TextField
          control={control}
          name="important"
          label="WAŻNE"
          labelIcon={<InfoIcon />}
          helperText="W tym polu wpisz wszystkie ważne informacje dotyczące Twoich preferencji, ograniczeń motorycznych lub żywieniowych np. uczulenia, uszkodzenia ciała itp."
          rows={3}
          textarea
        />
      </div>

      <Button variant="secondary" type="submit" disabled={isSubmitting}>
        {isSubmitting ? "Trwa zapisywanie..." : "Zapisz zmiany"}
      </Button>
    </form>
  );
};

export default DetailsForm;
