import React from "react";
import { Product } from "@/actions/wp";
import Item from "./Item";

type PlanListProps = {
  products: Product[];
  selectedId?: number;
  addToCart: (productId: number) => void;
};

const ProductList: React.FC<PlanListProps> = ({
  products,
  selectedId,
  addToCart,
}) => {
  return (
    <>
      {products.map((product) => {
        return (
          <Item
            product={product}
            key={product.id}
            selectedId={selectedId}
            addToCart={addToCart}
          />
        );
      })}
    </>
  );
};

export default ProductList;
