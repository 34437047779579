import AddUserForm from "./AddNew/AddUserForm";
import "./SignUp.scss";
import RootLayout from "@/layouts/RootLayout";

const SignUp = () => {
  return (
    <RootLayout>
      <section className="position-relative overflow-hidden form-register">
        <AddUserForm />
      </section>
    </RootLayout>
  );
};

export default SignUp;
