import { ChangeEvent, useState } from "react";
import { SearchIcon } from "@/assets/images/svg/Icons";
import Loader from "@/components/Loader/Loader";
import NiceSelect from "@/components/NiceSelect/NiceSelect";
import Pagination from "@/components/Pagination/Pagination";
import TrainerClients from "@/components/TrainercClients/TrainerClients";
import { useWpSelector } from "@/selectors";
import "./TrainerClientsPage.scss";
import { useGetClientsQuery } from "@/api/plansApi";
import { Alert } from "react-bootstrap";
import { useGetClientsSubscriptionsQuery } from "@/api/subscriptionsApi";
import { useDebounce } from "use-debounce";

const TrainerClientsPage = () => {
  const { currentUser } = useWpSelector();
  const [searchValue, setSearchValue] = useState("");
  const [sortValue, setSortValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [search] = useDebounce(searchValue, 500);

  const {
    data: clients,
    isError: clientError,
    isLoading: clientsLoading,
    status,
  } = useGetClientsQuery({
    page: currentPage,
    perPage: itemsPerPage,
    search: search,
    sort: sortValue,
  });

  const {
    data: subscriptionsData,
    isError: subError,
    isLoading: subFetching,
  } = useGetClientsSubscriptionsQuery({ page: 1, perPage: 100 });

  const handleSearchClient = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 2 || searchValue) {
      setSearchValue(e.target.value);
      setCurrentPage(1);
    }
  };

  const handleSortChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSortValue(e.target.value);
  };

  const handleClickPage = (page: number) => {
    setCurrentPage(page);
  };

  const isLoading = clientsLoading || subFetching;
  const isError = clientError || subError;
  const isData = !clientsLoading && !subFetching && !clientError && !subError;

  return (
    <div className="dashboard__reports">
      <div className="reports">
        <div className="reports__content">
          <header className="reports__content__header">
            <div className="reports__content__col reports__content__col--left">
              <div className="input_search">
                <input
                  type="text"
                  placeholder="Wyszukaj klienta"
                  onChange={handleSearchClient}
                />
                <SearchIcon />
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center reports__content__col reports__content__col--right">
              <span>Sortowanie:</span>
              <NiceSelect
                id="a-select"
                placeholder="Od najnowszych"
                className="reports__select"
                onChange={handleSortChange}
              >
                <option value="plan">Rodzaj pakietu</option>
                <option value="newest">Od najnowszych</option>
              </NiceSelect>
            </div>
          </header>
          <div className="reports__content__body cust__list">
            {isLoading && <Loader />}

            {isError && <Alert variant="danger">Wystąpił błąd</Alert>}

            {isData && (
              <TrainerClients
                clients={clients?.users || []}
                subscriptions={subscriptionsData || []}
                currentUser={currentUser}
                isPending={status === "pending"}
              />
            )}
          </div>
        </div>
      </div>
      <Pagination
        onNextPage={() => setCurrentPage((prev) => prev + 1)}
        onPrevPage={() => setCurrentPage((prev) => prev - 1)}
        currentPage={currentPage}
        onClickPage={handleClickPage}
        itemsQuantityAvaliabl={[5, 10, 15]}
        onClickItemsQuantity={(quantity) => {
          setItemsPerPage(quantity);
          setCurrentPage(1);
        }}
        quantityPerPage={itemsPerPage}
        totalPages={clients?.total_pages || 1}
      />
    </div>
  );
};

export default TrainerClientsPage;
