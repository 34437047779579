import React from "react";
import { Card } from "react-bootstrap";
import { Goal } from "@/actions/wp";
import { PointerIcon } from "@/assets/images/svg/Icons";

type ItemProps = {
  goal: Goal;
  isActive?: any;
  onClick: (name: string) => void;
  formValues: any;
};

const Item: React.FC<ItemProps> = React.memo(
  ({ goal, onClick, formValues }) => {
    const icon = goal?.acf.cpt_goals.icon;
    const list = goal?.acf.cpt_goals.list;

    const handleCardClick = (goalId: number) => {
      onClick(goal?.id.toString());
    };

    const cardClass =
      +formValues.goal === goal.id
        ? "position-relative border-0 is-active"
        : "position-relative border-0";

    return (
      <Card
        className={cardClass}
        tabIndex={1}
        onClick={() => handleCardClick(goal.id)}
      >
        <Card.Body>
          <header className="d-flex justify-content-between align-items-center font-italic py-0 border-0 card-header">
            <h3 className="m-0 card-title">{goal?.title.rendered}</h3>
            <img src={icon} alt={goal?.title.rendered} />
          </header>
          <div className="card-content">
            <ul className="card-list">
              {list.map((el: any, idx: number) => {
                return (
                  <li className="position-relative" key={idx}>
                    <div className="position-absolute d-flex align-items-center svg-container">
                      <PointerIcon />
                    </div>
                    <span
                      className="d-block"
                      dangerouslySetInnerHTML={{ __html: el.item }}
                    ></span>
                  </li>
                );
              })}
            </ul>
          </div>
        </Card.Body>
      </Card>
    );
  }
);

export default Item;
