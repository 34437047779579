import React from "react";
import ReactDOM from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Root from "./Root";
import "./index.css";
import TabFocusHandler from "./assets/ts/TabFocusHandler";
import PrivateRoute from "./components/auth/PrivateRoute";
import PublicRoute from "./components/auth/PublicRoute";
import links from "./constants/links";
import Admin from "./pages/Admin/Admin";
import HomePage from "./pages/HomePage/HomePage";
import NotFoundPage from "./pages/NotFount/NotFoundPage";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import BlogPage from "./pages/BlogPage/BlogPage";
import Blogs from "./pages/BlogPage/Blogs";
import Blog from "./pages/BlogPage/Blog";
import FaqPage from "./pages/FaqPage/FaqPage";
import AboutUsPage from "./pages/AboutUsPage/AboutUsPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import MetamorphosesPage from "./pages/MetamorphosesPage/MetamorphosesPage";
import PackagesPage from "./pages/PackagesPage/PackagesPage";
import TermsPage from "./pages/TermsPage/TermsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage/PrivacyPolicyPage";
import RulesPage from "./pages/RulesPage/RulesPage";
import CookiesPage from "./pages/CookiesPage/CookiesPage";
import ThankYou from "./pages/ThankYou/ThankYou";
import ScrollToTop from "./components/ScrollTop/ScrollTop";

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <Root>
    <TabFocusHandler />
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <PublicRoute path={links.signIn}>
          <SignIn />
        </PublicRoute>
        <PublicRoute path={links.signUp}>
          <SignUp />
        </PublicRoute>
        {/* Page routes */}
        <Route path={links.thankYou} exact component={ThankYou} />
        <Route path={links.cookiesPolicy} exact component={CookiesPage} />
        <Route path={links.rules} exact component={RulesPage} />
        <Route path={links.privacyPolicy} exact component={PrivacyPolicyPage} />
        <Route path={links.terms} exact component={TermsPage} />
        <Route path={links.blog} exact component={BlogPage} />
        <Route path="/blog/:slug" component={Blog} />
        <Route path={links.faq} exact component={FaqPage} />
        <Route path={links.aboutUs} exact component={AboutUsPage} />
        <Route path={links.contact} exact component={ContactPage} />
        <Route path={links.metamorphoses} exact component={MetamorphosesPage} />
        <Route path={links.packages} exact component={PackagesPage} />
        <Route path={links.home} exact component={HomePage} />
        {/* End - page routes */}
        <PrivateRoute path={links.dashboard}>
          <Admin />
        </PrivateRoute>
        <Route component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  </Root>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
