import React from "react";
import { useDispatch } from "react-redux";
import { Button, Form } from "react-bootstrap";
import "./SettingsForm.scss";
import { updateUser, User } from "@/actions/wp";
import { useForm } from "react-hook-form";
import TextField from "@/components/Form/TextField";
import { yupResolver } from "@hookform/resolvers/yup";
import { SettingsFormValidationSchema } from "@/utils/validators";

interface ISettingsForm {
  currentUser: User;
}

const SettingsForm: React.FC<ISettingsForm> = ({ currentUser }) => {
  const dispatch = useDispatch();

  const initialValues = {
    first_name: currentUser.first_name || "",
    last_name: currentUser.last_name || "",
    phone: (currentUser.meta.phone as unknown as string) || "",
    password: "",
    confirmPassword: "",
    gender: currentUser.meta.gender || "",
  };

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(SettingsFormValidationSchema),
  });

  const handleUpdateUser = (values: any) => {
    const fullName = `${values.first_name} ${values.last_name}`;

    const updatedUserData = {
      name: fullName,
      first_name: values.first_name,
      last_name: values.last_name,
      password: values.password || undefined,
      meta: {
        phone: values.phone,
        age: values.age,
        gender: values.gender,
        goal: values.goal,
        height: values.height,
        plan: values.plan,
        weight: values.weight,
      },
    };

    dispatch(updateUser(currentUser.id.toString(), updatedUserData));
  };

  const gender = watch("gender");

  return (
    <form onSubmit={handleSubmit(handleUpdateUser)} className="form">
      <div className="form__wrapper">
        <TextField
          control={control}
          type="text"
          name="first_name"
          label="IMIĘ"
          placeholder="[IMIĘ]"
        />

        <TextField
          control={control}
          type="text"
          name="last_name"
          label="NAZWISKO"
          placeholder="[NAZWISKO]"
        />

        <TextField
          control={control}
          type="number"
          name="phone"
          label="TELEFON"
          placeholder="[TELEFON]"
        />

        <Form.Group className="form-group-row form-group">
          <label className="form-label">Twoja płeć:</label>
          <div className="form-field d-flex overflow-hidden">
            <button
              type="button"
              className={
                gender === "Mężczyzna"
                  ? "form-button form-button--active"
                  : "form-button"
              }
              onClick={() => setValue("gender", "Mężczyzna")}
            >
              Mężczyzna
            </button>

            <button
              type="button"
              className={
                gender === "Kobieta"
                  ? "form-button form-button--active"
                  : "form-button"
              }
              onClick={() => setValue("gender", "Kobieta")}
            >
              Kobieta
            </button>
          </div>
        </Form.Group>

        <TextField
          control={control}
          type="password"
          name="password"
          label="ZMIEŃ HASŁO"
          placeholder="[NOWE HASŁO]"
        />

        <TextField
          control={control}
          type="password"
          name="confirmPassword"
          label="POWTÓRZ HASŁO"
          placeholder="[POWTÓRZ NOWE HASŁO]"
        />
      </div>

      <Button variant="secondary" type="submit" disabled={isSubmitting}>
        {isSubmitting ? "Trwa zapisywanie..." : "Zapisz zmiany"}
      </Button>
    </form>
  );
};

export default SettingsForm;
