import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { fetchGoal, fetchPlan, Goal, Plan, User } from "@/actions/wp";
import {
  SettingsIcon,
  BulbIcon,
  TargetIcon,
  PointerIcon,
  PackageIcon,
  CheckIcon,
  HumanIcon,
  HeightIcon,
  ScaleIcon,
  BirthdayIcon,
  BoneIcon,
  InfoIcon,
} from "@/assets/images/svg/Icons";
import { useWpSelector } from "@/selectors";
import links from "@/constants/links";
import { getPlanId } from "@/utils/plansIds";
import { Button } from "react-bootstrap";
import { CloseIconSmall } from "@/assets/images/svg/Icons";
import { isAfter, parse } from "date-fns";

const AccountInfo = () => {
  const { currentUser, goal, plan } = useWpSelector() as {
    goal: Goal;
    currentUser: User;
    plan: Plan;
  };

  const [isBanerOpen, setIsBanerOpen] = useState(
    JSON.parse(localStorage.getItem("info-baner") || "false"),
  );

  const setBanerClosed = () => {
    setIsBanerOpen(true);
    localStorage.setItem("info-baner", JSON.stringify(true));
  };

  const userRegistered = currentUser.user_registered;
  const userDate = parse(userRegistered, "dd.MM.yyyy", new Date());

  const comparisonDate = parse("31.03.2025", "dd.MM.yyyy", new Date());
  const isRegisteredLaterThanComparison = isAfter(userDate, comparisonDate);

  const dispatch = useDispatch();
  const avatarUrl =
    currentUser && currentUser.avatar_urls ? currentUser.avatar_urls[96] : null;

  useEffect(() => {
    if (currentUser && currentUser.meta.goal && currentUser.meta.plan) {
      dispatch(fetchGoal(currentUser.meta.goal));
      dispatch(fetchPlan(getPlanId(currentUser.meta.plan)));
    }
  }, [currentUser, dispatch]);

  const goalList = goal?.acf?.cpt_goals?.list;
  const planList = plan?.acf?.cpt_plans?.list;

  const isPlan30 =
    currentUser?.meta?.plan === "30" || currentUser?.meta?.plan === "185";

  return (
    <>
      <div className="dashboard__account account">
        <header className="account__header">
          <div className="d-flex align-items-start account__info">
            <div className="position-relative d-flex justify-content-center align-items-center account__avatar">
              {avatarUrl ? <img src={avatarUrl} alt="Account avatar" /> : "..."}
            </div>
            <div className="d-flex flex-column align-items-start account__data">
              <span className="account__name is-strong">
                <strong>{currentUser ? currentUser.name : "..."}</strong>
              </span>
              <span className="account__email">
                {currentUser ? currentUser.user_email : "..."}
              </span>
              <span className="account__created_at">
                <span>dołączyłeś/aś: </span>
                <time>{currentUser ? currentUser.user_registered : "..."}</time>
              </span>
              <Link to="/dashboard/ustawienia" className="account__settings">
                <span>Przejdź do ustawień</span>
                <SettingsIcon />
              </Link>
            </div>
          </div>
          <div className="account__tips">
            <div className="account__tips__wrapper">
              <header className="d-flex align-items-center account__tips__header">
                <div className="svg-container">
                  <BulbIcon />
                </div>
                <h3 className="font-italic mb-0 account__tips__heading">
                  CZY WIESZ, ŻE...
                </h3>
              </header>
              <div className="account__tips__content">
                <p className="mb-0">
                  Większość dorosłych potrzebuje około dwóch litrów płynów
                  dziennie, aby zastąpić normalną utratę wody (lub około ośmiu
                  szklanek wody dziennie).
                </p>
              </div>
            </div>
          </div>
        </header>

        {!isBanerOpen && !isPlan30 && isRegisteredLaterThanComparison && (
          <div className="account__header">
            <div className="info-baner">
              <div className="info-baner__wrapper">
                <div className="">
                  <h5 className="info-baner__wrapper--title">
                    Cześć {currentUser?.first_name},
                  </h5>

                  <p className="info-baner__wrapper--description">
                    Cieszę się, że wybrałeś moją platformę "Health and Body
                    Academy". <br></br>
                    <Link to={links.panelMessages}>
                      Przejdź teraz do zakładki wiadomości
                    </Link>{" "}
                    - czeka tam na Ciebie wprowadzenie do korzystania z
                    platformy oraz pierwsze zadanie!
                  </p>
                </div>

                <div className="info-baner__wrapper__close">
                  <Button variant="link" onClick={setBanerClosed}>
                    Przeczytałem, nie pokazuj więcej <CloseIconSmall />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="account__content">
          <div className="account__cards">
            <div className="border-0 card account-goal">
              <header className="d-flex align-items-center card__header">
                <div className="svg-container">
                  <TargetIcon />
                </div>
                <h3 className="text-uppercase font-italic mb-0 card__title">
                  TWÓJ CEL
                </h3>
                <Link to="/dashboard/twoj-cel" className="btn btn-secondary">
                  ZMIEŃ CEL
                </Link>
              </header>
              <div className="card__details">
                <div className="position-relative card__details__wrapper">
                  <h4 className="text-uppercase font-italic card__heading">
                    {goal ? goal?.title?.rendered : "..."}
                  </h4>
                  <div className="position-absolute goal-icon">
                    <img
                      src={goal ? goal?.acf?.cpt_goals?.icon : "..."}
                      alt={goal ? goal?.title?.rendered : "..."}
                    />
                  </div>
                  <ul className="p-0 list-unstyled card__list">
                    {goalList &&
                      goalList.map((el, idx) => {
                        return (
                          <li className="position-relative" key={idx}>
                            <div className="position-absolute d-flex align-items-center svg-container">
                              <PointerIcon />
                            </div>
                            <span
                              className="d-block"
                              dangerouslySetInnerHTML={{ __html: el.item }}
                            ></span>
                          </li>
                        );
                      })}
                  </ul>
                  <div className="goal-scores">
                    <div className="goal-scores__wrapper">
                      <div className="d-flex align-items-start card__details__info">
                        <span className="d-inline-block text-uppercase font-italic">
                          Start:
                        </span>
                        <span className="">
                          {currentUser ? currentUser.meta.weight : "..."}
                          &nbsp;kg
                        </span>
                      </div>
                      <div className="d-flex align-items-start card__details__info">
                        <span className="d-inline-block text-uppercase font-italic">
                          Teraz:
                        </span>
                        <span className="">
                          {currentUser
                            ? currentUser.meta.weight_current
                            : "..."}
                          &nbsp;kg
                        </span>
                      </div>
                      <div className="d-flex align-items-start card__details__info">
                        <span className="d-inline-block text-uppercase font-italic">
                          Cel:
                        </span>
                        <span className="">
                          {currentUser ? currentUser.meta.weight_target : "..."}
                          &nbsp;kg
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-0 card account-plan">
              <header className="d-flex align-items-center card__header">
                <div className="svg-container">
                  <PackageIcon />
                </div>
                <h3 className="text-uppercase font-italic mb-0 card__title">
                  TWÓJ PAKIET
                </h3>
                <Link to={links.panelPackages} className="btn btn-primary">
                  KUP PAKIET
                </Link>
              </header>
              <div className="card__details">
                <div className="card__details__wrapper">
                  <h4 className="text-center text-uppercase font-italic card__heading">
                    {plan ? plan?.title?.rendered : "..."}
                  </h4>
                  <strong className="d-block">
                    {plan ? plan?.acf?.cpt_plans?.text : "..."}
                  </strong>
                  <ul className="p-0 m-0 list-unstyled card__list">
                    {planList &&
                      planList.map((el, idx) => {
                        return (
                          <li className="position-relative" key={idx}>
                            <div className="position-absolute d-flex align-items-center svg-container">
                              <CheckIcon />
                            </div>
                            <span
                              className="d-block"
                              dangerouslySetInnerHTML={{ __html: el.item }}
                            ></span>
                          </li>
                        );
                      })}
                  </ul>
                  {/* <div className="text-center">
                    <Link to="#" className="d-inline-flex btn btn-secondary">
                      SZCZEGÓŁY PAKIETU
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="border-0 card account-body">
              <header className="d-flex align-items-center card__header">
                <div className="svg-container">
                  <HumanIcon />
                </div>
                <h3 className="text-uppercase font-italic mb-0 card__title">
                  TWOJE CIAŁO
                </h3>
                <Link to="/dashboard/twoje-cialo" className="btn btn-secondary">
                  AKTUALIZUJ
                </Link>
              </header>
              <div className="card__details">
                <div className="card__details__wrapper">
                  <div className="d-flex align-items-start card__details__info">
                    <HeightIcon />
                    <span className="d-inline-block text-uppercase font-italic">
                      <strong>Wzrost:</strong>
                    </span>
                    <span>
                      {currentUser ? currentUser.meta.height : "..."} cm
                    </span>
                  </div>
                  <div className="d-flex align-items-start card__details__info">
                    <ScaleIcon />
                    <span className="d-inline-block text-uppercase font-italic">
                      <strong>Waga:</strong>
                    </span>
                    <span>
                      {currentUser ? currentUser.meta.weight : "..."} kg
                    </span>
                  </div>
                  <div className="d-flex align-items-start card__details__info">
                    <BirthdayIcon />
                    <span className="d-inline-block text-uppercase font-italic">
                      <strong>Wiek:</strong>
                    </span>
                    <span>
                      {currentUser ? currentUser.meta.age : "..."}&nbsp;lat
                    </span>
                  </div>
                  <div className="d-flex align-items-start card__details__info">
                    <BoneIcon />
                    <span className="d-inline-block text-uppercase font-italic">
                      <strong>Kontuzje:</strong>
                    </span>
                    <span>{currentUser ? currentUser.meta.injury : "..."}</span>
                  </div>
                  <div className="d-flex align-items-start card__details__info">
                    <InfoIcon />
                    <span className="d-inline-block text-uppercase font-italic">
                      <strong>Ważne:</strong>
                    </span>
                    <span>
                      {currentUser ? currentUser.meta.important : "..."}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div>
            {userMedia.map(media => (
              <img key={media.id} src={media.source_url} alt={media.title.rendered} />
            ))}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default AccountInfo;
