import Loader from "@/components/Loader/Loader";
import axios from "axios";
import Constants from "@/constants";
import Button from "@/components/Buttons/LinkButton";
import ContactFormThanksModal from "@/components/ContactFormThanksModal/ContactFormThanksModal";
import { useForm } from "react-hook-form";
import { useToast } from "@/components/ToastContext/ToastContext";
import TextField from "@/components/Form/TextField";
import Checkbox from "@/components/Form/Checkbox";
import { yupResolver } from "@hookform/resolvers/yup";
import { ContactFormValidationSchema } from "@/utils/validators";
import { useToggle } from "@/hooks/useToggle";

const initialValues = {
  name: "",
  surname: "",
  email: "",
  phone: "",
  message: "",
  termsOne: false,
  termsTwo: false,
};

type FormValues = {
  name: string;
  surname: string;
  email: string;
  phone?: string | null;
  message: string;
  termsOne: boolean;
  termsTwo: boolean;
};

type Props = {
  formVariant?: "contact" | "faq";
};

const ContactUsForm = ({ formVariant }: Props) => {
  const token = localStorage.getItem("_wp_react_typescipt_token");
  const showSuccessModal = useToggle(false);
  const { enqueueToast } = useToast();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(ContactFormValidationSchema),
  });

  const submitForm = async (values: FormValues) => {
    await axios
      .post(
        `${Constants.apiUri}/send-email`,
        { ...values, to: "info@healthandbodyacademy.com" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((response) => {
        if (response.status < 300) {
          showSuccessModal.open();
          reset();
        }
      })
      .catch((_) =>
        enqueueToast({
          type: "error",
          message: "Wystąpił nieoczekiwany błąd. Spróbój ponownie później",
        }),
      );
  };

  return (
    <>
      <form onSubmit={handleSubmit(submitForm)} className="form__wrapper">
        <div className="form__wrapper_group">
          <TextField
            control={control}
            label="IMIĘ"
            name="name"
            placeholder="Twoje imię tutaj"
          />

          <TextField
            control={control}
            type="text"
            label="NAZWISKO"
            name="surname"
            placeholder="Twoje nazwisko tutaj"
          />
        </div>

        <div className="form__wrapper_group">
          <TextField
            control={control}
            type="email"
            label="E-MAIL"
            name="email"
            placeholder="np. jankowalski@poczta.pl"
          />

          <TextField
            control={control}
            type="number"
            label="TELEFON (OPCJONALNE)"
            name="phone"
            placeholder="Podaj 9-cyfrowy numer"
          />
        </div>

        <TextField
          control={control}
          name="message"
          label="TWOJA WIADOMOŚĆ"
          rows={5}
          maxLength={800}
          placeholder="Hej. Jestem zainteresowany treningiem z Tobą. Odzewij się do mnie, proszę najszybciej jak to możliwe."
          textarea
        />

        <Checkbox
          control={control}
          name="termsOne"
          label="Akceptuję regulamin"
        />

        <Checkbox
          control={control}
          name="termsTwo"
          label="Akceptuję politykę prywatności"
        />

        <div className="btn_wr">
          <Button
            variant="primary"
            submit={true}
            disabled={isSubmitting}
            type="button"
            color={formVariant === "contact" ? "blue" : "red"}
          >
            {isSubmitting ? <Loader variant="light" /> : "ZATWIERDŹ I WYŚLIJ"}
          </Button>
        </div>
      </form>

      <ContactFormThanksModal
        handleClose={showSuccessModal.close}
        showModal={showSuccessModal.isOpen}
      />
    </>
  );
};

export default ContactUsForm;
