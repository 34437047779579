import React from "react";
import "./HomePage.scss";
import DevelopmentCenterSection from "./sections/DevelopmentCenterSection";
import HeroSection from "./sections/HeroSection";
import HowItWorksSection from "./sections/HowItWorksSection";
import PackagesSection from "./sections/PackagesSection";
import WhyWorthSection from "./sections/WhyWorthSection";
import WhoAreWe from "./sections/WhoAreWe";
import GoogleOpinions from "./sections/GoogleOpinions";
import ResultsSlider from "./sections/ResultsSliderSection";
import BlogSection from "./sections/BlogSection";
import Cta from "@/components/Cta/Cta";
import OurPartners from "@/components/OurPartners/OurPartners";
import RootLayout from "@/layouts/RootLayout";

const HomePage = () => {
  return (
    <RootLayout>
      <main id="main">
        <HeroSection />
        <WhyWorthSection />
        <HowItWorksSection />
        <DevelopmentCenterSection />
        <PackagesSection />
        <GoogleOpinions />
        <ResultsSlider />
        <BlogSection />
        <WhoAreWe />
        <Cta />
        <OurPartners />
      </main>
    </RootLayout>
  );
};

export default HomePage;
