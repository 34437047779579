import PageHeading from "@/components/headings/PageHeading";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import blogsData from "@/pages/BlogPage/data/blog.json";
import "./BlogSection.scss";

interface BlogPost {
  slug: string;
  image: string;
  title: string;
  short_content: string;
  read_time: string;
}

const BlogSection = () => {
  return (
    <section className="blog">
      <div className="container">
        <div className="blog__wrapper">
          <header className="blog__header">
            <div className="header__content">
              <span className="d-block font-italic text-center">
                TRENUJ TEŻ UMYSŁ
              </span>
              <PageHeading variant="h2">WIEDZA I BLOG</PageHeading>
            </div>
          </header>
          <div className="my-0 blog-list">
            <div className="blog-list__wrapper">
              {blogsData.slice(0, 3).map((post: BlogPost) => (
                <div className="blog-list__post" key={post.slug}>
                  <Link
                    className="text-decoration-none"
                    to={`/blog/${post.slug}`}
                  >
                    <div className="position-relative post__image">
                      <img src={post.image} alt={post.title} />
                    </div>
                    <h2 className="text-uppercase font-italic post__title">
                      {post.title}
                    </h2>
                    <p className="post__short">{post.short_content}</p>
                    <div className="d-flex align-items-center post__read-time">
                      <div className="svg-container">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.63604 5.63604C4.1637 7.10837 3.24743 9.04567 3.04334 11.1178C2.83925 13.19 3.35997 15.2688 4.51677 17.0001C5.67358 18.7314 7.3949 20.008 9.38744 20.6125C11.38 21.2169 13.5204 21.1117 15.4441 20.3149C17.3678 19.5181 18.9557 18.0789 19.9373 16.2426C20.9188 14.4062 21.2333 12.2864 20.8271 10.2442C20.4209 8.202 19.3191 6.36384 17.7095 5.04291C16.1 3.72197 14.0822 3 12 3"
                            stroke="#797F87"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                          <path
                            d="M12 12L6 6"
                            stroke="#797F87"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                          <path
                            d="M12 3V5"
                            stroke="#797F87"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                          <path
                            d="M21 12L19 12"
                            stroke="#797F87"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                          <path
                            d="M12 19V21"
                            stroke="#797F87"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                          <path
                            d="M5 12L3 12"
                            stroke="#797F87"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                        </svg>
                      </div>
                      <span className="text-uppercase font-italic">
                        {post.read_time} MIN. CZYTANIA
                      </span>
                    </div>
                  </Link>

                  <Link
                    to={`/blog/${post.slug}`}
                    className="position-relative d-inline-flex align-items-center text-decoration-none link"
                  >
                    <span className="text-uppercase font-italic">
                      PRZECZYTAJ
                    </span>
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <footer className="text-center blog__footer">
            <Link to="/blog">
              <Button variant="primary">WIĘCEJ WPISÓW</Button>
            </Link>
          </footer>
        </div>
      </div>
    </section>
  );
};

export default BlogSection;
