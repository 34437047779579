import React from "react";
import { Route, useLocation } from "react-router-dom";
import { AllowedRoles } from "@/actions/wp";
import AdminSidebarNav from "@/components/AdminSidebarNav/AdminSidebarNav";
import TrainerRoute from "@/components/auth/TrainerRoute";
import UserRoute from "@/components/auth/UserRoute";
import PanelBreadcrumps from "@/components/Breadcrumps/PanelBreadcrumps";
import Loader from "@/components/Loader/Loader";
import links from "@/constants/links";
import RootLayout from "@/layouts/RootLayout";
import { useWpSelector } from "@/selectors";

import "./Admin.scss";
import Introduction from "./Introduction";
import VideoExercisePage from "./pages/CwiczeniaWideo/VideoExercisePage";
import DietsCreatorPage from "./pages/KreatorDiet/DietsCreatorPage";
import ReportsPage from "./pages/Raporty/ReportsPage";
import TrainerClientsPage from "./pages/TrainerClients/TrainerClientsPage";
import TrainerDashboardPage from "./pages/TrainerDashboard/TrainerDashboardPage";
import TrainerMessagesPage from "./pages/TrainerMessages/TrainerMessagesPage";
import TrainerRaportsPage from "./pages/TrainerRaports/TrainerRaportsPage";
import TrainerSettingsPage from "./pages/TrainerSettings/TrainerSettingsPage";
import TrainerSingleClientPage from "./pages/TrainerSingleClient/TrainerSingleClientPage";
import TargetPage from "./pages/TwojCel/TargetPage";
import TrainingPage from "./pages/Trening/TrainingPage";
import DetailsPage from "./pages/TwojeCialo/DetailsPage";
import PackagesPage from "./pages/TwojePakiety/PackagesPage";
import SettingsPage from "./pages/Ustawienia/SettingsPage";
import MessagesPage from "./pages/Wiadomosci/MessagesPage";
import SelectPackage from "./pages/WybierzPakiet/SelectPackage";
import { useGetActiveClientSubscriptionQuery } from "@/api/subscriptionsApi";

const Admin: React.FC = () => {
  const { pathname } = useLocation();
  const { currentUser } = useWpSelector();
  const { data: userSubscription, isLoading } =
    useGetActiveClientSubscriptionQuery(currentUser?.id!, {
      skip: !currentUser?.id,
    });

  if (isLoading)
    return (
      <main className="loader__wrapper">
        <Loader />
      </main>
    );

  const isAdmin = currentUser?.roles.includes(AllowedRoles.admin);

  if (!userSubscription && currentUser && !isAdmin) {
    return (
      <RootLayout>
        <main className="dashboard column">
          <SelectPackage />
        </main>
      </RootLayout>
    );
  }

  const selectPackagePath = pathname === links.selectPackage;

  return currentUser ? (
    <RootLayout>
      <main className={`dashboard ${selectPackagePath ? "column" : ""}`}>
        {!selectPackagePath && (
          <>
            <PanelBreadcrumps />
            <AdminSidebarNav pathName={pathname} />
          </>
        )}
        <div
          className={`${selectPackagePath ? "dashboard__content--full-width" : "dashboard__content"}`}
        >
          <Route
            path={links.dashboard}
            exact
            component={isAdmin ? TrainerDashboardPage : Introduction}
          />

          {/* User Routes */}
          <UserRoute path={links.panelTarget}>
            <TargetPage />
          </UserRoute>
          <UserRoute path={links.panelPackages}>
            <PackagesPage />
          </UserRoute>
          <UserRoute path={links.selectPackage}>
            <SelectPackage />
          </UserRoute>
          <UserRoute path={links.panelBody}>
            <DetailsPage />
          </UserRoute>
          <UserRoute path={links.panelMessages}>
            <MessagesPage />
          </UserRoute>
          <UserRoute path={links.panelRaports}>
            <ReportsPage />
          </UserRoute>
          <UserRoute path={links.panelVideo}>
            <VideoExercisePage />
          </UserRoute>
          <UserRoute path={links.panelDiet}>
            <DietsCreatorPage />
          </UserRoute>
          <UserRoute path={links.panelSettings}>
            <SettingsPage />
          </UserRoute>
          <UserRoute path={links.panelTraining}>
            <TrainingPage />
          </UserRoute>

          {/* Trainer Routes */}
          <TrainerRoute path={links.trainerClients}>
            <TrainerClientsPage />
          </TrainerRoute>
          <TrainerRoute path={links.trainerMessages}>
            <TrainerMessagesPage />
          </TrainerRoute>
          <TrainerRoute path={links.trainerRaports}>
            <TrainerRaportsPage />
          </TrainerRoute>
          <TrainerRoute path={links.trainerSettings}>
            <TrainerSettingsPage />
          </TrainerRoute>
          <TrainerRoute path={links.trainerClientInfo}>
            <TrainerSingleClientPage />
          </TrainerRoute>
        </div>
      </main>
    </RootLayout>
  ) : (
    <main className="loader__wrapper">
      <Loader />
    </main>
  );
};

export default Admin;
