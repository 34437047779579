import RouteHeading from "@/components/headings/RouteHeading";
import TrainerSettingsForm from "./elements/TrainerSettingsForm";
import "./TrainerSettingsPage.scss";

const TrainerSettingsPage = () => {
  return (
    <div className="dashboard__settings">
      <div className="settings">
        <header className="position-relative dashboard__header settings__header">
          <RouteHeading>USTAWIENIA KONTA</RouteHeading>
        </header>
        <TrainerSettingsForm />
      </div>
    </div>
  );
};

export default TrainerSettingsPage;
