import PageHeading from "@/components/headings/PageHeading";
import { DetailHero, DetailHero2 } from "@/assets/images/svg/Icons";
import "./Hero.scss";

const Hero = () => {
  return (
    <section className="hero-subpage">
      <div className="container">
        <div className="position-relative hero-subpage__wrapper">
          <div className="pulse">
            <DetailHero />
          </div>
          <div className="pulse">
            <DetailHero2 />
          </div>
          <div className="hero-subpage__content">
            <span className="d-block font-italic text-center">
              KRÓTKO I NA TEMAT!
            </span>
            <PageHeading variant="h1">ODPOWIADAM NA PYTANIA</PageHeading>
            <p className="text-center">
              Znajdziecie tu odpowiedzi na najczęściej zadawane przez Was
              pytania dotyczące moich usług, treningów i programów.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
