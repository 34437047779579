export const dashboardLink = "/dashboard";
export const trainerPanel = "/panel-trenera";

const links = {
  home: "/",
  dashboard: dashboardLink,
  signIn: "/logowanie",
  signUp: "/rejestracja",
  privacyPolicy: "/polityka-prywatnosci",
  cookiesPolicy: "/polityka-cookies",
  terms: "/regulamin-strony",
  rules: "/regulamin-treningow",
  resetPassword: "/reset-password",
  faq: "/pytania-i-odpowiedzi",
  aboutUs: "/o-mnie",
  blog: "/blog",
  contact: "/kontakt",
  metamorphoses: "/metamorfozy",
  packages: "/pakiety",
  thankYou: "/dziekujemy",

  // panel user
  panelTarget: `${dashboardLink}/twoj-cel`,
  panelPackages: `${dashboardLink}/twoje-pakiety`,
  selectPackage: `${dashboardLink}/wybierz-pakiet`,
  panelBody: `${dashboardLink}/twoje-cialo`,
  panelMessages: `${dashboardLink}/wiadomosci`,
  panelVideo: `${dashboardLink}/cwiczenia-wideo`,
  panelDiet: `${dashboardLink}/kreator-diet`,
  panelSettings: `${dashboardLink}/ustawienia`,
  panelRaports: `${dashboardLink}/raporty`,
  panelTraining: `${dashboardLink}/trening`,

  // panel trainer
  trainerMessages: `${dashboardLink}${trainerPanel}/wiadomosci`,
  trainerRaports: `${dashboardLink}${trainerPanel}/raporty`,
  trainerClients: `${dashboardLink}${trainerPanel}/lista-klientow`,
  trainerSettings: `${dashboardLink}${trainerPanel}/ustawienia`,
  trainerClientInfo: `${dashboardLink}${trainerPanel}/lista-klientow/:clientId`,
};

// const links = {
//   home: "/",
//   dashboard: dashboardLink,
//   signIn: "#",
//   signUp: "#",
//   terms: "#",
//   privacyPolicy: "#",
//   resetPassword: "#",
//   faq: "#",
//   aboutUs: "#",
//   blog: "#",
//   contact: "#",
//   metamorphoses: "#",
//   packages: "#",

//   // panel user
//   panelTarget: `${dashboardLink}/twoj-cel`,
//   panelPackages: `${dashboardLink}/twoje-pakiety`,
//   panelBody: `${dashboardLink}/twoje-cialo`,
//   panelMessages: `${dashboardLink}/wiadomosci`,
//   panelVideo: `${dashboardLink}/cwiczenia-wideo`,
//   panelDiet: `${dashboardLink}/kreator-diet`,
//   panelSettings: `${dashboardLink}/ustawienia`,
//   panelRaports: `${dashboardLink}/raporty`,

//   // panel trainer
//   trainerMessages: `${dashboardLink}${trainerPanel}/wiadomosci`,
//   trainerRaports: `${dashboardLink}${trainerPanel}/raporty`,
//   trainerClients: `${dashboardLink}${trainerPanel}/lista-klientow`,
//   trainerSettings: `${dashboardLink}${trainerPanel}/ustawienia`,
// };

export default links;
