import { FC } from "react";
import { NewUserData } from "@/actions/wp";
import { StepProps } from "../../interfaces";
import Products from "@/components/Products/Products";
import Loader from "@/components/Loader/Loader";
import { useGetProductsQuery } from "@/api/subscriptionsApi";
import { Alert } from "react-bootstrap";
import { useAddToCartMutation, useClearCartMutation } from "@/api/wpApi";
import { useFormContext } from "react-hook-form";

const StepTwo: FC<StepProps> = () => {
  const { setValue, watch } = useFormContext<NewUserData>();

  const metadataField = watch("productMetaData");

  const {
    data: products,
    isLoading: isProductsLoading,
    isError,
  } = useGetProductsQuery();

  const [addToCart] = useAddToCartMutation();
  const [clearCart] = useClearCartMutation();

  const handleAddToCart = async (productId: number, quantity = 1) => {
    try {
      await clearCart().unwrap();
      setValue("productMetaData", {});

      const cartResponse = await addToCart({ productId, quantity }).unwrap();
      const product = products?.find((p) => p.id === productId);

      if (product) {
        setValue("cartKey", cartResponse.cart_key);
        setValue(`productMetaData.${product.id}`, product.meta_data);
        setValue("stepData.plan", product.id.toString());
      }
    } catch (_) {
      console.error("Wystąpił problem podczas tworzenia zamówienia");
    }
  };

  return (
    <div>
      {isProductsLoading && (
        <div className="loading-overlay">
          <Loader />
        </div>
      )}
      <h2 className="form-heading text-center">
        WYBIERZ <span>TWÓJ</span> PAKIET
      </h2>

      {isError && <Alert variant="danger">Wystąpił błąd</Alert>}

      {!isProductsLoading && !isError && products && (
        <Products
          products={products}
          selectedId={
            metadataField ? Number(Object.keys(metadataField)[0]) : undefined
          }
          addToCart={handleAddToCart}
        />
      )}
    </div>
  );
};

export default StepTwo;
