import React, { useCallback, useState } from "react";
import {
  BananaIcon,
  BroccoliIcon,
  ChevronIcon,
  TomatoIcon,
} from "@/assets/images/svg/Icons";
import { DietPlan } from "../DietsCreatorPage";
import "./DietDay.scss";
import MealItem from "./MealItem";

interface IDietDay {
  day: number;
  open?: boolean;
  mealsAmount: number;
  carbs: number;
  fats: number;
  proteins: number;
  setDietPlan: React.Dispatch<React.SetStateAction<DietPlan>>;
}

const DietDay: React.FC<IDietDay> = ({
  day,
  open,
  mealsAmount,
  carbs,
  fats,
  proteins,
  setDietPlan,
}) => {
  const [isOpen, setIsOpen] = useState(open ? open : false);

  const handleClickOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const renderMeals = useCallback(() => {
    const meals = [];
    for (let i = 0; i < mealsAmount; i++) {
      meals.push(
        <MealItem
          key={`${i}-${mealsAmount}`}
          meal={i + 1}
          day={day}
          carbsPerMeal={Math.ceil(carbs / mealsAmount / 5) * 5}
          fatsPerMeal={Math.ceil(fats / mealsAmount / 5) * 5}
          proteinsPerMeal={Math.ceil(proteins / mealsAmount / 5) * 5}
          setDietPlan={setDietPlan}
        />
      );
    }
    return meals;
  }, [carbs, fats, mealsAmount, proteins, day, setDietPlan]);

  return (
    <div className={`day__wrapper ${isOpen ? "open" : ""}`}>
      <div className="day__header">
        <span className="day__number">DZIEŃ {day}</span>
        <span
          className={`toggle ${isOpen ? "open" : ""}`}
          onClick={handleClickOpen}
        >
          {isOpen ? "zwiń" : "rozwiń"}
          <ChevronIcon />
        </span>
      </div>
      {isOpen && (
        <div className="day__body">
          <div className="day__body-icons">
            <BroccoliIcon />
            <BananaIcon />
            <TomatoIcon />
          </div>
          <div className="w-100 day__body-paragraph">
            <p>
              Przykłady zestawienia produktów w trzech posiłkach , które możesz
              zastosować u siebie: Gramaturę dopasuj proszę sam / sama według
              naszego kalkulatora. Tutaj ograniczają Cię tylko Twoje ulubione
              smaki i Twoja fantazja &#128578;
            </p>
            <div className="creator__example">
              <div className="creator__example-item">
                <p className="my-0">
                  <strong>Tłuszcz :</strong> orzechy nerkowca
                </p>
                <p className="my-0">
                  <strong>Białko :</strong> filet z kurczaka bez skóry
                </p>
                <p>
                  <strong>Węglowodany :</strong> makaron razowy
                </p>
              </div>
              <div className="creator__example-item">
                <p className="my-0">
                  <strong>Tłuszcz :</strong> oliwa z oliwek
                </p>
                <p className="my-0">
                  <strong>Białko :</strong> dorsz filet
                </p>
                <p>
                  <strong>Węglowodany :</strong> ryż basmati
                </p>
              </div>
              <div className="creator__example-item">
                <p className="my-0">
                  <strong>Tłuszcz :</strong> awokado
                </p>
                <p className="my-0">
                  <strong>Białko :</strong> chuda szynka z indyka
                </p>
                <p>
                  <strong>Węglowodany :</strong> bułka grachamka
                </p>
              </div>
            </div>
          </div>
          <div className="day__body-meals_wr">{renderMeals()}</div>
        </div>
      )}
    </div>
  );
};

export default DietDay;
