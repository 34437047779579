import Constants from "@/constants";
import { format, parseISO } from "date-fns";

const formatWooDate = (date: Date) => format(date, "yyyy-MM-dd HH:mm:ss");

const transformLineItemsForSubscription = (cartLineItems: any[]) => {
  return cartLineItems.map((item) => {
    return {
      product_id: item.product_id,
      quantity: item.quantity,
      variation_id: item.variation_id || undefined,
    };
  });
};

const extractMetaDataValue = (key: string, metaData: any[]): string => {
  const foundItem = metaData.find((item) => item.key === key);
  return foundItem ? foundItem.value : "";
};

export const createSubscription = async (
  customerId: any,
  orderKey: any,
  orderId: any,
  lineItems: any,
  values: any,
  endDate?: string,
) => {
  lineItems = transformLineItemsForSubscription(lineItems);

  let allProductMetaData: { key: any; value: any }[] = [];
  (Object.values(values.productMetaData) as any[][]).forEach(
    (productMetaData: any[]) => {
      productMetaData.forEach((md) => {
        allProductMetaData.push({ key: md.key, value: md.value });
      });
    },
  );

  const subscriptionFields = {
    billing_period: extractMetaDataValue(
      "_subscription_period",
      allProductMetaData,
    ),
    billing_interval: parseInt(
      extractMetaDataValue("_subscription_period_interval", allProductMetaData),
    ),
  };

  try {
    const response = await fetch(`${Constants.WC}/subscriptions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${btoa(
          `${Constants.consumer_key}:${Constants.consumer_secret}`,
        )}`,
      },
      body: JSON.stringify({
        customer_id: customerId,
        order_key: orderKey,
        parent_id: orderId,
        line_items: lineItems,
        start_date: endDate ? formatWooDate(parseISO(endDate)) : undefined,
        ...subscriptionFields,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      console.error("Problem z utworzeniem subskrypcji:", responseData);
      return { error: true, data: responseData };
    }

    return { error: false, data: responseData };
  } catch (error) {
    console.error("Błąd przy tworzeniu subskrypcji:", error);
    return { error: true, data: error };
  }
};

export const createPrzelewy24Transaction = async (transactionData: {
  merchantId: number;
  posId: number;
  sessionId: string;
  amount: number;
  currency: string;
  description: string;
  email: string;
  country: string;
  language: string;
  urlReturn: string;
  sign: string;
}) => {
  const apiUrl = `${Constants.baseUri}/wp-json/myapp/v1/process-payment`;

  fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(transactionData),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.error) {
        console.error("Błąd płatności:", data.message);
      } else {
        window.location.href = `https://secure.przelewy24.pl/trnRequest/${data.data.token}`;
      }
    })
    .catch((error) => {
      console.error("Błąd:", error);
    });
};
