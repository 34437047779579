import { useEffect, useState } from "react";
import { SearchIcon } from "@/assets/images/svg/Icons";
import Loader from "@/components/Loader/Loader";
import NiceSelect from "@/components/NiceSelect/NiceSelect";
import Pagination from "@/components/Pagination/Pagination";
import TrainerRaports from "@/components/TrainerRaports/TrainerRaports";
import { Alert } from "react-bootstrap";
import { Order, useGetAllReportsQuery } from "@/api/plansApi";
import { useDebounce } from "use-debounce";

const TrainerRaportsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [searchValue, setSearchValue] = useState("");
  const [sort, setSort] = useState<Order>("desc");
  const [search] = useDebounce(searchValue, 500);

  const {
    data: reports,
    isError,
    isLoading,
    status,
  } = useGetAllReportsQuery({
    page: currentPage,
    per_page: itemsPerPage,
    search: search,
    order: sort,
  });

  //TODO temporary totalRecords fix
  const { data: allReports } = useGetAllReportsQuery({
    page: 1,
    per_page: 100,
  });

  useEffect(() => {
    if (allReports) {
      setTotalPages(Math.ceil(allReports.length / itemsPerPage));
    }
  }, [allReports, itemsPerPage]);

  const handleSearchRaport = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 2 || searchValue) {
      setSearchValue(e.target.value);
      setCurrentPage(1);
    }
  };

  const handleSortChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    setSort(e.target.value as Order);
  };

  const handleClickPage = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className="dashboard__reports">
      <div className="reports">
        <div className="reports__content">
          <header className="reports__content__header">
            <div className="reports__content__col reports__content__col--left">
              <div className="input_search">
                <input
                  type="text"
                  placeholder="Wyszukaj raport"
                  onChange={handleSearchRaport}
                />
                <SearchIcon />
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center reports__content__col reports__content__col--right">
              <span>Sortowanie:</span>
              <NiceSelect
                id="a-select"
                placeholder="Od najnowszych"
                className="reports__select"
                onChange={handleSortChange}
              >
                <option value="asc">Od najstarszych</option>
                <option value="desc">Od najnowszych</option>
              </NiceSelect>
            </div>
          </header>
          <div className="reports__content__body cust_list">
            {isLoading && <Loader />}

            {isError && <Alert variant="danger">Wystąpił błąd</Alert>}

            {reports && !isError && !isLoading && (
              <TrainerRaports
                reports={reports}
                isPending={status === "pending"}
                displayName={true}
              />
            )}
          </div>

          <Pagination
            currentPage={currentPage}
            onClickPage={handleClickPage}
            onNextPage={() => setCurrentPage(currentPage + 1)}
            onPrevPage={() => setCurrentPage(currentPage - 1)}
            itemsQuantityAvaliabl={[5, 10, 15]}
            onClickItemsQuantity={(quantity) => {
              setItemsPerPage(quantity);
              setCurrentPage(1);
            }}
            quantityPerPage={itemsPerPage}
            totalPages={totalPages}
          />
        </div>
      </div>
    </div>
  );
};

export default TrainerRaportsPage;
