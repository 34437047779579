import { FC } from "react";
import { User } from "@/actions/wp";
import "./TrainerClients.scss";
import ClientSubscription from "./ClientSubscription";
import { Subscription } from "@/api/subscriptionsApi";
import Loader from "../Loader/Loader";

export type FilteredClientType = User & { subscription: Subscription };

interface ITrainerClients {
  clients: User[] | [];
  currentUser?: User;
  subscriptions: Subscription[];
  isPending?: boolean;
}

const TrainerClients: FC<ITrainerClients> = ({
  clients,
  currentUser,
  subscriptions,
  isPending,
}) => {
  const filteredClientsWithSubscriptions = clients.map((client) => {
    const subscription = subscriptions.find(
      (sub) => sub.customer_id === client.id,
    );

    return {
      ...client,
      subscription: {
        customer_id: subscription?.customer_id ?? null,
        id: subscription?.id ?? null,
        next_payment_date_gmt: subscription?.next_payment_date_gmt ?? "",
        last_payment_date_gmt: subscription?.last_payment_date_gmt ?? "",
        needs_payment: subscription?.needs_payment ?? false,
        line_items: subscription?.line_items ?? [],
      },
    };
  });

  const filteredClients =
    currentUser?.id === 234
      ? (clients.filter((client: User) =>
          [1653].includes(Number(client.meta.plan)),
        ) as FilteredClientType[])
      : filteredClientsWithSubscriptions;

  return (
    <div>
      <div className="list">
        <header className="list_header">
          <div className="text-uppercase list_header__item">
            Imię i nazwisko
          </div>
          <div className="text-uppercase list_header__item">Dołączył/a</div>
          <div className="text-uppercase list_header__item">Pakiet</div>
          <div className="text-uppercase list_header__item">Pakiet wygasa</div>
          <div className="text-uppercase list_header__item">Akcje</div>
        </header>

        <div className="list__wrapper relative">
          {isPending && <Loader backdrop />}

          {filteredClients.length > 0 ? (
            filteredClients?.map((client) => (
              <ClientSubscription key={client.id} client={client} />
            ))
          ) : (
            <div className="empty-list">
              <span>Brak danych</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrainerClients;
