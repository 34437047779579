import { Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Loader from "@/components/Loader/Loader";
import ClientInfo from "./elements/ClientInfo";
import { useGetSingleClientQuery } from "@/api/plansApi";

export type UserProfileParams = {
  clientId: string;
};

const TrainerSingleClientPage = () => {
  let { clientId } = useParams<UserProfileParams>();

  const {
    data: user,
    isLoading,
    isError,
  } = useGetSingleClientQuery(clientId, { skip: !clientId });

  return !isLoading ? (
    !isError ? (
      <ClientInfo user={user} />
    ) : (
      <Alert variant="danger">Wystąpił błąd.</Alert>
    )
  ) : (
    <Loader />
  );
};

export default TrainerSingleClientPage;
