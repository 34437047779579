import Loader from "@/components/Loader/Loader";
import styles from "./FinalStep.module.scss";

const FinalStep = () => {
  return (
    <section className={styles.wrapper}>
      <p className="form-heading text-center">
        Trwa przekierowywanie do sfinalizowania płatności...
      </p>
      <div>
        <Loader />
      </div>
    </section>
  );
};

export default FinalStep;
