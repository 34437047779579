import {
  AuthUserAction,
  TestingAuthAction,
  UnAuthUserAction,
} from "@/actions/auth";
import { ActionAuthTypes } from "@/actions/types";

type Actions = AuthUserAction | UnAuthUserAction | TestingAuthAction;

export interface AuthState {
  authenticated: boolean;
}

export const initialState: AuthState = {
  authenticated: false,
};

const authReducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case ActionAuthTypes.authUser:
      return { ...state, authenticated: true };
    case ActionAuthTypes.unAuthUser:
      return { ...state, authenticated: false };
    default:
      return state;
  }
};

export default authReducer;
