import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchPlans, Plan } from "@/actions/wp";
import Button from "@/components/Buttons/LinkButton";
import PageHeading from "@/components/headings/PageHeading";
import Loader from "@/components/Loader/Loader";
import Item from "@/components/Plans/Item";
import links from "@/constants/links";
import WidthLayout from "@/layouts/WidthLayout";
import { useWpSelector } from "@/selectors";
import "./PackagesSection.scss";

const PackagesSection = () => {
  const dispatch = useDispatch();
  const { plans } = useWpSelector();

  const splideOptions = {
    hasTrack: true,
    type: "slide",
    perPage: 4,
    breakpoints: {
      600: {
        perPage: 1,
      },
      900: {
        perPage: 2,
      },
      1220: {
        perPage: 3,
      },
      1350: {
        perPage: 3,
      },
    },
    height: "56rem",
    gap: 0,
    pagination: true,
    arrows: true,
    drag: true,
    swipe: true,
    classes: {
      arrows: "splide__arrows",
      arrow: "splide__arrow custom_arrow",
      prev: "splide__arrow--prev",
      next: "splide__arrow--next",
    },
  };

  useEffect(() => {
    dispatch(fetchPlans());
  }, [dispatch]);

  return plans !== undefined ? (
    <WidthLayout>
      <section className="section__wrapper_packages">
        <div className="text__wrapeper">
          <p className="section__paragraph">DO DZIEŁA!</p>
          <PageHeading variant="h3">PAKIETY</PageHeading>
          <p className="section__paragraph_second">
            Kupując najdłuższy program płacisz najlepszą cenę w stosunku do
            czasu trwania. Jeśli to Twoja pierwsza przygoda, szczególnie polecam
            Ci pakiet ADAPTACJA.
          </p>
        </div>

        <div className="packages__wrapper">
          <Splide
            options={splideOptions}
            aria-labelledby="reactivity-example-heading"
            className="slider__plans"
          >
            {plans?.map((plan: Plan) => {
              if (!plan || !plan.id) return null;
              return (
                <SplideSlide key={plan.id} className="slider__element">
                  <Item plan={plan} />
                </SplideSlide>
              );
            })}
          </Splide>
        </div>

        <div className="buttons__wrapper">
          <Button
            type="link"
            href={links.signUp}
            text="KUP PAKIET"
            variant="primary"
          />
          <span>LUB</span>
          <Button
            type="link"
            href={links.packages}
            text="DOWIEDZ SIĘ WIĘCEJ"
            variant="secondary"
          />
        </div>
      </section>
    </WidthLayout>
  ) : (
    <Loader />
  );
};

export default PackagesSection;
