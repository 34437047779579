import RouteHeading from "@/components/headings/RouteHeading";

import React from "react";
import { Button } from "react-bootstrap";

import SettingsForm from "./components/SettingsForm";

import "./SettingsPage.scss";
import Loader from "@/components/Loader/Loader";
import { PhotoIcon } from "@/assets/images/svg/Icons";
import { useWpSelector } from "@/selectors";

const SettingsPage = () => {
  const { currentUser } = useWpSelector();
  const avatarUrl =
    currentUser && currentUser.avatar_urls ? currentUser.avatar_urls[96] : null;

  const handleUpdatePhoto = () => {};

  return (
    <div className="dashboard__settings">
      {currentUser ? (
        <div className="settings">
          <header className="position-relative dashboard__header settings__header">
            <RouteHeading>USTAWIENIA TWOJEGO PROFILU</RouteHeading>
            <p className="mb-0">
              W tym miejscu możesz dostosować szczegóły dot. Twojego konta.
              Jeśli chcesz zmienić swój adres e-mail, napisz do mnie na&nbsp;
              <a href="mailto:info@healthandbodyacademy.com.">
                info@healthandbodyacademy.com.
              </a>
            </p>
          </header>
          <div className="settings__form">
            <div className="account__info">
              <div className="position-relative d-flex justify-content-center align-items-center account__avatar">
                {avatarUrl ? (
                  <img src={avatarUrl} alt="Account avatar" />
                ) : (
                  "..."
                )}
              </div>
              <div className="text-center account__info__details">
                <span className="d-block">
                  {currentUser ? currentUser.user_email : "..."}
                </span>
                <span className="account__created_at">
                  <span>dołączyłeś/aś:&nbsp;</span>
                  <time>
                    {currentUser ? currentUser.user_registered : "..."}
                  </time>
                </span>
              </div>
              <Button
                variant="secondary"
                type="button"
                onClick={handleUpdatePhoto}
              >
                Zmień zdjęcie
                <div className="svg-container">
                  <PhotoIcon />
                </div>
              </Button>
              <span className="d-block text-center">
                Zdjęcie powinno być w formacie .jpg lub .png.{" "}
                <strong>Maksymalny rozmiar 3mb.</strong>
              </span>
            </div>
            <SettingsForm currentUser={currentUser} />
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default SettingsPage;
