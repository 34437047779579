import React from "react";
import { Product } from "@/actions/wp";
import Loader from "../Loader/Loader";

import ProductList from "./ProductList";
import "./Products.scss";

interface IProducts {
  selectedId?: number;
  addToCart: (productId: number) => void;
  products?: Product[];
}

const Products: React.FC<IProducts> = ({ addToCart, products, selectedId }) => {
  return (
    <div className="plans">
      <div className="d-flex flex-wrap plans__wrapper">
        {products && products.length > 0 ? (
          <ProductList
            addToCart={addToCart}
            products={products}
            selectedId={selectedId}
          />
        ) : (
          <div className="loading-overlay">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default Products;
