import PageHeading from "@/components/headings/PageHeading";
import { DetailHero, DetailHero2 } from "@/assets/images/svg/Icons";
import "./Hero.scss";

const Hero = () => {
  return (
    <section className="hero-contact">
      <div className="container">
        <div className="position-relative hero-contact__wrapper">
          <div className="pulse">
            <DetailHero />
          </div>
          <div className="pulse">
            <DetailHero2 />
          </div>
          <div className="hero-contact__content">
            <span className="d-block font-italic text-center">
              ZAINTERESOWANY?
            </span>
            <PageHeading variant="h1">
              ZADZWOŃ<span>&nbsp;LUB&nbsp;</span>NAPISZ
            </PageHeading>
            <p className="text-center">
              Na wiadomości e-mail odpisujemy w 24 godziny. Jeśli masz pytania,
              wątpliwości albo propozycje współpracy - pisz śmiało!
            </p>
            <div className="text-center hero-contact__button">
              <a
                className="d-inline-flex align-items-center text-decoration-none"
                href="mailto: info@healthandbodyacademy.com"
              >
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.0143 4H4.55714C3.79851 4 3.13236 4.39017 2.77574 4.96704C2.87018 4.99416 2.96219 5.03576 3.04866 5.09251L12.7857 11.4825L22.5228 5.09251C22.6092 5.03576 22.7012 4.99416 22.7957 4.96705C22.4391 4.39017 21.7729 4 21.0143 4ZM23.0714 7.12466L13.3344 13.5146C13.0013 13.7332 12.5702 13.7332 12.2371 13.5146L2.5 7.12466V17.5C2.5 18.5607 3.42571 19.4286 4.55714 19.4286H21.0143C22.1457 19.4286 23.0714 18.5607 23.0714 17.5V7.12466Z"
                    fill="#F43B57"
                  />
                </svg>
                <span>info@healthandbodyacademy.com</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
