import axios from "axios";
import { useEffect, useState } from "react";
import { ChevronIcon2, PlayFillIcon2 } from "@/assets/images/svg/Icons";
import Loader from "@/components/Loader/Loader";
import Constants from "@/constants";
import { useWpSelector } from "@/selectors";
import "./VideoExercisePage.scss";

interface Video {
  id: number;
  title: string;
  categories: string[];
  video_file?: string;
  thumbnail?: string;
}

interface VideosByCategory {
  [category: string]: Video[];
}

const VideoExercisePage = () => {
  const { currentUser } = useWpSelector();
  const [videos, setVideos] = useState<Video[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [activeCategories, setActiveCategories] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    const fetchUserVideos = async () => {
      if (currentUser && currentUser.id) {
        setIsLoading(true);
        try {
          const response = await axios.get<Video[]>(
            `${Constants.apiUri}/user_videos/`,
            {
              params: {
                user_ids: currentUser.id,
              },
            }
          );
          setVideos(response.data);
          setError("");
        } catch (err) {
          setError(
            "Przepraszamy, coś poszło nie tak, spróbuj ponownie później"
          );
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchUserVideos();
  }, [currentUser]);

  const groupVideosByCategory = (videos: Video[]): VideosByCategory => {
    return videos.reduce((acc: VideosByCategory, video: Video) => {
      video.categories.forEach((category: string) => {
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(video);
      });
      return acc;
    }, {});
  };

  const toggleCategory = (category: string) => {
    setActiveCategories((prev) => ({ ...prev, [category]: !prev[category] }));
  };

  const renderVideos = () => {
    if (isLoading) return <Loader />;
    if (error) return <div>{error}</div>;

    const videosByCategory = groupVideosByCategory(videos);

    return Object.entries(videosByCategory).map(([category, videos]) => (
      <div key={category} className="category-section">
        <header
          onClick={() => toggleCategory(category)}
          className="position-relative d-flex justify-content-between align-items-center category__header"
        >
          <h3 className="my-0 font-italic text-uppercase">{category}</h3>
          <ChevronIcon2 />
        </header>
        <div
          className={`d-flex flex-wrap overflow-hidden video__list${
            activeCategories[category] ? " expanded" : ""
          }`}
        >
          {videos.map((video) => (
            <div key={video.id} className="video__item">
              <a
                href={video.video_file}
                target="_blank"
                className="text-decoration-none d-block"
                rel="noopener noreferrer"
              >
                <div className="position-relative overflow-hidden video__thumbnail">
                  <img
                    className="w-100"
                    src={video.thumbnail}
                    alt={video.title}
                  />
                  <div className="d-inline-block position-absolute svg-container">
                    <PlayFillIcon2 />
                  </div>
                </div>
                <h4 className="my-0">{video.title}</h4>
              </a>
            </div>
          ))}
        </div>
      </div>
    ));
  };

  return <div className="video-exercise-page">{renderVideos()}</div>;
};

export default VideoExercisePage;
