import { useState } from "react";

import { AllowedRoles, User } from "@/actions/wp";
import { UserFillIcon, ChevronIcon } from "@/assets/images/svg/Icons";
import Loader from "@/components/Loader/Loader";
import UserMessages from "./elements/UserMessages";
import "./TrainerMessagesPage.scss";
import { useGetClientsQuery } from "@/api/plansApi";

const TrainerMessagesPage = () => {
  const [userMessageId, setUserMessageId] = useState<null | number>(null);
  const [usersListShow, setUsersListShow] = useState(false);

  const { data, isLoading } = useGetClientsQuery({
    page: 1,
    perPage: 99999,
  });

  const handleClickUser = (id: number) => {
    setUserMessageId(id);
    setUsersListShow(false);
  };

  const handleShowUsersList = () => {
    setUsersListShow(!usersListShow);
  };

  const clientsWithChat = data?.users.filter(
    (usr) =>
      !usr.roles.includes(AllowedRoles.admin) &&
      usr.meta.plan !== "30" &&
      usr.meta.plan !== "185" &&
      !usr.roles.includes(AllowedRoles.guest) &&
      usr.roles.length > 0,
  );

  const displayUser = clientsWithChat?.find(
    (item) => item.id === userMessageId,
  );

  return (
    <div className="messages">
      <div className={`messages__users ${usersListShow ? "show" : ""}`}>
        <div className={`current__message_user `} onClick={handleShowUsersList}>
          <span className="current__message_user-name">
            <UserFillIcon />
            {userMessageId ? displayUser?.name : "Wybierz podopiecznego"}
          </span>
          <span
            className={`current__message_user-icon ${
              usersListShow ? "show" : ""
            }`}
          >
            <ChevronIcon />
          </span>
        </div>
        <ul className={`users__list ${usersListShow ? "show" : ""}`}>
          {!isLoading ? (
            clientsWithChat &&
            clientsWithChat.map((user: User) => {
              const avatarUrl = user.avatar_urls ? user.avatar_urls[96] : null;

              return (
                <li
                  key={user.id}
                  onClick={() => handleClickUser(user.id)}
                  className="users__list-item"
                >
                  {avatarUrl ? (
                    <img src={avatarUrl} alt="Account avatar" />
                  ) : (
                    ""
                  )}

                  {user.name}
                </li>
              );
            })
          ) : (
            <Loader />
          )}
        </ul>
      </div>
      <div className="messages__content">
        <UserMessages id={userMessageId} client={displayUser} />
      </div>
    </div>
  );
};

export default TrainerMessagesPage;
