import { useWpSelector } from "@/selectors";
import Button from "@/components/Buttons/LinkButton";
import TrainerClients from "@/components/TrainercClients/TrainerClients";
import links from "@/constants/links";
import "./LatestCustomers.scss";
import { useGetClientsSubscriptionsQuery } from "@/api/subscriptionsApi";
import { useGetClientsQuery } from "@/api/plansApi";
import Loader from "@/components/Loader/Loader";
import { Alert } from "react-bootstrap";

const LatestCustomers = () => {
  const { currentUser } = useWpSelector();
  const {
    data: clients,
    isError: clientError,
    isFetching,
  } = useGetClientsQuery({
    orderby: "registered_date",
  });

  const {
    data: subscriptionsData,
    isError: subError,
    isFetching: subFetching,
  } = useGetClientsSubscriptionsQuery({ page: 1, perPage: 100 });

  const isLoading = isFetching || subFetching;
  const isError = clientError || subError;
  const isData = !isFetching && !subFetching && !clientError && !subError;

  return (
    <div className="cust__list">
      <h3 className="trainer__dashboard-second-heading">
        TWOI NAJNOWSI KLIENCI
      </h3>

      {isLoading && <Loader />}

      {isError && <Alert variant="danger">Wystąpił błąd</Alert>}

      {isData && (
        <TrainerClients
          clients={clients?.users || []}
          subscriptions={subscriptionsData || []}
          currentUser={currentUser}
        />
      )}

      <div className="cust__list_btn">
        <Button
          href={links.trainerClients}
          text="Zobacz wszystkich"
          type="link"
          variant="secondary"
        />
      </div>
    </div>
  );
};

export default LatestCustomers;
