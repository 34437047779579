import yup from "./yup/yupConfig";

const StepOneValidationSchema = yup.object({
  stepData: yup.object({
    gender: yup.string().trim().required(),
    height: yup.string().trim().required(),
    age: yup.string().trim().required(),
    weight: yup.string().trim().required(),
    training_days: yup.string().trim().required(),
  }),
});

const StepTwoValidationSchema = yup.object({});

const StepThreeValidationSchema = yup.object({});

const StepFourValidationSchema = yup.object({
  first_name: yup.string().trim().required(),
  last_name: yup.string().trim().required(),
  address: yup.string().trim().required(),
  city: yup.string().trim().required(),
  postal_code: yup.string().trim().required(),
  email: yup.string().email().trim().required(),
  password: yup.string().trim().required(),
  phone: yup.string().trim().length(9).required(),
  discount: yup.string().trim(),
  policy: yup.bool().oneOf([true], "Wymagane pole"),
  terms: yup.bool().oneOf([true], "Wymagane pole"),
});

export const RegistrationValidationSchema = [
  StepOneValidationSchema,
  StepTwoValidationSchema,
  StepThreeValidationSchema,
  StepFourValidationSchema,
];

export const SignFormSchemaValidation = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
});

export const SettingsFormValidationSchema = yup.object().shape({
  password: yup.string().trim(),
  confirmPassword: yup
    .string()
    .trim()
    .oneOf([yup.ref("password")], "Hasła muszą być takie same"),
  gender: yup.string().required("Wybór płci jest wymagany"),
  first_name: yup.string().trim().required(),
  last_name: yup.string().trim().required(),
  phone: yup.string().trim().length(9).required(),
});

export const ContactFormValidationSchema = yup.object().shape({
  name: yup.string().trim().required(),
  surname: yup.string().trim().required(),
  email: yup.string().trim().required(),
  phone: yup.string().trim().notRequired(),
  termsOne: yup
    .bool()
    .oneOf([true], "Musisz zaakceptować regulamin")
    .required("Zaznaczenie tego pola jest wymagane"),
  termsTwo: yup
    .bool()
    .oneOf([true], "Musisz zaakceptować politykę prywatności")
    .required("Zaznaczenie tego pola jest wymagane"),
  message: yup.string().trim().required(),
});

export const NewPostFormValidationSchema = yup.object().shape({
  title: yup.string().trim().required(),
  content: yup.string().trim().required(),
});

export const AddReportValidationSchema = yup.object({
  title: yup.string().required(),
  content: yup.string().required(),
  status: yup.string(),
  meta: yup
    .object({
      report_type: yup.string(),
      report_start_date: yup.string(),
      report_end_date: yup.string(),
      report_points_mood: yup.string().required(),
      report_points_energy: yup.string().required(),
      report_points_comfort: yup.string().required(),
      report_points_hunger: yup.string().required(),
      report_points_reliability: yup.string().required(),
    })
    .notRequired()
    .nonNullable(),
});
