import React from "react";
import { Goal, NewUserData } from "@/actions/wp";
import Item from "./Item";
import { useFormContext } from "react-hook-form";

type GoalListProps = {
  goals: Goal[];
  onClick: (name: string) => void;
};

const GoalList: React.FC<GoalListProps> = ({ goals, onClick }) => {
  const { watch } = useFormContext<NewUserData>();

  const stepDataValues = watch("stepData");
  return (
    <>
      {goals.map((goal) => {
        return (
          <Item
            formValues={stepDataValues}
            goal={goal}
            key={goal.id}
            onClick={onClick}
          />
        );
      })}
    </>
  );
};

export default GoalList;
