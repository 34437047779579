import { FC } from "react";
import { Container, Spinner } from "react-bootstrap";

interface ILoader {
  variant?: "light";
  backdrop?: boolean;
}

const Loader: FC<ILoader> = ({ variant, backdrop }) => {
  return (
    <Container
      className="d-flex justify-content-center align-items-center w-100 h-100 bg-red-500"
      style={
        backdrop
          ? {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "rgba(0,0,0,0.1)",
              borderRadius: "14px",
              margin: 0,
            }
          : {}
      }
    >
      <Spinner animation="grow" variant={variant ? variant : "danger"} />
    </Container>
  );
};

export default Loader;
