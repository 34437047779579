import { Form } from "react-bootstrap";
import { Controller, Control, FieldValues, Path } from "react-hook-form";

interface ControlledRadioProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object,
> {
  control: Control<TFieldValues, TContext>;
  name: Path<TFieldValues>;
  options: { value: string; label: string }[];
  title?: string;
  required?: boolean;
  disabled?: boolean;
  wrapperClass?: string;
}

const RadioGroup = <TFieldValues extends FieldValues, TContext extends object>({
  control,
  name,
  options,
  disabled,
  title,
  required,
  wrapperClass,
}: ControlledRadioProps<TFieldValues, TContext>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <Form.Group
          className={`d-flex flex-wrap align-items-center form-group form-group ${wrapperClass}`}
          role="group"
        >
          {title && (
            <label className="d-block w-100 label">
              {title}
              {required && "*"}
            </label>
          )}

          {options.map((option) => {
            const id = `${name}-${option.value}`;

            return (
              <Form.Check
                {...field}
                key={option.value}
                type="radio"
                id={id}
                label={option.label}
                value={option.value}
                checked={field.value === option.value}
                onChange={() => field.onChange(option.value)}
                disabled={disabled}
              />
            );
          })}

          {error && (
            <Form.Text className="error-text" style={{ width: "100%" }}>
              {error.message}
            </Form.Text>
          )}
        </Form.Group>
      )}
    />
  );
};

export default RadioGroup;
