import { FC, useEffect, useState } from "react";
import { EyeIcon } from "@/assets/images/svg/Icons";
import { formatDate } from "@/functions/formatDate";
import RaportsPerviewModal from "@/pages/Admin/pages/Raporty/Modal/ReportsPreviewModal";
import Loader from "../Loader/Loader";
import "./TrainerRaports.scss";
import { useGetClientsQuery } from "@/api/plansApi";
import { Report, User } from "@/actions/wp";
import { useWpSelector } from "@/selectors";

interface ITrainerClients {
  reports: Report[];
  isPending: boolean;
  displayName?: boolean;
}

const TrainerRaports: FC<ITrainerClients> = ({
  reports,
  isPending,
  displayName = true,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [initialValues, setInitialValues] = useState<Report>();
  const [filteredClients, setFilteredClients] = useState<User[]>([]);

  const { data } = useGetClientsQuery({ page: 1, perPage: 99999 });
  const { currentUser } = useWpSelector();

  useEffect(() => {
    if (data?.users) {
      const filtered = data.users.filter((client) =>
        [1652, 1653].includes(Number(client.meta.plan)),
      );
      setFilteredClients(filtered);
    }
  }, [data?.users]);

  // Filtrowanie raportów w zależności od ID użytkownika
  const filteredReports =
    currentUser?.id === 234
      ? reports.filter((raport) =>
          filteredClients.some((client) => client.id === raport.author),
        )
      : reports.filter(
          (raport) =>
            !filteredClients.some((client) => client.id === raport.author),
        );

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <div>
      <div className={`list`}>
        <header className={`list_header ${!displayName ? "short" : ""}`}>
          {displayName && (
            <div className="text-uppercase list_header__item">
              Imię i nazwisko
            </div>
          )}

          <div className="text-uppercase list_header__item">Dołączył/a</div>
          <div className="text-uppercase list_header__item">Typ</div>
          <div className="text-uppercase list_header__item">Tytuł</div>
          <div className="text-uppercase list_header__item">Akcje</div>
        </header>

        <div className="list__wrapper relative">
          {isPending && <Loader backdrop />}

          {filteredReports.length > 0 ? (
            filteredReports?.map((raport) => {
              return (
                <div
                  className={`list_item ${!displayName ? "short" : ""}`}
                  key={raport.id}
                >
                  {displayName && (
                    <span className="d-block d-flex align-items-center">
                      <span className="info__mobile">Imię i nazwisko:</span>
                      <span>{raport?.author_details?.name}</span>
                    </span>
                  )}

                  <time className="d-block d-flex align-items-center">
                    <span className="info__mobile">Dołączył/a:</span>{" "}
                    <time>
                      {formatDate(raport?.meta?.report_start_date)} -{" "}
                      {formatDate(raport?.meta?.report_end_date)}
                    </time>
                  </time>

                  <span className="d-block d-flex align-items-center">
                    <span className="info__mobile">Typ:</span> Raport
                  </span>

                  <span className="d-block d-flex align-items-center">
                    <span className="info__mobile">Tytuł:</span>
                    <span className="item_title">{raport.title.rendered}</span>
                  </span>

                  <div className="d-flex justify-content-between align-items-center item__col item__col--right list_item__details">
                    <button
                      type="button"
                      className="p-0"
                      onClick={() => {
                        setInitialValues(raport);
                        handleShow();
                      }}
                    >
                      <span>podgląd</span>
                      <EyeIcon />
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="no-data">Brak raportów</div>
          )}
        </div>

        {initialValues && (
          <RaportsPerviewModal
            raport={initialValues}
            handleClose={handleClose}
            showModal={showModal}
          />
        )}
      </div>
    </div>
  );
};

export default TrainerRaports;
