import PageHeading from "@/components/headings/PageHeading";
import { DetailHero, DetailHero2 } from "@/assets/images/svg/Icons";
import "./Hero.scss";

const Hero = () => {
  return (
    <section className="hero-subpage">
      <div className="container">
        <div className="position-relative hero-subpage__wrapper">
          <div className="pulse">
            <DetailHero />
          </div>
          <div className="pulse">
            <DetailHero2 />
          </div>
          <div className="hero-subpage__content">
            <span className="d-block font-italic text-center">
              ZACZNIJ OD ZARAZ
            </span>
            <PageHeading variant="h1">
              <span>SPRAWDŹ</span> PAKIETY
            </PageHeading>
            <p className="text-center">
              Bez względu na to, czy chcecie przypakować, poprawić kondycję, czy
              wymodelować konkretne partie ciała, pakiety treningowe zostały
              dobrane, tak abyście to osiągnęli
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
