import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";
import { useWpSelector } from "@/selectors";

const RootLayout = ({ children }: { children: React.ReactNode }) => {
  const { currentUser } = useWpSelector();

  return (
    <div>
      <Header currentUser={currentUser} />
      {children}
      <Footer />
    </div>
  );
};

export default RootLayout;
