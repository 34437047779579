import React from "react";

import { Card } from "react-bootstrap";
import { Plan } from "@/actions/wp";
import { CheckIcon } from "@/assets/images/svg/Icons";

type ItemProps = {
  plan: Plan;
  onClick?: (name: string) => void;
  formValues?: any;
};

const Item: React.FC<ItemProps> = React.memo(
  ({ plan, onClick, formValues }) => {
    const bestseller = plan.acf.cpt_plans.bestseller;
    const list = plan.acf.cpt_plans.list;
    const original_price = plan.acf.cpt_plans.original_price;
    const premium = plan.acf.cpt_plans.premium_price;
    const remotely = plan.acf.cpt_plans.remotely;
    const new_product = plan.acf.cpt_plans.new;
    const price = plan.acf.cpt_plans.price;
    const description = plan.acf.cpt_plans.description;
    const text = plan.acf.cpt_plans.text;
    const time = plan.acf.cpt_plans.time;

    return (
      <Card
        className={`position-relative border-0 ${
          +formValues?.plan === +plan.id ? "is-active" : ""
        }`}
        tabIndex={1}
        onClick={onClick ? () => onClick(plan.id.toString()) : () => {}}
      >
        {bestseller && !premium && (
          <div className="card-tag card-tag--blue">
            <span className="font-italic text-uppercase">BESTSELLER</span>
          </div>
        )}
        {!bestseller && premium && (
          <div className="card-tag card-tag--red">
            <span className="font-italic text-uppercase">NAJLEPSZA CENA</span>
          </div>
        )}
        {remotely && (
          <div className="card-tag card-tag--orange">
            <span className="font-italic text-uppercase">TRENUJ W DOMU</span>
          </div>
        )}
        {new_product && (
          <div className="card-tag card-tag--red">
            <span className="font-italic text-uppercase">NOWOŚĆ!</span>
          </div>
        )}
        <Card.Body>
          <header className="bg-transparent text-center font-italic p-0 border-0 rounded-0 card-header">
            <h3 className="mt-0 text-uppercase card-title">
              {plan.title.rendered}
            </h3>
            <time className="font-italic text-uppercase card-time">{time}</time>
          </header>
          <div className="card-content">
            {description && (
              <div className="text-center card-description">
                <p className="mb-0">{description}</p>
              </div>
            )}
            <p className="card-text">
              <strong>{text}</strong>
            </p>
            <ul className="list-unstyled m-0 p-0 card-list">
              {list.map((el: any, idx: number) => {
                return (
                  <li className="position-relative card-list__item" key={idx}>
                    <div className="position-absolute d-flex align-items-center svg-container">
                      <CheckIcon />
                    </div>
                    <span
                      className="d-block"
                      dangerouslySetInnerHTML={{ __html: el.item }}
                    ></span>
                  </li>
                );
              })}
            </ul>
          </div>
          <footer className="bg-transparent border-0 rounded-0 p-0 card-footer">
            <div className="d-flex justify-content-center align-items-center card-footer__wrapper">
              <span className="font-italic price">{price}</span>
              <span className="font-italic price--original">
                {original_price}
              </span>
            </div>
          </footer>
        </Card.Body>
      </Card>
    );
  },
);

export default Item;
