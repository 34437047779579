import links from "@/constants/links";
import { useFormContext } from "react-hook-form";
import { NewUserData } from "@/actions/wp";
import TextField from "@/components/Form/TextField";
import Checkbox from "@/components/Form/Checkbox";
import { LockIcon, EmailIcon } from "@/assets/images/svg/Icons";

const StepFour = () => {
  const { control } = useFormContext<NewUserData>();

  return (
    <section>
      <h2 className="form-heading text-center">UTWÓRZ KONTO</h2>

      <TextField
        control={control}
        name="first_name"
        label="IMIĘ:"
        placeholder="[IMIĘ]"
      />

      <TextField
        control={control}
        name="last_name"
        label="NAZWISKO:"
        placeholder="[NAZWISKO]"
      />

      <TextField
        control={control}
        name="address"
        label="ADRES:"
        placeholder="[ADRES]"
      />

      <div className="form-group--row d-flex">
        <TextField
          control={control}
          name="city"
          label="MIASTO:"
          placeholder="[MIASTO]"
        />

        <TextField
          control={control}
          name="postal_code"
          label="KOD POCZTOWY:"
          placeholder="[KOD]"
        />
      </div>

      <TextField
        control={control}
        type="email"
        name="email"
        label="E-MAIL"
        placeholder="[ADRES E-MAIL]"
        endIcon={<EmailIcon />}
      />

      <TextField
        control={control}
        type="password"
        name="password"
        label="HASŁO"
        placeholder="[MIN. 6 ZNAKÓW]"
        endIcon={<LockIcon />}
        autoComplete="new-password"
      />

      <TextField
        control={control}
        type="number"
        name="phone"
        label="TELEFON"
        placeholder="[NR. TELEFONU]"
      />

      <TextField
        control={control}
        name="discount"
        label="KOD RABATOWY:"
        placeholder="[KOD RABATOWY]"
      />

      <Checkbox
        control={control}
        name="policy"
        label="Zgadzam się na przetwarzanie przez HealthAndBodyAcademy danych dotyczących mojego zdrowia, w celu prawidłowej realizacji usług dostępnych w serwisie.*"
      />

      <Checkbox
        control={control}
        name="terms"
        label={
          <>
            Akceptuję{" "}
            <a href={links.terms} target="_blank" rel="noreferrer noopener">
              regulamin Health & Body Academy
            </a>{" "}
            oraz
            <a
              href={links.privacyPolicy}
              target="_blank"
              rel="noreferrer noopener"
            >
              {" "}
              politykę prywatności serwisu
            </a>
            .*
          </>
        }
      />
    </section>
  );
};

export default StepFour;
