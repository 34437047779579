import RootLayout from "@/layouts/RootLayout";
import PagesBreadcrumbs from "@/components/PagesBreadcrumbs/PagesBreadcrumbs";
import Hero from "./sections/Hero";
import Blogs from "./Blogs";
import Cta from "@/components/Cta/Cta";

const BlogPage = () => {
  return (
    <RootLayout>
      <main id="main">
        <PagesBreadcrumbs />
        <Hero />
        <Blogs />
        <Cta />
      </main>
    </RootLayout>
  );
};

export default BlogPage;
