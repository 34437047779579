import { useMemo, useState } from "react";

import { Alert } from "react-bootstrap";

import "./Reports.scss";
import { Report } from "@/actions/wp";
import { EyeIcon, CloseIconSmall } from "@/assets/images/svg/Icons";
import Loader from "@/components/Loader/Loader";
import { formatDate } from "@/functions/formatDate";
import { useWpSelector } from "@/selectors";
import ReportsPreviewModal from "../Modal/ReportsPreviewModal";
import { useToggle } from "@/hooks/useToggle";
import ReportsModal from "../Modal/ReportsModal";
import { useDeleteReportMutation, useGetReportsQuery } from "@/api/plansApi";
import { useToast } from "@/components/ToastContext/ToastContext";

const Reports: React.FC<{ sortOrder: string }> = ({ sortOrder }) => {
  const { enqueueToast } = useToast();
  const reportsModal = useToggle<{
    report?: Report;
    type: "preview" | "edit";
  }>();

  const { currentUser } = useWpSelector();

  const [warningMsgStatus, setWarningMsgStatus] = useState(false);
  const [reportToDelete, setPostToDelete] = useState<number | null>(null);

  const {
    data: reports,
    isLoading,
    isError,
  } = useGetReportsQuery(currentUser?.id!, { skip: !currentUser?.id });

  const [deleteReport] = useDeleteReportMutation();

  const handleDelete = (reportId: number) => {
    setPostToDelete(reportId);
    setWarningMsgStatus(true);
  };

  const confirmDelete = () => {
    if (reportToDelete !== null) {
      try {
        deleteReport(reportToDelete).unwrap();

        enqueueToast({
          type: "success",
          message: "Raport został pomyślnie usunięty",
        });
        setWarningMsgStatus(false);
      } catch (error) {
        enqueueToast({
          type: "error",
          message: "Wystąpił błąd podczas usuwania raportu",
        });
      }
    }
  };

  //TODO sort on BE
  const sortedPosts = useMemo(() => {
    if (reports && reports.length > 0) {
      if (sortOrder === "newest") {
        return [...reports].sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
        );
      }
      return [...reports].sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
      );
    }
    return [];
  }, [reports, sortOrder]);

  return (
    <div className="reports__list">
      {warningMsgStatus && (
        <Alert
          variant="warning"
          onClose={() => setWarningMsgStatus(false)}
          dismissible
        >
          Czy na pewno chcesz usunąć ten raport?
          <button onClick={confirmDelete}>Tak</button>
          <button onClick={() => setWarningMsgStatus(false)}>Nie</button>
        </Alert>
      )}

      <header className="d-none d-xl-flex reports__list__header">
        <div className="header__item">
          <span className="d-inline-block text-uppercase header__text">
            Okres
          </span>
          <span className="d-inline-block text-uppercase header__text">
            Typ
          </span>
          <span className="d-inline-block text-uppercase header__text">
            Tytuł
          </span>
        </div>
        <div className="header__item" aria-label="Akcje">
          <span className="d-inline-block text-uppercase header__text">
            Akcje
          </span>
        </div>
      </header>
      <div className="reports__list__wrapper">
        {isError && <Alert variant="danger">Wystąpił błąd.</Alert>}

        {!isLoading ? (
          <>
            {sortedPosts.length > 0 ? (
              sortedPosts.map((report) => (
                <div className="item" key={report.id}>
                  <div className="position-relative item__col item__col--left">
                    <time className="d-block">
                      {formatDate(report.meta.report_start_date)} -{" "}
                      {formatDate(report.meta.report_end_date)}
                    </time>
                    <span className="d-block">{report.meta.report_type}</span>
                    <h4 className="item-title">{report.title.rendered}</h4>
                  </div>
                  <div className="d-flex justify-content-between align-items-center item__col item__col--right">
                    <button
                      type="button"
                      className="d-flex align-items-center p-0"
                      onClick={() =>
                        reportsModal.openWithProps({ report, type: "preview" })
                      }
                    >
                      <span>podgląd</span>
                      <EyeIcon />
                    </button>
                    {/* 
                    <button
                      type="button"
                      className="d-flex align-items-center p-0"
                      onClick={() =>
                        reportsModal.openWithProps({ post, type: "edit" })
                      }
                    >
                      <span>edytuj</span>
                      <EditIcon />
                    </button> */}

                    <button
                      type="button"
                      className="d-flex align-items-center p-0"
                      onClick={() => handleDelete(report.id)}
                    >
                      <span>usuń</span>
                      <CloseIconSmall />
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className="d-flex justify-content-center">Brak raportów</div>
            )}
          </>
        ) : (
          <Loader />
        )}
      </div>

      {reportsModal.additionalProps?.type === "preview" && (
        <ReportsPreviewModal
          raport={reportsModal.additionalProps.report}
          handleClose={reportsModal.close}
          showModal={reportsModal.isOpen}
        />
      )}

      {reportsModal.additionalProps?.type === "edit" && (
        <ReportsModal
          initialValues={{
            ...reportsModal.additionalProps.report,
            title: reportsModal.additionalProps.report?.title.rendered || "",
            content:
              reportsModal.additionalProps.report?.content.rendered || "",
          }}
          handleClose={reportsModal.close}
          showModal={reportsModal.isOpen}
          isEditForm
        />
      )}
    </div>
  );
};

export default Reports;
