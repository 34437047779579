import { Button } from "react-bootstrap";
import PageHeading from "@/components/headings/PageHeading";

interface MediPayStatusProps {
  mediPayStatus: string | null;
  clientLink?: string | null;
}

const MediPayStatus = ({ mediPayStatus, clientLink }: MediPayStatusProps) => {
  switch (mediPayStatus) {
    case "IN_PROGRESS":
      return (
        <>
          <PageHeading variant="h1">Płatność w toku</PageHeading>
          <p className="text-center form-final-paragraph">
            Twoja płatność jest w trakcie realizacji. Możesz wznowić proces,
            jeśli został przerwany.
          </p>
          {clientLink && (
            <Button variant="primary-blue" href={clientLink}>
              Wznów płatność
            </Button>
          )}
        </>
      );
    case "EXPIRED":
      return (
        <>
          <PageHeading variant="h1">Link wygasł</PageHeading>
          <p className="text-center form-final-paragraph">
            Twój link do płatności wygasł. Skontaktuj się z nami w celu
            uzyskania nowego.
          </p>
        </>
      );
    case "REJECTED":
      return (
        <>
          <PageHeading variant="h1">Płatność odrzucona</PageHeading>
          <p className="text-center form-final-paragraph">
            Twoja płatność została odrzucona. Skontaktuj się z obsługą klienta.
          </p>
        </>
      );
    case "ACCEPTED":
      return (
        <>
          <PageHeading variant="h1">Płatność zakończona</PageHeading>
          <p className="text-center form-final-paragraph">
            Dziękujemy! Twoja płatność została zaakceptowana.
          </p>
        </>
      );
    case "CLIENT_CREATED":
      return (
        <>
          <PageHeading variant="h1">Rozpocznij płatność</PageHeading>
          {clientLink && (
            <Button variant="primary-blue" href={clientLink}>
              Przejdź do płatności
            </Button>
          )}
        </>
      );
    case "NOT_FOUND":
      return (
        <>
          <PageHeading variant="h1">Klient nie znaleziony</PageHeading>
          <p className="text-center form-final-paragraph">
            Nie znaleźliśmy Twojego klienta w systemie MediPay. Sprawdź, czy
            rozpocząłeś proces płatności lub skontaktuj się z obsługą klienta.
          </p>
        </>
      );
    case "ERROR":
      return (
        <>
          <PageHeading variant="h1">
            TRANSAKCJA <span>NIEUDANA</span>
          </PageHeading>
          <p className="text-center form-final-paragraph">
            Niestety, wystąpił problem z przetwarzaniem Twojej transakcji.
            Prosimy o kontakt z obsługą klienta lub spróbuj ponownie później.
          </p>
        </>
      );
    default:
      return null;
  }
};

export default MediPayStatus;
