import { createApi, BaseQueryFn } from "@reduxjs/toolkit/query/react";
import { AxiosRequestConfig, AxiosError } from "axios";
import { NewPostData, Plan, Report, User } from "@/actions/wp";
import axiosClient from "./axiosClient";

export type Order = "asc" | "desc";

const axiosBaseQuery: BaseQueryFn<
  {
    url: string;
    method?: AxiosRequestConfig["method"];
    params?: any;
    data?: any;
  },
  unknown,
  { status?: number; data?: any }
> = async ({ url, method = "GET", params, data }) => {
  try {
    const response = await axiosClient({ url, method, params, data });
    return { data: response.data };
  } catch (error) {
    const axiosError = error as AxiosError<{ message?: string }>;

    return {
      error: {
        status: axiosError.response?.status,
        data: axiosError.response?.data || {
          message: axiosError.response?.data.message,
        },
      },
    };
  }
};

export const plansApi = createApi({
  reducerPath: "plansApi",
  baseQuery: axiosBaseQuery,
  tagTypes: ["Reports"],
  endpoints: (builder) => ({
    getPlans: builder.query<Plan[], void>({
      query: () => ({ url: "/plans" }),
    }),
    getClients: builder.query<
      { page: number; total_pages: number; total_users: number; users: User[] },
      {
        page?: number;
        perPage?: number;
        search?: string;
        sort?: string;
        orderby?: string;
        order?: Order;
      }
    >({
      query: ({
        page = 1,
        perPage = 5,
        search = "",
        sort = "ASC",
        orderby = "date",
        order = "desc",
      }) => ({
        url: "/clients",
        params: {
          page,
          per_page: perPage,
          orderby,
          order,
          search: search.length > 2 ? search : "",
          sort_order: sort === "plan" ? "plan" : "ASC",
        },
      }),
    }),
    getSingleClient: builder.query<User, string>({
      query: (clientId) => ({
        url: `/users/${clientId}`,
      }),
    }),
    getAllReports: builder.query<
      Report[],
      {
        page: number;
        per_page: number;
        order?: Order;
        orderby?: "date";
        sort?: "newest" | "oldest";
        search?: string;
      }
    >({
      query: ({
        page = 1,
        per_page = 5,
        order = "desc",
        orderby = "date",
        sort = "newest",
        search = "",
      }) => ({
        url: `/reports`,
        params: {
          page,
          per_page,
          orderby,
          order,
          search,
          sort,
        },
      }),
      providesTags: ["Reports"],
    }),
    getReports: builder.query<Report[], string | number>({
      query: (userId) => ({
        url: `/reports?author=${userId}`,
      }),
      providesTags: ["Reports"],
    }),
    postReportWithImages: builder.mutation<
      any,
      { postData: NewPostData; images: File[] }
    >({
      queryFn: async (
        { postData, images },
        _queryApi,
        _extraOptions,
        fetchWithBQ,
      ) => {
        try {
          const postResponse = await fetchWithBQ({
            url: "/reports",
            method: "POST",
            data: postData,
          });

          if (postResponse.error) throw postResponse.error;
          //   const postId = (postResponse.data as { id: string })?.id;

          //   let uploadedImageIds = [];
          for (const image of images) {
            const formData = new FormData();
            formData.append("file", image);

            const imageResponse = await fetchWithBQ({
              url: "/media",
              method: "POST",
              data: formData,
            });

            if (imageResponse.error) throw imageResponse.error;
            // uploadedImageIds.push((imageResponse.data as { id: string })?.id);
          }

          //   await fetchWithBQ({
          //     url: `/acf/v3/reports/${postId}`,
          //     method: "POST",
          //     data: { acf: { cpt_reports_gallery: uploadedImageIds } },
          //   });

          return { data: postResponse.data };
        } catch (error) {
          return {
            error: { status: 500, data: "An unexpected error occurred" },
          };
        }
      },
      invalidatesTags: ["Reports"],
    }),

    deleteReport: builder.mutation<number, number>({
      query: (id) => ({
        url: `/reports/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Reports"],
    }),
  }),
});

export const {
  useGetPlansQuery,
  useGetClientsQuery,
  useGetSingleClientQuery,
  useGetReportsQuery,
  usePostReportWithImagesMutation,
  useDeleteReportMutation,
  useGetAllReportsQuery,
} = plansApi;
