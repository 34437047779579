import { useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import links from "@/constants/links";
import { fetchMessages, IMessage, postMessage } from "@/actions/wp";
import { useWpSelector } from "@/selectors";
import UpgradePlan from "@/assets/images/png/buy-higher-plan.png";
import "./MessagesPage.scss";
import { useForm } from "react-hook-form";
import { useToast } from "@/components/ToastContext/ToastContext";
import TextField from "@/components/Form/TextField";

const MessagesPage = () => {
  const { currentUser } = useWpSelector();
  const dispatch = useDispatch();
  const { messages } = useWpSelector();
  const listRef = useRef<HTMLUListElement>(null);
  const { enqueueToast } = useToast();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<{ text: string }>({
    defaultValues: { text: "" },
  });

  useEffect(() => {
    dispatch(fetchMessages(String(currentUser?.id), () => {}));

    const interval = setInterval(() => {
      dispatch(fetchMessages(String(currentUser?.id), () => {}));
    }, 30000);

    return () => clearInterval(interval);
  }, [dispatch, currentUser]);

  const sortMsg = messages?.sort((a: any, b: any) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateA.getTime() - dateB.getTime();
  });

  useEffect(() => {
    if (listRef.current) {
      const scrollableList = listRef.current;
      scrollableList.scrollTop = scrollableList.scrollHeight;
    }
  }, [messages]);

  const isPlan30 =
    currentUser?.meta?.plan === "30" || currentUser?.meta?.plan === "185";

  const submitForm = async (values: { text: string }) => {
    try {
      if (!values.text?.trim() || !currentUser) {
        return;
      }

      await new Promise((resolve, reject) => {
        dispatch(
          postMessage(
            {
              receiverId: 1,
              senderId: currentUser.id,
              text: values.text.trim(),
            },
            (res) => {
              if (res.status < 300) {
                reset();
                dispatch(fetchMessages(String(currentUser.id), () => {}));
                resolve(res);
              } else {
                reject(new Error("Message sending failed"));
              }
            },
          ),
        );
      });
    } catch (error) {
      enqueueToast({
        type: "error",
        message: "Wystąpił błąd podczas wysyłania wiadomości",
      });
    }
  };

  return (
    <div className="h-100 message_wr">
      {isPlan30 ? (
        <div className="position-relative h-100 d-flex justify-content-center align-items-center upgrade-plan">
          <div className="w-100 upgrade-plan__msg">
            <p>
              Posiadasz pakiet <strong>ADAPTACJA</strong>. Cieszę się, że mi
              zaufałeś i wybrałeś moją markę do rozpoczęcia przygody ze zdrowiem
              i siłownią. Chcąc cieszyć się i korzystać w 100% z mojej wiedzy,
              monitorowac swoje postępy w oparciu o zdjęcia sylwetki i
              cotygodniowe raporty, mieć osobisty kontakt przez okno czatu 7 dni
              w tygodniu -{" "}
              <strong>
                <Link to={links.panelPackages}>zakup wyższy pakiet</Link>
              </strong>
            </p>
          </div>
          <div className="d-none d-xl-block">
            <img src={UpgradePlan} alt="HEALTH AND BODY ACADEMY" />
          </div>
        </div>
      ) : (
        <>
          <ul className="message_content" ref={listRef}>
            <li className={"message__caller"}>
              <span>
                Cześć :) Ciesze się, że wybrałeś moją platformę „Health And Body
                Academy” do pomocy w prowadzeniu zdrowego stylu życia i przygody
                z siłownią. Gwarantuje Ci , że trzymając się zasad współpracy ze
                mną - stosując dietę , plan treningowy, cotygodniowe raporty i
                moje wskazówki - osiągniesz sukces.
              </span>
            </li>

            <li className={"message__caller"}>
              <span>
                Zdrowo Schudniesz, nabierzesz masy mięśniowej, poprawisz
                samopoczucie i zdrowie. Wszystko w zależności od wybranych dla
                siebie celów i priorytetów.
              </span>
            </li>

            <li className={"message__caller"}>
              <span>
                Masz teraz dostęp do super prostego kalkulatora posiłków , który
                szybko podpowie Ci jakich makroskladnikow użyć do skomponowania
                idealnego posiłku dla Ciebie . Przygotowujesz je na swój
                własnych sposob , według własnych preferencji smakowych . Możesz
                te wybrane przez siebie zestawienia produktów / dania / piec ,
                smażyć , gotować , stosować ulubioną obróbkę termiczną lub
                poprostu przygotowywać z nich sałatki . Z dobrej jakości
                pieczywem robić smaczne kanapki. Ty wybierasz sobie ile posiłków
                w ciągu dnia chcesz jeść tak żeby było Ci wygodnie „trzymać
                dietę”.
              </span>
            </li>

            <li className={"message__caller"}>
              <span>
                Proszę wyślij mi teraz swoje aktualne zdjęcia „przód , tył ,
                bok” . Miejsce na mnie znajdziesz właśnie w zakładce „RAPORT”.
                Ułatwi mi to odpowiedni dobór ćwiczeń pod Twoje indywidualne
                cele / pod Twoją sylwetke . Podaj wagę , wiek, wzrost i cel
                współpracy ze mną. -{">"} wypalenie tłuszczu, zwiększenie masy
                mięśniowej, poprawa kondycji, rekonwalescencja po
                urazie/wypadku, rekompozycja ciała. Będę mógł precyzyjnie i
                dokładnie zaproponować Ci podaż makroskladników w diecie /
                kalorii w ciągu dnia .
              </span>
            </li>

            <li className={"message__caller"}>
              <span>
                Proszę Cię (nie jest to obowiązkowe ) / jeśli masz ochotę na
                100% pomoc z mojej strony / zrób pakiet podstawowych badań z
                krwi takich jak : Cukier / morfologia krwi / profil tarczycowy
                tsh ft3 ft4 / profil lipidowy / ALAT ASPAT bilirubina /
                (Opcjonalnie testosteron , estradiol , prolaktyna ) . Po
                otrzymaniu wyników - wyślij niezwłocznie - pomoże mi to
                ustosunkować się do Twojego indywidualnego przypadku i dobrać ,
                jeśli to potrzeba , odpowiednie suplementy , konkretny trening i
                proporcje w makroskladnikow w diecie
              </span>
            </li>

            <li className={"message__caller"}>
              <span>
                Przed Tobą kawał fajnej sportowej przygody. Będę Cię wspierać na
                tej nowej drodze, pomagać Ci przejść przez ewentualne trudności
                , podpowiem ciekawe rozwiązania , pomogę uzyskać zdrowie oraz
                wymarzoną formę . Od teraz działamy razem , więc mamy
                przynajmniej dwa razy większe szanse na sukces. Jestem do Twojej
                dyspozycji .
              </span>
            </li>

            {sortMsg?.map((msg: IMessage) => {
              const deletedMsg = !msg.text && !msg.audio_url;

              return (
                <li
                  key={msg.id}
                  className={`${
                    currentUser && +msg.senderId === +currentUser?.id
                      ? "message__author"
                      : "message__caller"
                  } ${msg.audio_url ? "message-audio" : ""} ${deletedMsg ? "deleted" : ""}`}
                >
                  {deletedMsg && <span>Usunięto..</span>}

                  {msg.text &&
                    msg.text.split("\n").map((item, key) => (
                      <span key={key}>
                        {item}
                        <br />
                      </span>
                    ))}

                  {msg.audio_url && (
                    <audio controls>
                      <source src={msg.audio_url} type="audio/mp3" />
                    </audio>
                  )}
                </li>
              );
            })}
          </ul>

          <form
            onSubmit={handleSubmit(submitForm)}
            className="message__form_wrapper"
          >
            <TextField
              control={control}
              name="text"
              label=""
              placeholder="Aa"
              rows={2}
              textarea
            />

            <div className="submit-btn-wrapper">
              <Button
                className="send_button"
                variant="primary"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <span className="spinner-grow spinner-grow-sm" />
                ) : (
                  "WYŚLIJ"
                )}
              </Button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default MessagesPage;
