import { Button, Container, Form, Stack } from "react-bootstrap";
import { useDispatch } from "react-redux";
import LinkButton from "@/components/Buttons/LinkButton";
import { authUser, FormValues } from "@/actions/auth";
import { fetchCurrentUser } from "@/actions/wp";
import Loader from "@/components/Loader/Loader";
import { initialValues } from "./data";
import links from "@/constants/links";
import { useForm } from "react-hook-form";
import TextField from "@/components/Form/TextField";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToast } from "@/components/ToastContext/ToastContext";
import { SignFormSchemaValidation } from "@/utils/validators";
import { FillEyeOff, FillEye, UserIcon } from "@/assets/images/svg/Icons";
import { useToggle } from "@/hooks/useToggle";

const SignInForm = () => {
  const dispatch = useDispatch();
  const { enqueueToast } = useToast();
  const passwordHidden = useToggle(true);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(SignFormSchemaValidation),
  });

  const submit = async (values: FormValues) => {
    try {
      await new Promise((resolve, reject) => {
        dispatch(
          authUser(values, (response) => {
            if (response instanceof Error) {
              reject(response);
            } else {
              dispatch(fetchCurrentUser());
              resolve(response);
            }
          }),
        );
      });
    } catch (_) {
      enqueueToast({
        type: "error",
        message: "Niepoprawna nazwa użytkownika lub hasło",
      });
    }
  };

  return (
    <Container className="form-register__container">
      <form onSubmit={handleSubmit(submit)} className="form-inner">
        <Stack gap={2}>
          <TextField
            control={control}
            name="username"
            placeholder="[LOGIN]"
            label="Wpisz nazwę użytkownika"
            endIcon={<UserIcon />}
          />

          <TextField
            control={control}
            name="password"
            label="Wpisz hasło"
            placeholder="[HASŁO]"
            type={passwordHidden.isOpen ? "password" : "text"}
            endIcon={
              <span
                onClick={() => passwordHidden.toggle(passwordHidden.isOpen)}
              >
                {passwordHidden.isOpen ? <FillEye /> : <FillEyeOff />}
              </span>
            }
          />

          <Form.Group className="text-center button_wr">
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              {isSubmitting ? <Loader variant="light" /> : "Zaloguj"}
            </Button>

            <LinkButton
              href={links.home}
              text="Strona Główna"
              type="link"
              variant="secondary"
            />
          </Form.Group>
        </Stack>
      </form>
    </Container>
  );
};

export default SignInForm;
