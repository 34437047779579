import MichalStopka from "@/assets/images/png/michal-stopka.png";
import { InstaIcon2 } from "@/assets/images/svg/Icons";
import PageHeading from "@/components/headings/PageHeading";
import "./ContactForm.scss";
import ContactUsForm from "@/components/Form/ContactUsForm";

const ContactForm = () => {
  return (
    <section className="contact-form">
      <div className="container">
        <div className="contact-form__wrapper">
          <div className="form">
            <div className="form__inner">
              <header className="d-flex form__header">
                <div className="form__avatar">
                  <img src={MichalStopka} alt="MICHAŁ BOBYK" />
                </div>
                <div className="form__details">
                  <PageHeading variant="h3">KONTAKT DO MICHAŁA</PageHeading>
                  <div className="form__contact">
                    <a className="text-decoration-none" href="tel:530266311">
                      tel. +48 530 266 311
                    </a>
                  </div>
                  <div className="form__social">
                    <a
                      className="d-inline-flex align-items-center text-decoration-none"
                      href="https://www.instagram.com/michalbobyk"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <InstaIcon2 />
                      <span>/michalbobyk</span>
                    </a>
                  </div>
                </div>
              </header>

              <ContactUsForm formVariant="contact" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
