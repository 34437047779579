import { useWpSelector } from "@/selectors";
import {
  FourDaysPlanMan,
  FourDaysPlanWoman,
  ThreeDaysPlanMan,
  ThreeDaysPlanWoman,
  TwoDaysPlanMan,
  TwoDaysPlanWoman,
} from "../data/plans";
import SingleDay from "./SingleDay";

const TrainingPlan = () => {
  const { currentUser } = useWpSelector();

  const gender = currentUser?.meta.gender === "Mężczyzna";

  switch (currentUser?.meta.training_days) {
    case 2:
      return (
        <div>
          {Object.keys(gender ? TwoDaysPlanMan : TwoDaysPlanWoman).map(
            (item: any, idx) => {
              return (
                <SingleDay
                  open={idx === 0}
                  key={`${item.name}-${idx}`}
                  exercise={
                    gender ? TwoDaysPlanMan[item] : TwoDaysPlanWoman[item]
                  }
                />
              );
            }
          )}
        </div>
      );
    case 3:
      return (
        <div>
          {Object.keys(gender ? ThreeDaysPlanMan : ThreeDaysPlanWoman).map(
            (item: any, idx) => {
              return (
                <SingleDay
                  open={idx === 0}
                  key={`${item.name}-${idx}`}
                  exercise={
                    gender ? ThreeDaysPlanMan[item] : ThreeDaysPlanWoman[item]
                  }
                />
              );
            }
          )}
        </div>
      );
    case 4:
      return (
        <div>
          {Object.keys(gender ? FourDaysPlanMan : FourDaysPlanWoman).map(
            (item: any, idx) => {
              return (
                <SingleDay
                  open={idx === 0}
                  key={`${item.name}-${idx}`}
                  exercise={
                    gender ? FourDaysPlanMan[item] : FourDaysPlanWoman[item]
                  }
                />
              );
            }
          )}
        </div>
      );
    default:
      return (
        <div>
          {Object.keys(gender ? FourDaysPlanMan : FourDaysPlanWoman).map(
            (item: any, idx) => {
              return (
                <SingleDay
                  open={idx === 0}
                  key={`${item.name}-${idx}`}
                  exercise={
                    gender ? FourDaysPlanMan[item] : FourDaysPlanWoman[item]
                  }
                />
              );
            }
          )}
        </div>
      );
  }
};

export default TrainingPlan;
