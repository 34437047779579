import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { gender } from "../../data";
import { StepProps } from "../../interfaces";
import { useFormContext } from "react-hook-form";
import RadioGroup from "@/components/Form/Radio";
import TextField from "@/components/Form/TextField";
import { UserIcon, CalendarIcon, OwnIcon } from "@/assets/images/svg/Icons";
import { NewUserData } from "@/actions/wp";

const trainingDaysOptions = [
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
];

const StepOne: React.FC<StepProps> = () => {
  const { control, watch, setValue } = useFormContext<NewUserData>();
  const genderField = watch("stepData.gender");

  useEffect(() => {
    !genderField && setValue("stepData.gender", gender[0].name);
  }, [genderField, setValue]);

  return (
    <>
      <h2 className="form-heading text-center">
        ZACZYNAMY <span>TWOJĄ</span> PRZEMIANĘ
      </h2>

      <Form.Group className="form-group-row form-group">
        <label className="form-label">Twoja płeć:</label>

        <div className="form-field d-flex overflow-hidden">
          {gender.map((el) => (
            <button
              type="button"
              key={el.name}
              onClick={() => {
                setValue("stepData.gender", el.name);
              }}
              className={`form-button ${
                genderField === el.name ? "form-button--active" : ""
              }`}
            >
              {el.name}
            </button>
          ))}
        </div>
      </Form.Group>

      <TextField
        control={control}
        type="number"
        name="stepData.height"
        label="Wzrost:"
        placeholder="[CM]"
        endIcon={<UserIcon />}
      />

      <TextField
        control={control}
        type="number"
        name="stepData.age"
        label="Wiek:"
        placeholder="[LATA]"
        endIcon={<CalendarIcon />}
      />

      <TextField
        control={control}
        type="number"
        name="stepData.weight"
        label="Waga:"
        placeholder="[KG]"
        endIcon={<OwnIcon />}
      />

      <RadioGroup
        control={control}
        name="stepData.training_days"
        title="Ilość treningów w tygodniu:"
        options={trainingDaysOptions}
        wrapperClass="form-group-training"
      />
    </>
  );
};

export default StepOne;
