import Button from "@/components/Buttons/LinkButton";
import TrainerRaports from "@/components/TrainerRaports/TrainerRaports";
import links from "@/constants/links";
import "./LatestRaports.scss";
import { useGetAllReportsQuery } from "@/api/plansApi";
import Loader from "@/components/Loader/Loader";
import { Alert } from "react-bootstrap";

const LatestRaports = () => {
  const {
    data: reports,
    isLoading,
    isError,
  } = useGetAllReportsQuery({ page: 1, per_page: 5 });

  return (
    <div className="raports__list">
      <h3 className="trainer__dashboard-second-heading">
        OSTATNIO DODANE RAPORTY
      </h3>

      {isLoading && <Loader />}

      {isError && <Alert variant="danger">Wystąpił błąd</Alert>}

      {reports && !isLoading && !isError && (
        <TrainerRaports isPending={isLoading} reports={reports} />
      )}

      <div className="cust__list_btn">
        <Button
          href={links.trainerRaports}
          text="Zobacz wszystkie"
          type="link"
          variant="secondary"
        />
      </div>
    </div>
  );
};

export default LatestRaports;
