import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import RootLayout from "@/layouts/RootLayout";
import Constants from "@/constants";
import "./ThankYou.scss";
import TransactionStatusMessage from "./TransactionStatusMessage";
import MediPayStatus from "./MedipayStatus";

const ThankYou = () => {
  const [transactionStatus, setTransactionStatus] = useState<boolean | null>(
    null
  );
  const [mediPayTransactionStatus, setMedipayTransactionStatus] = useState<
    string | null
  >(null);
  const [clientLink, setClientLink] = useState<string | null>(null);

  useEffect(() => {
    const getQueryParam = (param: string) =>
      new URLSearchParams(window.location.search).get(param);
    const externalId = getQueryParam("externalId");
    const sessionId = getQueryParam("sessionId");
    const id = getQueryParam("id");

    if (externalId) {
      axios
        .get(
          `${Constants.baseUri}/wp-json/myapp/v1/get-mediPay-status/?externalId=${externalId}`
        )
        .then(({ data }) => {
          setMedipayTransactionStatus(data.status.toUpperCase());
          if (data.clientLink) setClientLink(data.clientLink);
        })
        .catch(() => setMedipayTransactionStatus("ERROR"));
    }

    if (sessionId && id) {
      const apiUrl = `${Constants.baseUri}/wp-json/myapp/v1/get-transaction/?sessionId=${sessionId}`;

      axios
        .get(apiUrl)
        .then(({ data }) => {
          if (data.data.status === 1) {
            setTransactionStatus(true);
            updateOrderStatus(id, "completed");
          } else {
            setTransactionStatus(false);
          }
        })
        .catch(() => setTransactionStatus(false));
    }
  }, []);

  const updateOrderStatus = async (orderId: string, newStatus: string) => {
    const wcApiUrl = `${Constants.WC}/orders/${orderId}`;
    const basicAuth =
      "Basic " + btoa(`${Constants.consumer_key}:${Constants.consumer_secret}`);

    try {
      await axios.put(
        wcApiUrl,
        { status: newStatus },
        { headers: { Authorization: basicAuth } }
      );
    } catch (error) {
      console.error("Błąd aktualizacji statusu zamówienia:", error);
    }
  };

  return (
    <RootLayout>
      <main id="main">
        <section className="thank-you">
          <div className="thank-you__wrapper">
            <div className="container">
              {mediPayTransactionStatus === null && (
                <TransactionStatusMessage
                  transactionStatus={transactionStatus}
                />
              )}

              {transactionStatus === null && (
                <MediPayStatus
                  mediPayStatus={mediPayTransactionStatus}
                  clientLink={clientLink}
                />
              )}

              <div className="wr-buttons-final-step">
                <Link to="/">
                  <Button variant="secondary" className="btn-final-primary">
                    STRONA GŁÓWNA
                  </Button>
                </Link>
                <Link to="/logowanie">
                  <Button variant="primary">PANEL KLIENTA</Button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </main>
    </RootLayout>
  );
};

export default ThankYou;
