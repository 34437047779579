import { ReactNode } from "react";
import { Form } from "react-bootstrap";
import { Controller, Control, FieldValues, Path } from "react-hook-form";
import "./FormField.scss";

interface ControlledCheckboxProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object,
> {
  control: Control<TFieldValues, TContext>;
  name: Path<TFieldValues>;
  label: ReactNode;
  disabled?: boolean;
}

const Checkbox = <TFieldValues extends FieldValues, TContext extends object>({
  control,
  name,
  label,
}: ControlledCheckboxProps<TFieldValues, TContext>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <Form.Group className="form-group">
          <label className="checkbox-container">
            <span>{label}</span>

            <Form.Control type="checkbox" {...field} />

            <span className="checkmark"></span>
          </label>

          {error && (
            <Form.Text className="error-text">{error.message}</Form.Text>
          )}
        </Form.Group>
      )}
    />
  );
};

export default Checkbox;
