import React from "react";
import { useParams } from "react-router-dom";
import RootLayout from "@/layouts/RootLayout";
import PagesBreadcrumbs from "@/components/PagesBreadcrumbs/PagesBreadcrumbs";
import blogsData from "./data/blog.json";
import { Link } from "react-router-dom";
import "./Blog.scss";

interface ContentElement {
  type: "heading" | "paragraph" | "list";
  text?: string;
  items?: string[];
}

interface BlogPost {
  id: string;
  slug: string;
  title: string;
  author: string;
  read_time: string;
  image: string;
  content: ContentElement[];
}

const renderContentElement = (element: ContentElement) => {
  switch (element.type) {
    case "heading":
      return (
        <h2
          className="text-uppercase font-italic"
          dangerouslySetInnerHTML={{ __html: element.text || "" }}
        ></h2>
      );
    case "paragraph":
      return <p dangerouslySetInnerHTML={{ __html: element.text || "" }}></p>;
    case "list":
      return (
        <ul>
          {element.items?.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      );
    default:
      return null;
  }
};

const Blog = () => {
  const { slug } = useParams<{ slug: string }>();
  const post = blogsData.find((p) => p.slug === slug) as BlogPost;

  if (!post) {
    return <div>Post nie znaleziony</div>;
  }

  return (
    <RootLayout>
      <PagesBreadcrumbs />
      <section className="blog-post">
        <div className="blog-post__wrapper">
          <div className="container">
            <header className="text-center blog-post__header">
              <div className="blog-post__image">
                <img src={post.image} alt={post.title} />
              </div>
              <span className="d-block text-uppercase font-italic blog-post__author">
                Autor: {post.author}
              </span>
              <h1 className="text-uppercase font-italic blog-post__title">
                {post.title}
              </h1>
              <div className="d-flex justify-content-center align-items-center blog-post__read-time">
                <div className="svg-container">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.13604 5.63604C4.6637 7.10837 3.74743 9.04567 3.54334 11.1178C3.33925 13.19 3.85997 15.2688 5.01677 17.0001C6.17358 18.7314 7.8949 20.008 9.88744 20.6125C11.88 21.2169 14.0204 21.1117 15.9441 20.3149C17.8678 19.5181 19.4557 18.0789 20.4373 16.2426C21.4188 14.4062 21.7333 12.2864 21.3271 10.2442C20.9209 8.202 19.8191 6.36384 18.2095 5.04291C16.6 3.72197 14.5822 3 12.5 3"
                      stroke="#797F87"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M12.5 12L6.5 6"
                      stroke="#797F87"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M12.5 3V5"
                      stroke="#797F87"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M21.5 12L19.5 12"
                      stroke="#797F87"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M12.5 19V21"
                      stroke="#797F87"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M5.5 12L3.5 12"
                      stroke="#797F87"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
                <span className="text-uppercase font-italic">
                  {post.read_time} MIN. CZYTANIA
                </span>
              </div>
            </header>
            <div className="blog-post__content">
              {post.content.map((element, index) => (
                <React.Fragment key={index}>
                  {renderContentElement(element)}
                </React.Fragment>
              ))}
            </div>
            <footer className="d-flex justify-content-center blog-post__footer">
              <Link
                to={`/blog`}
                className="position-relative d-flex align-items-center text-decoration-none"
              >
                <div className="svg-container">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.8332 10H4.1665"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.99984 15.8327L4.1665 9.99935L9.99984 4.16602"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <span className="text-uppercase font-italic">Wróć</span>
              </Link>
            </footer>
          </div>
        </div>
      </section>
    </RootLayout>
  );
};

export default Blog;
