import PageHeading from "../headings/PageHeading";
import FittrainLogo from "@/assets/images/png/partners/Fittrain.jpg";
import "./OurPartners.scss";

const OurPartners = () => {
  return (
    <section className="our-partners">
      <div className="container">
        <div className="position-relative our-partners__wrapper">
          <header className="our-partners__header">
            <PageHeading variant="h2">PARTNERZY</PageHeading>
          </header>
          <div className="d-flex justify-content-center align-items-center our-partners__content">
            <div className="partner">
              <a
                className="d-block text-center text-decoration-none"
                href="http://fittrain.pl/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={FittrainLogo} alt="FIT&TRAIN" />
                <span className="d-block partner-name">FIT&TRAIN</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurPartners;
