import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchGoals, User } from "@/actions/wp";
import {
  BirthdayIcon,
  BoneIcon,
  HeightIcon,
  HumanIcon,
  InfoIcon,
  PackageIcon,
  ScaleIcon,
  TargetIcon,
} from "@/assets/images/svg/Icons";
import { useWpSelector } from "@/selectors";
import "./ClientInfo.scss";
import ClientRaports from "./ClientRaports";
import { useGetClientSubscriptionsQuery } from "@/api/subscriptionsApi";
import { formatDate } from "@/functions/formatDate";
import Loader from "@/components/Loader/Loader";
import { Alert } from "react-bootstrap";

interface IClientInfo {
  user?: User;
}

const ClientInfo: FC<IClientInfo> = ({ user }) => {
  const dispatch = useDispatch();
  const { goals } = useWpSelector();

  const avatarUrl = user && user.avatar_urls ? user.avatar_urls[96] : null;

  const {
    data: userSubsriptions,
    isLoading,
    isError,
  } = useGetClientSubscriptionsQuery(user?.id!, { skip: !user?.id });

  useEffect(() => {
    dispatch(fetchGoals());
  }, [dispatch]);

  const userGoal = goals.find((goal: any) => goal.id === +user?.meta.goal!);

  const subscriptionInactive = userSubsriptions?.every(
    (sub) => sub.status !== "active",
  );

  const activeSubscription = userSubsriptions?.find(
    (sub) => sub.status === "active",
  );

  return (
    <section className="section__wrapper user">
      <header className="user__header">
        <div className="d-flex align-items-start user__info">
          <div className="position-relative d-flex justify-content-center align-items-center user__avatar">
            {avatarUrl ? <img src={avatarUrl} alt="user avatar" /> : "..."}
          </div>
          <div className="d-flex flex-column align-items-start user__data">
            <span className="user__name is-strong">
              <strong>{user ? user.name : "..."}</strong>
            </span>

            <span className="user__email">
              {user ? user.meta.gender : "..."}
            </span>
            <span>E-mail: {user?.user_email}</span>
            <span className="user__created_at">
              <span>dołączył/a: </span>
              <time>{user ? user.user_registered : "..."}</time>
            </span>
            <span>Telefon: {user ? user.billing_phone : "-"}</span>
          </div>
        </div>
      </header>
      <div className="account__content">
        <div className="account__cards">
          <div className="border-0 card account-goal">
            <header className="d-flex align-items-center card__header">
              <div className="svg-container">
                <TargetIcon />
              </div>
              <h3 className="text-uppercase font-italic mb-0 card__title">
                CEL
              </h3>
            </header>
            <div className="card__details">
              <div className="position-relative card__details__wrapper">
                <h4 className="text-uppercase font-italic card__heading">
                  {userGoal ? userGoal.title.rendered : "..."}
                </h4>
                <div className="position-absolute goal-icon">
                  <img
                    src={userGoal ? userGoal?.acf?.cpt_goals?.icon : "..."}
                    alt={userGoal ? userGoal?.title?.rendered : "..."}
                  />
                </div>
                <div className="goal-scores">
                  <div className="goal-scores__wrapper">
                    <div className="d-flex align-items-start card__details__info">
                      <span className="d-inline-block text-uppercase font-italic">
                        Start:
                      </span>
                      <span className="">
                        {user ? user.meta.weight : "..."}
                        &nbsp;kg
                      </span>
                    </div>
                    <div className="d-flex align-items-start card__details__info">
                      <span className="d-inline-block text-uppercase font-italic">
                        Teraz:
                      </span>
                      <span className="">
                        {user ? user.meta.weight_current : "..."}
                        &nbsp;kg
                      </span>
                    </div>
                    <div className="d-flex align-items-start card__details__info">
                      <span className="d-inline-block text-uppercase font-italic">
                        Cel:
                      </span>
                      <span className="">
                        {user ? user.meta.weight_target : "..."}
                        &nbsp;kg
                      </span>
                    </div>
                  </div>
                </div>
                <div className="pt-3">
                  <span className="d-inline-block text-uppercase training_amount font-italic">
                    {" "}
                    Liczba treningów:{" "}
                  </span>{" "}
                  {user?.meta.training_days}
                </div>
              </div>
            </div>
          </div>
          <div className="border-0 card account-plan">
            <header className="d-flex align-items-center card__header">
              <div className="svg-container">
                <PackageIcon />
              </div>
              <h3 className="text-uppercase font-italic mb-0 card__title">
                PAKIET
              </h3>
            </header>

            {isLoading && <Loader />}

            {isError && <Alert variant="danger">Wystąpił błąd</Alert>}

            {!isLoading && !isError && activeSubscription && (
              <div className="card__details">
                <div className="card__details__wrapper">
                  <h4 className="text-center text-uppercase font-italic card__heading">
                    {activeSubscription.line_items?.[0]?.name ||
                      "Brak nazwy pakietu"}
                  </h4>

                  {subscriptionInactive ? (
                    <span className="d-block card__details__wrapper--date">
                      Pakiet nieaktywny
                    </span>
                  ) : (
                    <>
                      <span className="d-block card__details__wrapper--date">
                        {formatDate(
                          activeSubscription.last_payment_date_gmt,
                          true,
                        )}{" "}
                        -{" "}
                        {formatDate(
                          activeSubscription.next_payment_date_gmt,
                          true,
                        )}
                      </span>

                      <span className="d-block card__details__wrapper--date">
                        Do końca:{" "}
                        {(() => {
                          if (!activeSubscription?.next_payment_date_gmt)
                            return "0 dni";

                          const nextPaymentDate = new Date(
                            activeSubscription.next_payment_date_gmt,
                          );
                          if (isNaN(nextPaymentDate.getTime()))
                            return "Nieprawidłowa data";

                          const today = new Date();
                          const diffTime =
                            nextPaymentDate.getTime() - today.getTime();
                          const diffDays = Math.max(
                            0,
                            Math.ceil(diffTime / (1000 * 60 * 60 * 24)),
                          );

                          return `${diffDays} dni`;
                        })()}
                      </span>
                    </>
                  )}
                </div>
              </div>
            )}

            {!activeSubscription && !isLoading && !isError && (
              <div className="card__details">
                <div className="card__details__wrapper">
                  <span className="d-block card__details__wrapper--date">
                    Brak pakietu
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className="border-0 card account-body">
            <header className="d-flex align-items-center card__header">
              <div className="svg-container">
                <HumanIcon />
              </div>
              <h3 className="text-uppercase font-italic mb-0 card__title">
                CIAŁO
              </h3>
            </header>
            <div className="card__details">
              <div className="card__details__wrapper">
                <div className="d-flex align-items-start card__details__info">
                  <HeightIcon />
                  <span className="d-inline-block text-uppercase font-italic">
                    <strong>Wzrost:</strong>
                  </span>
                  <span>{user ? user.meta.height : "..."} cm</span>
                </div>
                <div className="d-flex align-items-start card__details__info">
                  <ScaleIcon />
                  <span className="d-inline-block text-uppercase font-italic">
                    <strong>Waga:</strong>
                  </span>
                  <span>{user ? user.meta.weight : "..."} kg</span>
                </div>
                <div className="d-flex align-items-start card__details__info">
                  <BirthdayIcon />
                  <span className="d-inline-block text-uppercase font-italic">
                    <strong>Wiek:</strong>
                  </span>
                  <span>{user ? user.meta.age : "..."}&nbsp;lat</span>
                </div>
                <div className="d-flex align-items-start card__details__info">
                  <BoneIcon />
                  <span className="d-inline-block text-uppercase font-italic">
                    <strong>Kontuzje:</strong>
                  </span>
                  <span>{user ? user.meta.injury : "..."}</span>
                </div>
                <div className="d-flex align-items-start card__details__info">
                  <InfoIcon />
                  <span className="d-inline-block text-uppercase font-italic">
                    <strong>Ważne:</strong>
                  </span>
                  <span>{user ? user.meta.important : "..."}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {user && <ClientRaports userId={user.id} />}
    </section>
  );
};

export default ClientInfo;
