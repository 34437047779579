import PageHeading from "@/components/headings/PageHeading";

interface TransactionStatusMessageProps {
  transactionStatus: boolean | null;
}

const TransactionStatusMessage = ({
  transactionStatus,
}: TransactionStatusMessageProps) => {
  if (transactionStatus === null) {
    return (
      <>
        <PageHeading variant="h1">
          TRANSAKCJA <span>PRZETWARZANA!</span>
        </PageHeading>
        <p className="text-center form-final-paragraph">
          Twoja transakcja jest obecnie przetwarzana. Dostęp do konta będzie
          możliwy, jak tylko transakcja zostanie zaksięgowana.
        </p>
      </>
    );
  }

  if (transactionStatus === true) {
    return (
      <>
        <PageHeading variant="h1">
          <span>SUPER!</span> ZACZYNAMY
        </PageHeading>
        <p className="text-center form-final-paragraph">
          Dzięki za zaufanie! Twoje konto zostało aktywowane. Możesz teraz
          przejść do Panelu Klienta, aby podejrzeć swój program treningowy lub
          wygenerować plan żywieniowy.
          <strong>Nie czekaj na motywację i zacznij już teraz!</strong>
        </p>
      </>
    );
  }

  return (
    <>
      <PageHeading variant="h1">
        TRANSAKCJA <span>NIEUDANA</span>
      </PageHeading>
      <p className="text-center form-final-paragraph">
        Niestety, wystąpił problem z przetwarzaniem Twojej transakcji. Prosimy o
        kontakt z obsługą klienta lub spróbuj ponownie później.
      </p>
    </>
  );
};

export default TransactionStatusMessage;
