import React from "react";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { AppState, rootReducer } from "@/reducers";
import { fetchCurrentUser } from "@/actions/wp";
import { ActionAuthTypes } from "@/actions/types";
import { plansApi } from "./api/plansApi";
import { subscriptionsApi } from "./api/subscriptionsApi";
import { wpApi } from "./api/wpApi";
import { ToastProvider } from "./components/ToastContext/ToastContext";
import "bootstrap/dist/css/bootstrap.min.css";

type RootProps = {
  children: React.ReactNode;
  initialState?: AppState;
};

const createStore = (preloadedState?: {}) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        plansApi.middleware,
        subscriptionsApi.middleware,
        wpApi.middleware,
      ),
  });

const Root: React.FC<RootProps> = ({ children, initialState }) => {
  const store = createStore(initialState);

  const token = localStorage.getItem("_wp_react_typescipt_token");

  if (token) {
    store.dispatch({ type: ActionAuthTypes.authUser });
    store.dispatch(fetchCurrentUser());
  }

  return (
    <Provider store={store}>
      <ToastProvider>{children}</ToastProvider>
    </Provider>
  );
};

export type AppDispatch = ReturnType<typeof createStore>["dispatch"];
export default Root;
