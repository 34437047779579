import { createContext, useContext, useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";

type ToastType = "success" | "error" | "info" | "chat";

interface ToastMessage {
  id: string;
  type?: ToastType;
  message?: string;
  autoHide?: boolean;
  duration?: number;
  customToast?: React.ReactNode;
}

interface ToastContextProps {
  enqueueToast: (toast: Omit<ToastMessage, "id">) => void;
}

const ToastContext = createContext<ToastContextProps | undefined>(undefined);

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [toasts, setToasts] = useState<ToastMessage[]>([]);

  const enqueueToast = (toast: Omit<ToastMessage, "id">) => {
    const id = Date.now().toString();
    setToasts((prev) => [...prev, { id, ...toast }]);

    if (toast.autoHide !== false) {
      setTimeout(() => removeToast(id), toast.duration || 5000);
    }
  };

  const removeToast = (id: string) => {
    setToasts((prev) => prev.filter((t) => t.id !== id));
  };

  return (
    <ToastContext.Provider value={{ enqueueToast }}>
      {children}

      <ToastContainer position="top-end" className="p-3">
        {toasts.map(({ id, type, message, customToast, autoHide = true }) => (
          <div key={id}>
            {customToast ? (
              customToast
            ) : (
              <Toast
                bg={
                  type === "error"
                    ? "danger"
                    : type === "success"
                      ? "success"
                      : "info"
                }
                onClose={() => removeToast(id)}
                autohide={autoHide}
              >
                <Toast.Body className="text-white">{message}</Toast.Body>
              </Toast>
            )}
          </div>
        ))}
      </ToastContainer>
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) throw new Error("useToast must be used within ToastProvider");
  return context;
};
