import { FC } from "react";
import { Button, Modal } from "react-bootstrap";
import { CloseIcon } from "@/assets/images/svg/Icons";
import PageHeading from "../headings/PageHeading";

interface IContactFormThanksModal {
  showModal: boolean;
  handleClose: () => void;
}

const ContactFormThanksModal: FC<IContactFormThanksModal> = ({
  handleClose,
  showModal,
}) => {
  return (
    <Modal show={showModal} onHide={handleClose}>
      <div className="modal-wrapper mb">
        <div className="d-flex justify-content-end">
          <Button variant="none" type="button" onClick={handleClose}>
            <CloseIcon />
          </Button>
        </div>
        <Modal.Body>
          <PageHeading variant="h2">DZIĘKUJEMY ZA WIADOMOŚĆ</PageHeading>
          <p className="text-center my-4">
            Twoja wiadomość została pomyślnie wysłana. W międzyczasie, zapraszam
            Cię do zapoznania się z moimi artykułami, poradami treningowymi i
            sukcesami moich klientów na mojej stronie internetowej lub w mediach
            społecznościowych.
          </p>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default ContactFormThanksModal;
