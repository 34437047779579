import { useCallback, useMemo, useState } from "react";

export const useToggle = <T = undefined>(initialOpen = false) => {
  const [isOpenState, setIsOpenState] = useState(initialOpen || false);
  const [additionalProps, setAdditionalProps] = useState<T>();

  const isOpen = useMemo(() => isOpenState, [isOpenState]);

  const open = useCallback(() => {
    setIsOpenState(true);
  }, []);

  const openWithProps = useCallback((info?: T) => {
    setAdditionalProps(info);
    setIsOpenState(true);
  }, []);

  const close = useCallback(() => {
    setAdditionalProps(undefined);
    setIsOpenState(false);
  }, []);

  const toggle = useCallback((open: boolean) => {
    setIsOpenState(!open);
  }, []);

  return { isOpen, open, openWithProps, close, toggle, additionalProps };
};
