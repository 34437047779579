import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./auth_reducer";
import msgReducer from "./msg_reducer";
import wpReducer from "./wp_reducer";
import { plansApi } from "@/api/plansApi";
import { subscriptionsApi } from "@/api/subscriptionsApi";
import { wpApi } from "@/api/wpApi";

export const rootReducer = combineReducers({
  auth: authReducer,
  msg: msgReducer,
  wp: wpReducer,
  [plansApi.reducerPath]: plansApi.reducer,
  [subscriptionsApi.reducerPath]: subscriptionsApi.reducer,
  [wpApi.reducerPath]: wpApi.reducer,
});

export type AppState = ReturnType<typeof rootReducer>;
