import { NewPostData } from "@/actions/wp";

export const surveyOptions = {
  mood: [
    { value: "Świetnie", label: "Świetnie" },
    { value: "Dobrze", label: "Dobrze" },
    { value: "Średnio", label: "Średnio" },
    { value: "Źle", label: "Źle" },
    { value: "Fatalnie", label: "Fatalnie" },
  ],
  energy: [
    { value: "Świetnie", label: "Świetnie" },
    { value: "Dobrze", label: "Dobrze" },
    { value: "Średnio", label: "Średnio" },
    { value: "Źle", label: "Źle" },
    { value: "Fatalnie", label: "Fatalnie" },
  ],
  comfort: [
    { value: "Świetnie", label: "Świetnie" },
    { value: "Dobrze", label: "Dobrze" },
    { value: "Średnio", label: "Średnio" },
    { value: "Źle", label: "Źle" },
    { value: "Fatalnie", label: "Fatalnie" },
  ],
  hunger: [
    { value: "Nigdy", label: "Nigdy" },
    { value: "Rzadko", label: "Rzadko" },
    { value: "Od czasu do czasu", label: "Od czasu do czasu" },
    { value: "Często", label: "Często" },
    { value: "Cały czas", label: "Cały czas" },
  ],
  reliability: [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
  ],
};

export const initialValues: NewPostData = {
  title: "",
  content: "",
  status: "publish",
  meta: {
    report_type: "Tygodniowy",
    report_start_date: "",
    report_end_date: "",
    report_points_mood: "",
    report_points_energy: "",
    report_points_comfort: "",
    report_points_hunger: "",
    report_points_reliability: "",
  },
};
