//TEMPORARY FIX

export const getPlanId = (planId: string) => {
  switch (planId) {
    case "185":
      return "30";
    case "186":
      return "29";
    case "188":
      return "28";
    case "189":
      return "27";
    case "1654":
      return "1652";
    case "1655":
      return "1653";
    case "1762":
      return "1761";
    default:
      return planId;
  }
};
