import React, { useState, useRef } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "./ReportsModal.scss";
import "react-datepicker/dist/react-datepicker.css";
import { format, isValid, parse } from "date-fns";
import { NewPostData } from "@/actions/wp";
import { CloseIcon } from "@/assets/images/svg/Icons";
import { useForm } from "react-hook-form";
import RadioGroup from "@/components/Form/Radio";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddReportValidationSchema } from "@/utils/validators";
import TextField from "@/components/Form/TextField";
import { surveyOptions } from "./helpers";
import { useToast } from "@/components/ToastContext/ToastContext";
import { usePostReportWithImagesMutation } from "@/api/plansApi";

interface AddNewFormProps {
  showModal: boolean;
  handleClose: () => void;
  initialValues: NewPostData;
  isEditForm?: boolean;
}

const ReportsModal: React.FC<AddNewFormProps> = ({
  showModal,
  handleClose,
  initialValues,
  isEditForm,
}) => {
  const { enqueueToast } = useToast();
  const [postReportWithImages, { isLoading }] =
    usePostReportWithImagesMutation();

  const parseCustomDate = (dateStr: string | undefined) => {
    if (!dateStr) return new Date();
    const parsedDate = parse(dateStr, "dd.MM.yyyy", new Date());
    return isValid(parsedDate) ? parsedDate : new Date();
  };

  const initialStartDate = parseCustomDate(
    initialValues.meta?.report_start_date,
  );
  const initialEndDate = parseCustomDate(initialValues.meta?.report_end_date);

  const [dates, setDates] = useState({
    start: initialStartDate,
    end: initialEndDate,
  });

  const startDatePickerRef = useRef<DatePicker>(null);
  const endDatePickerRef = useRef<DatePicker>(null);

  const handleDateChange = (key: "start" | "end") => (date: Date | null) => {
    if (!date) return;

    setDates((prev) => {
      const newDates = { ...prev, [key]: date };

      if (key === "start" && date > prev.end) newDates.end = date;
      if (key === "end" && date < prev.start) newDates.start = date;

      return newDates;
    });
  };

  const openDatePicker = (key: "start" | "end") => {
    if (key === "start") startDatePickerRef.current?.setOpen(true);
    if (key === "end") endDatePickerRef.current?.setOpen(true);
  };

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      setSelectedFiles((prevFiles) => [...prevFiles, ...filesArray]);
    }
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
    }
  };

  const handleRemoveImg = (name: string) => {
    const filtredImages = selectedFiles.filter((img) => img.name !== name);

    setSelectedFiles(filtredImages);
  };

  const { control, handleSubmit, setValue, watch, reset } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(AddReportValidationSchema),
  });

  const submitForm = async (values: NewPostData) => {
    const formattedStartDate = format(dates.start, "dd.MM.yyyy");
    const formattedEndDate = format(dates.end, "dd.MM.yyyy");

    if (values.meta) {
      values.meta.report_start_date = formattedStartDate;
      values.meta.report_end_date = formattedEndDate;
    }

    try {
      await postReportWithImages({ postData: values, images: selectedFiles });
      enqueueToast({
        type: "success",
        message: "Pomyślnie dodano raport",
      });
      onCloseModal();
    } catch (error) {
      enqueueToast({
        type: "error",
        message: "Wystąpił błąd podczas dodawania raportu",
      });
    }
  };

  const onCloseModal = () => {
    reset();
    handleClose();
    setSelectedFiles([]);
  };

  return (
    <Modal show={showModal} onHide={onCloseModal}>
      <div className="modal-wrapper">
        <div className="d-flex justify-content-end">
          <Button variant="none" type="button" onClick={onCloseModal}>
            <CloseIcon />
          </Button>
        </div>

        <header className="position-relative d-block pt-0 border-0 modal-header">
          <h3 className="text-center font-italic modal-title">UTWÓRZ RAPORT</h3>
          <ul className="d-flex justify-content-center list-unstyled m-0">
            <li>Bądź ze sobą szczery</li>
            <li>Odnotuj zmiany w samopoczuciu</li>
            <li>Zajmie to tylko kilka minut</li>
          </ul>
        </header>

        <Modal.Body>
          <form onSubmit={handleSubmit(submitForm)} className="form">
            <Form.Group className="form-group--half form-group">
              <label className="d-block form-label">Typ raportu:</label>
              <div className="form-field d-flex overflow-hidden">
                <button
                  type="button"
                  className={
                    watch("meta.report_type") === "Tygodniowy"
                      ? "form-button form-button--active"
                      : "form-button"
                  }
                  onClick={() => setValue("meta.report_type", "Tygodniowy")}
                >
                  Tygodniowy
                </button>

                <button
                  type="button"
                  className={
                    watch("meta.report_type") === "Miesięczny"
                      ? "form-button form-button--active"
                      : "form-button"
                  }
                  onClick={() => setValue("meta.report_type", "Miesięczny")}
                >
                  Miesięczny
                </button>
              </div>
            </Form.Group>

            <Form.Group className="d-flex flex-column form-group--half form-group">
              <label className="d-block form-label">okres raportu:</label>
              <div className="form-group__dates">
                {(["start", "end"] as const).map((key) => {
                  const formattedDate = format(dates[key], "dd MM yyyy").split(
                    " ",
                  );
                  const ref =
                    key === "start" ? startDatePickerRef : endDatePickerRef;

                  return (
                    <div key={key} className="form-field--date">
                      <span>{key === "start" ? "Od" : "Do"}</span>

                      <div
                        className="date-wrapper d-flex justify-content-between gap-1"
                        onClick={() => openDatePicker(key)}
                      >
                        {formattedDate.map((part, index) => (
                          <span key={index} className="form-control">
                            {part}
                          </span>
                        ))}
                      </div>

                      <DatePicker
                        ref={ref}
                        selected={dates[key]}
                        onChange={handleDateChange(key)}
                        dateFormat="dd MM yyyy"
                        className="d-none"
                      />
                    </div>
                  );
                })}
              </div>
            </Form.Group>

            <TextField
              control={control}
              name="title"
              label="Tytuł raportu"
              placeholder="Tytuł, który pozwoli wyróżnić Ci ten raport np. “Ciężki tydzień, początek przygody.”"
            />

            <TextField
              control={control}
              name="content"
              label="treść raportu"
              placeholder="Maks. 800 znaków."
              rows={5}
              maxLength={800}
              textarea
            />

            <Form.Group className="form-group">
              <div className="form-label__wrapper">
                <label htmlFor="" className="form-label">
                  dodaj zdjęcia
                </label>
                <div className="form__images">
                  {selectedFiles.length
                    ? selectedFiles.map((img, idx) => {
                        return (
                          <div
                            key={`${img.size}-${idx}`}
                            className="img_preview"
                          >
                            <span onClick={() => handleRemoveImg(img.name)}>
                              <CloseIcon />
                            </span>

                            <img src={URL.createObjectURL(img)} alt="Podgląd" />
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>
              {selectedFiles.length < 6 ? (
                <div className="d-flex flex-column justify-content-center align-items-center form-field form-field--media">
                  <input
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept=".png, .jpg, .jpeg"
                  />

                  <span className="d-block">
                    Kliknij przycisk aby dodać zdjęcie, lub upuść pliki tutaj.
                  </span>

                  <button
                    className="custom-file-upload"
                    type="button"
                    onClick={handleButtonClick}
                  >
                    DODAJ ZDJĘCIE
                  </button>

                  <span className="d-block">
                    Max 6 zdjęć. Dopuszczalne formaty: .jpeg, .png, .heic
                  </span>
                </div>
              ) : null}
            </Form.Group>

            <RadioGroup
              control={control}
              name="meta.report_points_mood"
              title="1. Jak oceniasz swoje ogólne samopoczucie w tym okresie?"
              required
              options={surveyOptions.mood}
            />

            <RadioGroup
              control={control}
              name="meta.report_points_energy"
              title="2. Jak oceniasz swój poziom energii w tym okresie?"
              required
              options={surveyOptions.energy}
            />

            <RadioGroup
              control={control}
              name="meta.report_points_comfort"
              title="3. Jak oceniasz swój komfort żołądkowy / trawienny w tym okresie?"
              required
              options={surveyOptions.comfort}
            />

            <RadioGroup
              control={control}
              name="meta.report_points_hunger"
              title="4. Czy byłeś/aś głodny w tym okresie?"
              required
              options={surveyOptions.hunger}
            />

            <RadioGroup
              control={control}
              name="meta.report_points_reliability"
              title="5. Jak rzetelnie przestrzegałeś planu treningowego i diety (w skali 0-5)?"
              required
              options={surveyOptions.reliability}
            />

            <div className="modal-submit">
              <Button variant="primary" type="submit" disabled={isLoading}>
                {isLoading ? (
                  <span className="spinner-grow spinner-grow-sm" />
                ) : (
                  "ZATWIERDŹ I WYŚLIJ"
                )}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default ReportsModal;
