import PageHeading from "@/components/headings/PageHeading";
import Faq from "@/components/Faq/Faq";
import MichalStopka from "@/assets/images/png/michal-stopka.png";
import "./FaqForm.scss";
import ContactUsForm from "@/components/Form/ContactUsForm";

const FaqForm = () => {
  return (
    <section className="faq-form">
      <div className="container">
        <div className="faq-form__wrapper">
          <div className="faq-form__left">
            <Faq showHeader={false} />
          </div>
          <div className="faq-form__right">
            <div className="right__wrapper">
              <header className="right__header">
                <div className="right__heading">
                  <PageHeading variant="h2">
                    CIĄGLE MASZ WĄTPLIWOŚCI?
                  </PageHeading>
                  <PageHeading variant="h3">NAPISZ DO MNIE!</PageHeading>
                </div>
                <div className="d-flex right__avatars">
                  <div className="avatar">
                    <img src={MichalStopka} alt="MICHAŁ BOBYK" />
                  </div>
                </div>
              </header>
              <p>
                Wiadomości wysłane za pomocą tego formularza trafią na moją
                firmową skrzynkę mailową. Odpiszę najszybciej jak pozwoli mi na
                to Nasz rozkład treningów. Jeśli zależy Ci na kontakcie
                bezpośrednim, przejdź do strony kontakt.
              </p>

              <ContactUsForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqForm;
