import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import AppScreenshot from "@/assets/images/png/app-screenshot.png";
import { PlusIcon } from "@/assets/images/svg/Icons";
import {
  SmallAnimatedArrows,
  MobileAnimatedArrows,
  AnimatedArrows,
} from "@/components/AnimatedArrows/AnimatedArrows";
import PageHeading from "@/components/headings/PageHeading";

import "./DevelopmentCenterSection.scss";

const items = [
  {
    id: 1,
    text: "dostęp do +100 autorskich wideo instruktażowych",
  },
  {
    id: 2,
    text: "kontakt z trenerem 7 dni w tygodniu* poprzez panel czatu",
  },
  {
    id: 3,
    text: "kalkulator zapotrzebowania kcal oraz makroskładników",
  },
  {
    id: 4,
    text: "system monitorowania sylwetki w oparciu raporty i zdjęcia",
  },
  {
    id: 5,
    text: "podgląd aktualnego planu treningowego ",
  },
];

const DevelopmentCenterSection = () => {
  return (
    <section className="development-center">
      <div className="container">
        <div className="development-center__wrapper">
          <div className="d-flex development-center__image">
            <div className="position-relative">
              <SmallAnimatedArrows />
              <MobileAnimatedArrows />
              <MobileAnimatedArrows />
              <MobileAnimatedArrows />
              <SmallAnimatedArrows />
              <AnimatedArrows />
              <SmallAnimatedArrows />
              <SmallAnimatedArrows />
              <img src={AppScreenshot} alt="app-screenshot" />
            </div>
          </div>
          <div className="d-flex flex-column development-center__content">
            <PageHeading variant="h2">ZAWSZE POD RĘKĄ</PageHeading>
            <PageHeading variant="h3">TWOJE CENTRUM ROZWOJU</PageHeading>
            <p>
              Twój panel klienta w Health And Body Academy to tak naprawdę
              mobilne centrum Twojej przemiany. Jako posiadacz jednego z
              pakietów zyskujesz dostęp przez naszą witrynę m.in:
            </p>
            <ul className="list-unstyled mx-0">
              {items.map((item) => {
                return (
                  <li key={item.id} className="d-flex">
                    <div className="d-inline-flex justify-content-center align-items-center svg-container">
                      <PlusIcon />
                    </div>
                    {item.text}
                  </li>
                );
              })}
            </ul>
            <span>
              *-Panel czatu jest dostępny we wszystkich pakietach oprócz pakietu
              ADAPTACJA.
            </span>
            <div className="development-center__button">
              <Link to="/rejestracja">
                <Button variant="primary">Zacznij teraz</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DevelopmentCenterSection;
