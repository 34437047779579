import { FC } from "react";
import { Button, Modal } from "react-bootstrap";
import { CloseIcon } from "@/assets/images/svg/Icons";
import "./ReportsPreviewModal.scss";

interface IReportsPreviewModal {
  raport: any;
  handleClose: () => void;
  showModal: boolean;
}

const poolAnswears = {
  great: "Świetnie",
  good: "Dobrze",
  average: "Średnio",
  bad: "Źle",
  fatally: "Fatalnie",
  never: "Nigdy",
  rarely: "Rzadko",
  timeToTime: "Od czasu do czasu",
  often: "Często",
  wholeTime: "Cały czas",
};

const ReportsPreviewModal: FC<IReportsPreviewModal> = ({
  raport,
  handleClose,
  showModal,
}) => {
  const startDate = raport?.meta?.report_start_date?.split(".");
  const endDate = raport?.meta?.report_end_date?.split(".");

  return (
    <Modal show={showModal} onHide={handleClose}>
      <div className="modal-wrapper">
        <div className="d-flex justify-content-end">
          <Button variant="none" type="button" onClick={handleClose}>
            <CloseIcon />
          </Button>
        </div>
        <header>
          <h3 className="text-center font-italic modal__title">
            {raport?.title.rendered}
          </h3>
          <p className="text-center font-italic modal__client">
            Klient: {raport?.author_details.name}
          </p>
        </header>
        <div className="modal__body">
          <div className="modal__body-head d-flex">
            <div className="type">
              <span className="label">Typ raportu</span>
              <div className="data__wr">{raport?.meta.report_type}</div>
            </div>
            <div className="time">
              <span className="label">Okres raportu</span>
              <div className="d-flex time__dates">
                <div className="d-flex align-items-center mr-4 mb-2 mb-md-0">
                  <span className="text-uppercase font-weight-900 mr-3 font-italic bold_text">
                    od
                  </span>
                  {startDate.map((item: any, idx: number) => (
                    <div key={`${item}-${idx}`} className="data__wr">
                      {item}
                    </div>
                  ))}
                </div>
                <div className="d-flex align-items-center">
                  <span className="text-uppercase mr-3 font-italic bold_text">
                    do
                  </span>
                  {endDate.map((item: any, idx: number) => (
                    <div key={`${item}-${idx}`} className="data__wr">
                      {item}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <span className="label">Treść raportu</span>
          <div className="modal__body-content">
            <p dangerouslySetInnerHTML={{ __html: raport?.content.rendered }} />
          </div>
          <span className="label mt-4 d-block">Zdjęcia</span>
          <div className="poll">
            <label className="d-block w-100">
              1. Jak oceniasz swoje ogólne samopoczucie w tym okresie?*
            </label>
            <div className="poll__item">
              <div
                className={`poll__item-answear ${
                  raport?.meta.report_points_mood === poolAnswears.great
                    ? "active"
                    : ""
                } `}
              >
                <span className="dot" />
                {poolAnswears.great}
              </div>
              <div
                className={`poll__item-answear ${
                  raport?.meta.report_points_mood === poolAnswears.good
                    ? "active"
                    : ""
                } `}
              >
                <span className="dot" />
                {poolAnswears.good}
              </div>
              <div
                className={`poll__item-answear ${
                  raport?.meta.report_points_mood === poolAnswears.average
                    ? "active"
                    : ""
                } `}
              >
                <span className="dot" />
                {poolAnswears.average}
              </div>
              <div
                className={`poll__item-answear ${
                  raport?.meta.report_points_mood === poolAnswears.bad
                    ? "active"
                    : ""
                } `}
              >
                <span className="dot" />
                {poolAnswears.bad}
              </div>
              <div
                className={`poll__item-answear ${
                  raport?.meta.report_points_mood === poolAnswears.fatally
                    ? "active"
                    : ""
                } `}
              >
                <span className="dot" />
                {poolAnswears.fatally}
              </div>
            </div>
            <label className="d-block w-100">
              2. Jak oceniasz swój poziom energii w tym okresie?*
            </label>
            <div className="poll__item">
              <div
                className={`poll__item-answear ${
                  raport?.meta.report_points_energy === poolAnswears.great
                    ? "active"
                    : ""
                } `}
              >
                <span className="dot" />
                {poolAnswears.great}
              </div>
              <div
                className={`poll__item-answear ${
                  raport?.meta.report_points_energy === poolAnswears.good
                    ? "active"
                    : ""
                } `}
              >
                <span className="dot" />
                {poolAnswears.good}
              </div>
              <div
                className={`poll__item-answear ${
                  raport?.meta.report_points_energy === poolAnswears.average
                    ? "active"
                    : ""
                } `}
              >
                <span className="dot" />
                {poolAnswears.average}
              </div>
              <div
                className={`poll__item-answear ${
                  raport?.meta.report_points_energy === poolAnswears.bad
                    ? "active"
                    : ""
                } `}
              >
                <span className="dot" />
                {poolAnswears.bad}
              </div>
              <div
                className={`poll__item-answear ${
                  raport?.meta.report_points_energy === poolAnswears.fatally
                    ? "active"
                    : ""
                } `}
              >
                <span className="dot" />
                {poolAnswears.fatally}
              </div>
            </div>
            <label className="d-block w-100">
              3. Jak oceniasz swój komfort żołądkowy / trawienny w tym okresie?*
            </label>
            <div className="poll__item">
              <div
                className={`poll__item-answear ${
                  raport?.meta.report_points_comfort === poolAnswears.great
                    ? "active"
                    : ""
                } `}
              >
                <span className="dot" />
                {poolAnswears.great}
              </div>
              <div
                className={`poll__item-answear ${
                  raport?.meta.report_points_comfort === poolAnswears.good
                    ? "active"
                    : ""
                } `}
              >
                <span className="dot" />
                {poolAnswears.good}
              </div>
              <div
                className={`poll__item-answear ${
                  raport?.meta.report_points_comfort === poolAnswears.average
                    ? "active"
                    : ""
                } `}
              >
                <span className="dot" />
                {poolAnswears.average}
              </div>
              <div
                className={`poll__item-answear ${
                  raport?.meta.report_points_comfort === poolAnswears.bad
                    ? "active"
                    : ""
                } `}
              >
                <span className="dot" />
                {poolAnswears.bad}
              </div>
              <div
                className={`poll__item-answear ${
                  raport?.meta.report_points_comfort === poolAnswears.fatally
                    ? "active"
                    : ""
                } `}
              >
                <span className="dot" />
                {poolAnswears.fatally}
              </div>
            </div>
            <label className="d-block w-100">
              4. Czy byłeś/aś głodny w tym okresie?*
            </label>
            <div className="poll__item">
              <div
                className={`poll__item-answear ${
                  raport?.meta.report_points_hunger === poolAnswears.never
                    ? "active"
                    : ""
                } `}
              >
                <span className="dot" />
                {poolAnswears.never}
              </div>
              <div
                className={`poll__item-answear ${
                  raport?.meta.report_points_hunger === poolAnswears.rarely
                    ? "active"
                    : ""
                } `}
              >
                <span className="dot" />
                {poolAnswears.rarely}
              </div>
              <div
                className={`poll__item-answear ${
                  raport?.meta.report_points_hunger === poolAnswears.timeToTime
                    ? "active"
                    : ""
                } `}
              >
                <span className="dot" />
                {poolAnswears.timeToTime}
              </div>
              <div
                className={`poll__item-answear ${
                  raport?.meta.report_points_hunger === poolAnswears.often
                    ? "active"
                    : ""
                } `}
              >
                <span className="dot" />
                {poolAnswears.often}
              </div>
              <div
                className={`poll__item-answear ${
                  raport?.meta.report_points_hunger === poolAnswears.wholeTime
                    ? "active"
                    : ""
                } `}
              >
                <span className="dot" />
                {poolAnswears.wholeTime}
              </div>
            </div>
            <label className="d-block w-100">
              5. Jak rzetelnie przestrzegałeś planu treningowego i diety (w
              skali 0-5)?*
            </label>
            <div className="poll__item">
              <div
                className={`poll__item-answear ${
                  raport?.meta.report_points_reliability === "1" ? "active" : ""
                } `}
              >
                <span className="dot" />1
              </div>
              <div
                className={`poll__item-answear ${
                  raport?.meta.report_points_reliability === "2" ? "active" : ""
                } `}
              >
                <span className="dot" />2
              </div>
              <div
                className={`poll__item-answear ${
                  raport?.meta.report_points_reliability === "3" ? "active" : ""
                } `}
              >
                <span className="dot" />3
              </div>
              <div
                className={`poll__item-answear ${
                  raport?.meta.report_points_reliability === "4" ? "active" : ""
                } `}
              >
                <span className="dot" />4
              </div>
              <div
                className={`poll__item-answear ${
                  raport?.meta.report_points_reliability === "5" ? "active" : ""
                } `}
              >
                <span className="dot" />5
              </div>
            </div>
          </div>
        </div>

        <footer className="modal__footer">
          <Button variant="secondary" type="button" onClick={handleClose}>
            Zamknij
          </Button>
        </footer>
      </div>
    </Modal>
  );
};

export default ReportsPreviewModal;
