import { useCallback, useState } from "react";
import * as CryptoJS from "crypto-js";
import Products from "@/components/Products/Products";
import Loader from "@/components/Loader/Loader";
import PageHeading from "@/components/headings/PageHeading";
import { useDispatch } from "react-redux";
import { unAuthUser } from "@/actions/auth";
import { Alert, Button } from "react-bootstrap";
import "./SelectPackage.scss";
import { useWpSelector } from "@/selectors";
import { BackIcon } from "@/assets/images/svg/Icons";
import links from "@/constants/links";
import {
  useGetActiveClientSubscriptionQuery,
  useGetProductsQuery,
} from "@/api/subscriptionsApi";
import { useAddToCartMutation, useClearCartMutation } from "@/api/wpApi";
import Constants from "@/constants";
import {
  createPrzelewy24Transaction,
  createSubscription,
} from "@/pages/SignUp/AddNew/helpers";
import { useCreateOrderHandler } from "@/hooks/useCreateOrderHandler";
import { Link } from "react-router-dom";
import LinkButton from "@/components/Buttons/LinkButton";

const SelectPackage = () => {
  const [paymentSubmitting, setPaymentSubmitting] = useState(false);
  const [medipaySubmitting, setMedipaySubmitting] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [selectedPackage, setSelectedPackage] = useState<any>(null);

  const { handleCreateOrder } = useCreateOrderHandler();

  const dispatch = useDispatch();

  const { currentUser } = useWpSelector();
  const { data: userSubscription } = useGetActiveClientSubscriptionQuery(
    currentUser?.id!,
  );

  const [addToCart] = useAddToCartMutation();
  const [clearCart] = useClearCartMutation();

  const { data: products, isLoading, isError } = useGetProductsQuery();

  const Logout = useCallback(() => {
    dispatch(
      unAuthUser(() => {
        window.location.reload();
      }),
    );
  }, [dispatch]);

  const handleAddToCart = async (productId: number, quantity = 1) => {
    try {
      await clearCart().unwrap();

      const cartResponse = await addToCart({ productId, quantity }).unwrap();
      setSelectedPackage(cartResponse);
      const product = products?.find((p) => p.id === productId);

      if (product) {
        setSelectedProduct({ ...product, cartKey: cartResponse.cart_key });
      }
    } catch (_) {
      alert("Wystąpił błąd podczas aktualizacji koszyka.");
    }
  };

  const handleMediPay = async () => {
    setMedipaySubmitting(true);

    try {
      const createdOrder = await handleCreateOrder({
        userId: currentUser?.id!,
        payment: { method_name: "mediPay", method_title: "mediPay" },
        cartData: selectedPackage,
        userData: currentUser!,
      });

      if (!createdOrder || isError) {
        setMedipaySubmitting(false);
        alert("Wystąpił błąd podczas tworzenia zamówienia.");
        return;
      }

      const subscriptionResponse = await createSubscription(
        currentUser?.id,
        createdOrder.order_key,
        createdOrder.id,
        createdOrder.line_items,
        {
          productMetaData: { [selectedProduct.id]: selectedProduct.meta_data },
        },
        userSubscription?.next_payment_date_gmt || undefined,
      );

      if (subscriptionResponse.error) {
        alert("Błąd podczas tworzenia subskrypcji");

        setMedipaySubmitting(false);
        return;
      }

      const totalPrice = createdOrder.total;

      const mediPayData = {
        userId: `${currentUser?.id}-${Date.now()}`,
        firstName: currentUser?.first_name,
        lastName: currentUser?.last_name,
        phoneNumber: currentUser?.meta.phone,
        amount: totalPrice,
        callbackUrl: `${Constants.baseUri}/wp-json/myapp/v1/mediPayCallback`,
        webhookUrl: `${window.location.origin}/dziekujemy?externalId=HEAB-${currentUser?.id}-${Date.now()}`,
      };

      const mediPayResponse = await fetch(
        `${Constants.baseUri}/wp-json/myapp/v1/mediPayPayment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(mediPayData),
        },
      );

      const mediPayResult = await mediPayResponse.json();

      if (!mediPayResponse.ok || mediPayResult.error) {
        setMedipaySubmitting(false);
        alert(
          `Błąd MediPay: ${mediPayResult.error === "transRejected" ? "Wniosek został odrzucony, klient nie może dostać finansowania" : mediPayResult.error || "Nieznany błąd"}`,
        );
        return;
      }

      if (mediPayResult.clientLink) {
        window.location.href = mediPayResult.clientLink;
      } else {
        setMedipaySubmitting(false);
        alert("Błąd: Brak linku do płatności.");
      }
    } catch (_) {
      setMedipaySubmitting(false);
      alert("Wystąpił błąd podczas przetwarzania MediPay.");
    }
  };

  const handlePrzelewy24 = async () => {
    setPaymentSubmitting(true);

    try {
      const createdOrder = await handleCreateOrder({
        userId: currentUser?.id!,
        payment: { method_name: "p24", method_title: "Przelewy24" },
        cartData: selectedPackage,
        userData: currentUser!,
      });

      if (!createdOrder || isError) {
        setPaymentSubmitting(false);
        alert("Wystąpił błąd podczas tworzenia zamówienia.");
        return;
      }

      const subscriptionResponse = await createSubscription(
        currentUser?.id,
        createdOrder.order_key,
        createdOrder.id,
        createdOrder.line_items,
        {
          productMetaData: { [selectedProduct.id]: selectedProduct.meta_data },
        },
        userSubscription?.next_payment_date_gmt || undefined,
      );

      if (subscriptionResponse.error) {
        console.error(
          "Błąd podczas tworzenia subskrypcji:",
          subscriptionResponse.data,
        );

        setPaymentSubmitting(false);
        return;
      }

      let sessionId: string = subscriptionResponse.data.order_key;
      let merchantId: number = 235900;
      let currency: string = "PLN";
      let crc: string = "929c4f4c66ca54eb";

      const totalAmount = Math.round(subscriptionResponse.data.total * 100);

      let obj = {
        sessionId: sessionId,
        merchantId: merchantId,
        amount: totalAmount,
        currency: currency,
        crc: crc,
      };

      let jsonStr = JSON.stringify(obj);
      let hash = CryptoJS.SHA384(jsonStr);
      let hashedStr = hash.toString(CryptoJS.enc.Hex);

      const transactionData = {
        merchantId: 235900,
        posId: 235900,
        sessionId: sessionId,
        amount: totalAmount,
        currency: "PLN",
        description: sessionId,
        email: currentUser?.user_email!,
        country: "PL",
        language: "pl",
        urlReturn: `${window.location.origin}/dziekujemy?id=${createdOrder.id}&sessionId=${sessionId}`,
        sign: hashedStr,
      };

      await createPrzelewy24Transaction(transactionData);

      setPaymentSubmitting(false);
    } catch (_) {
      alert("Wystąpił błąd podczas tworzenia zamówienia.");

      setPaymentSubmitting(false);
    }
  };

  return (
    <div className="dashboard__packages">
      <div className="container column">
        <PageHeading variant="h2">
          WYBIERZ <span>TWÓJ</span> PAKIET
        </PageHeading>

        {isLoading && (
          <div className="loading-overlay">
            <Loader />
          </div>
        )}

        {isError && <Alert variant="danger">Wystąpił błąd</Alert>}

        {!isLoading && !isError && products && (
          <Products
            products={products}
            selectedId={selectedProduct?.id}
            addToCart={handleAddToCart}
          />
        )}
      </div>

      <div className="container">
        <div className="container__actions">
          <h5>Wybierz pakiet i zapłać</h5>

          <Button
            className="mediPay-btn"
            variant="primary-blue"
            type="button"
            onClick={handleMediPay}
            disabled={
              paymentSubmitting || medipaySubmitting || !selectedProduct
            }
            style={{
              pointerEvents:
                medipaySubmitting || !selectedProduct ? "none" : "initial",
            }}
          >
            {medipaySubmitting ? <Loader variant="light" /> : "MediPay"}
          </Button>

          <Button
            variant="primary"
            type="button"
            onClick={handlePrzelewy24}
            disabled={
              paymentSubmitting || medipaySubmitting || !selectedProduct
            }
            style={{
              pointerEvents:
                paymentSubmitting || !selectedProduct ? "none" : "initial",
            }}
          >
            {paymentSubmitting ? <Loader variant="light" /> : "Przelewy24"}
          </Button>

          {userSubscription ? (
            <LinkButton
              // as={Link}
              href={links.panelPackages}
              //   className="text-center"
              type="link"
              variant="secondary"
            >
              <BackIcon />
              Wróć
            </LinkButton>
          ) : (
            <Button
              onClick={Logout}
              title="Wyloguj"
              variant="secondary"
              style={{ justifyContent: "center" }}
            >
              Wyloguj
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectPackage;
