import { Product } from "@/actions/wp";
import Constants from "@/constants";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// TODO import types

export interface CreateOrderArgs {
  customer_id: number;
  payment_method: string;
  payment_method_title: string;
  set_paid: boolean;
  billing: {
    first_name: string;
    last_name: string;
    address_1: string;
    address_2?: string;
    city: string;
    state: string;
    postcode: string;
    country: string;
    email: string;
  };
  shipping: {
    first_name: string;
    last_name: string;
    address_1: string;
    address_2?: string;
    city: string;
    state: string;
    postcode: string;
    country: string;
  };
  line_items: {
    product_id: number;
    quantity: number;
  }[];
}

export interface OrderResponse {
  id: number;
  order_key: string;
  total: string;
  billing: {
    first_name: string;
    last_name: string;
    address_1: string;
    address_2?: string;
    city: string;
    state: string;
    postcode: string;
    country: string;
    email: string;
  };
  shipping: {
    first_name: string;
    last_name: string;
    address_1: string;
    address_2?: string;
    city: string;
    postcode: string;
    country: string;
  };
  line_items: Array<{
    product_id: number;
    quantity: number;
  }>;
}

export interface Subscription {
  customer_id: number | null;
  id: number | null;
  next_payment_date_gmt: string | "";
  last_payment_date_gmt: string | "";
  start_date_gmt?: string | null;
  needs_payment: boolean;
  line_items: { name: string; product_id: number }[];
  status?: "on-hold" | "active" | "pending" | "expired";
}

export const subscriptionsApi = createApi({
  reducerPath: "subscriptionsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${Constants.WC}`,
    prepareHeaders: (headers) => {
      headers.set(
        "Authorization",
        `Basic ${btoa(
          `${Constants.consumer_key}:${Constants.consumer_secret}`,
        )}`,
      );
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getClientsSubscriptions: builder.query<
      Subscription[],
      { page: number; perPage: number }
    >({
      query: ({ page, perPage }) => ({
        url: `/subscriptions`,
        params: { page, per_page: perPage },
      }),
    }),
    getClientSubscriptions: builder.query<Subscription[] | null, number>({
      query: (clientId) => `/subscriptions?customer=${clientId}`,
    }),
    getActiveClientSubscription: builder.query<Subscription | null, number>({
      query: (clientId) => `/subscriptions?customer=${clientId}`,
      transformResponse: (response: Subscription[]) =>
        response.length > 0
          ? response.find((sub) => sub.status === "active") || null
          : null,
    }),
    getProducts: builder.query<Product[], void>({
      query: () =>
        `/products?consumer_key=${Constants.consumer_key}&consumer_secret=${Constants.consumer_secret}&order=asc&orderby=price`,
    }),
    createOrder: builder.mutation<OrderResponse, CreateOrderArgs>({
      query: (orderData) => ({
        url: "/orders",
        method: "POST",
        body: orderData,
      }),
    }),
  }),
});

export const {
  useGetClientSubscriptionsQuery,
  useGetClientsSubscriptionsQuery,
  useGetActiveClientSubscriptionQuery,
  useGetProductsQuery,
  useCreateOrderMutation,
} = subscriptionsApi;
