import { NewUserData, User } from "@/actions/wp";
import {
  CreateOrderArgs,
  OrderResponse,
  useCreateOrderMutation,
} from "@/api/subscriptionsApi";

interface CartData {
  items: { id: number; quantity: { value: number } }[];
}

interface PaymentData {
  method_name: string;
  method_title: string;
}

interface CreateOrderParams {
  userId: number;
  cartData: CartData;
  userData: NewUserData | User;
  payment: PaymentData;
  isCurrentUser?: boolean;
}

export const useCreateOrderHandler = () => {
  const [createOrderMutation, mutationResult] = useCreateOrderMutation();

  const handleCreateOrder = async ({
    userId,
    cartData,
    userData,
    payment,
    isCurrentUser,
  }: CreateOrderParams): Promise<OrderResponse> => {
    const address_1 = isCurrentUser
      ? (userData as User).billing_address_1
      : (userData as NewUserData).address;
    const city = isCurrentUser
      ? (userData as User).billing_city
      : (userData as NewUserData).city;
    const postcode = isCurrentUser
      ? (userData as User).billing_postcode
      : (userData as NewUserData).postal_code;
    const email = isCurrentUser
      ? (userData as User).user_email
      : (userData as NewUserData).email;

    const orderRequestData: CreateOrderArgs = {
      customer_id: userId,
      payment_method: payment.method_name,
      payment_method_title: payment.method_title,
      set_paid: false,
      billing: {
        first_name: userData.first_name,
        last_name: userData.last_name,
        address_1,
        address_2: "",
        city,
        state: "",
        postcode,
        country: "PL",
        email,
      },
      shipping: {
        first_name: userData.first_name,
        last_name: userData.last_name,
        address_1,
        address_2: "",
        city,
        state: "",
        postcode,
        country: "PL",
      },
      line_items: cartData.items.map((item) => ({
        product_id: item.id,
        quantity: item.quantity.value,
      })),
    };

    const createdOrder = await createOrderMutation(orderRequestData).unwrap();
    return createdOrder;
  };

  return { handleCreateOrder, ...mutationResult };
};
