import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";

import RouteHeading from "@/components/headings/RouteHeading";

import "@/components/Goals/Goals.scss";
import "./TargetForm.scss";
import { fetchGoals, Goal, updateUser, User } from "@/actions/wp";
import { useWpSelector } from "@/selectors";
import Item from "@/components/Goals/Item";
import TextField from "@/components/Form/TextField";
import { useForm } from "react-hook-form";

interface ITargetForm {
  currentUser: User;
}

type FormValues = {
  goal: string;
  weight: number;
  weight_current: number;
  weight_target: number;
};

const TargetForm: React.FC<ITargetForm> = ({ currentUser }) => {
  const dispatch = useDispatch();
  const { goals } = useWpSelector();
  const [activeGoalId, setActiveGoalId] = useState(currentUser.meta.goal);

  useEffect(() => {
    dispatch(fetchGoals());
  }, [dispatch]);

  const handleGoalClick = (
    goalId: string,
    setFieldValue: (
      field: "goal" | "weight" | "weight_current" | "weight_target",
      value: string,
    ) => void,
  ) => {
    setActiveGoalId(goalId);
    setFieldValue("goal", goalId);
  };

  const handleUpdateUser = (values: FormValues) => {
    const updatedUserData = {
      meta: {
        goal: values.goal,
        weight: values.weight,
        weight_current: values.weight_current,
        weight_target: values.weight_target,
      },
    };

    dispatch(updateUser(currentUser.id.toString(), updatedUserData));
  };

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      goal: currentUser.meta.goal || "",
      weight: currentUser.meta.weight || 0,
      weight_current: currentUser.meta.weight_current || 0,
      weight_target: currentUser.meta.weight_target || 0,
    },
  });

  return (
    <form onSubmit={handleSubmit(handleUpdateUser)} className="form">
      <div className="form__wrapper">
        <header className="position-relative dashboard__header target__header">
          <RouteHeading>TWÓJ CEL</RouteHeading>
        </header>

        <Form.Group className="form-group-row form-group--full-width form-group">
          <div className="goals">
            {goals.map((goal: Goal, index: number) => (
              <Item
                formValues={getValues()}
                key={index}
                goal={goal}
                isActive={activeGoalId === goal.id.toString()}
                onClick={() => handleGoalClick(goal.id.toString(), setValue)}
              />
            ))}
          </div>
        </Form.Group>

        <header className="position-relative dashboard__header target__header">
          <RouteHeading>SZCZEGÓŁY</RouteHeading>
        </header>

        <TextField
          control={control}
          type="number"
          name="weight"
          label="waga startowa"
          placeholder="[KG]"
        />

        <TextField
          control={control}
          type="number"
          name="weight_current"
          label="WAGA bieżąca"
          placeholder="[KG]"
        />

        <TextField
          control={control}
          type="number"
          name="weight_target"
          label="waga docelowa"
          placeholder="[KG]"
        />
      </div>
      <Button variant="secondary" type="submit" disabled={isSubmitting}>
        {isSubmitting ? "Trwa zapisywanie..." : "Zapisz zmiany"}
      </Button>
    </form>
  );
};

export default TargetForm;
