import { ActionAuthTypes, ActionMessagesTypes } from "./types";
import axios, { AxiosResponse } from "axios";
import Constants from "@/constants";
import { AllowedRoles, User } from "./wp";
import { AppDispatch } from "@/Root";
import { subscriptionsApi } from "@/api/subscriptionsApi";
import links from "@/constants/links";

export const tokenHeader = (): { headers: { Authorization: string } } => {
  const token = localStorage.getItem("_wp_react_typescipt_token");
  return { headers: { Authorization: `Bearer ${token}` } };
};

export const tokenHeaderWithParams = (
  params: any,
): {
  headers: { Authorization: string };
  params: any;
} => {
  const token = localStorage.getItem("_wp_react_typescipt_token");
  return {
    headers: { Authorization: `Bearer ${token}` },
    params: params,
  };
};

export interface FormValues {
  username: string;
  password: string;
}

export interface AuthUserAction {
  type: ActionAuthTypes.authUser;
}

export interface WarningMessageAction {
  type: ActionMessagesTypes.warningMsg;
  payload: string;
}

export interface ClearMessageAction {
  type: ActionMessagesTypes.clearMsg;
}

export type AuthUserType = (
  credentials: FormValues,
  callback: (res: AxiosResponse | any) => void,
) => void;

export const authUser: AuthUserType = (credentials, callback) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch<ClearMessageAction>({ type: ActionMessagesTypes.clearMsg });

      const authResponse = await axios.post(
        `${Constants.jwtAuthUri}`,
        credentials,
        { headers: { Accept: "application/json" } },
      );

      const token = authResponse.data.token;
      localStorage.setItem("_wp_react_typescipt_token", token);

      const userResponse = await axios.get<User>(
        `${Constants.apiUri}/users/me`,
        { headers: { Authorization: `Bearer ${token}` } },
      );

      const isAuthorized =
        userResponse.data.roles.includes(AllowedRoles.admin) ||
        userResponse.data.roles.includes(AllowedRoles.author);

      if (!isAuthorized) {
        dispatch<WarningMessageAction>({
          type: ActionMessagesTypes.warningMsg,
          payload: "Brak uprawnień.",
        });
        return callback(new Error("Brak uprawnień."));
      }

      dispatch<AuthUserAction>({ type: ActionAuthTypes.authUser });

      try {
        const subscription = await dispatch(
          subscriptionsApi.endpoints.getActiveClientSubscription.initiate(
            userResponse.data.id,
          ),
        ).unwrap();

        if (!subscription) {
          return (window.location.href = links.selectPackage);
        }
      } catch (subscriptionError) {
        console.error("Błąd pobierania subskrypcji:", subscriptionError);
        return (window.location.href = links.selectPackage);
      }

      window.location.href = links.dashboard;
    } catch (error) {
      dispatch<WarningMessageAction>({
        type: ActionMessagesTypes.warningMsg,
        payload: "Niepoprawna nazwa użytkownika lub hasło",
      });
      callback(error);
    }
  };
};

export interface UnAuthUserAction {
  type: ActionAuthTypes.unAuthUser;
}

export interface SuccessMessageAction {
  type: ActionMessagesTypes.successMsg;
  payload: string;
}

export const unAuthUser = (callback: () => void) => {
  localStorage.removeItem("_wp_react_typescipt_token");
  return (dispatch: AppDispatch) => {
    dispatch<UnAuthUserAction>({ type: ActionAuthTypes.unAuthUser });
    dispatch<ClearMessageAction>({ type: ActionMessagesTypes.clearMsg });
    dispatch<SuccessMessageAction>({
      type: ActionMessagesTypes.successMsg,
      payload: "Thanks for trying the app!",
    });
    if (callback) callback();
  };
};

export interface TestingAuthAction {
  type: ActionAuthTypes.forTesting;
}
