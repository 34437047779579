import Constants from "@/constants";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const wpApi = createApi({
  reducerPath: "wpApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${Constants.baseUri}`,
    prepareHeaders: (headers, { endpoint }) => {
      if (endpoint !== "uploadAudioMessage") {
        headers.set("Content-Type", "application/json");
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    clearCart: builder.mutation<void, void>({
      query: () => ({
        url: "/wp-json/cocart/v2/cart/clear",
        method: "POST",
      }),
    }),
    addToCart: builder.mutation<
      { cart_key: string },
      { productId: number; quantity?: number }
    >({
      query: ({ productId, quantity = 1 }) => ({
        url: "/wp-json/cocart/v2/cart/add-item",
        method: "POST",
        body: {
          id: productId.toString(),
          quantity: quantity.toString(),
        },
      }),
    }),
    uploadAudioMessage: builder.mutation<{ audio_url: string }, FormData>({
      query: (formData) => ({
        url: "/wp-json/wp/v2/messages/upload-audio/",
        method: "POST",
        body: formData,
      }),
    }),
  }),
});

export const {
  useAddToCartMutation,
  useClearCartMutation,
  useUploadAudioMessageMutation,
} = wpApi;
