// import { Link } from "react-router-dom";
import { CheckIcon } from "@/assets/images/svg/Icons";
import { useWpSelector } from "@/selectors";
import RouteHeading from "@/components/headings/RouteHeading";
// import { Button } from "react-bootstrap";
// import { ReactComponent as PlusSquareIcon } from "@/assets/images/svg/plus-square.svg";
import "./PackagesPage.scss";
// import links from "@/constants/links";
import {
  useGetClientSubscriptionsQuery,
  useGetProductsQuery,
} from "@/api/subscriptionsApi";
import { formatDate } from "@/functions/formatDate";
import { differenceInDays } from "date-fns";
import Loader from "@/components/Loader/Loader";

// const newPackageAdvantages = [
//   "Mieć nieprzerwany dostęp do Twojego panelu H&B Academy,",
//   "Mieć już z głowy kolejną płatność",
// ];

const PackagesPage = () => {
  const { currentUser } = useWpSelector();

  const { data: subscriptions, isLoading } = useGetClientSubscriptionsQuery(
    currentUser?.id!,
    {
      skip: !currentUser?.id,
    },
  );
  const { data: allSubscriptions, isLoading: allSubscriptionsLoading } =
    useGetProductsQuery();

  const packagesHistory = subscriptions?.filter(
    (sub) => sub.status === "expired",
  );

  const activePackages = subscriptions?.filter(
    (sub) => sub.status === "active",
  );

  return (
    <>
      <div className="dashboard__account">
        <RouteHeading>AKTYWNE PAKIETY</RouteHeading>
        <div className="account">
          <div className="account__cards justify-start">
            {(isLoading || allSubscriptionsLoading) && (
              <div className="border-0 card account-plan">
                <Loader />
              </div>
            )}

            {!isLoading &&
              !allSubscriptionsLoading &&
              subscriptions &&
              activePackages?.map((pack) => {
                const packDetails = allSubscriptions?.find(
                  (sub) => sub.id === pack.line_items[0].product_id,
                );

                return (
                  <div key={pack.id} className="border-0 card account-plan">
                    <div className="card__details">
                      <div className="card__details__wrapper">
                        <h4 className="text-center text-uppercase font-italic card__heading">
                          {packDetails?.name || "..."}
                        </h4>

                        <span className="d-block card__details__wrapper--date">
                          {formatDate(pack.start_date_gmt!, true)} -{" "}
                          {formatDate(pack.next_payment_date_gmt!, true)}
                        </span>

                        <span
                          className="d-block card__details__wrapper--date"
                          style={{ color: "#7AB6FF", marginBottom: "16px" }}
                        >
                          Do końca:{" "}
                          {differenceInDays(
                            new Date(pack.next_payment_date_gmt),
                            new Date(),
                          )}{" "}
                          dni
                        </span>

                        <strong className="d-block">
                          {packDetails
                            ? packDetails?.acf?.acf_product?.text
                            : "..."}
                        </strong>
                        <ul className="p-0 m-0 list-unstyled card__list">
                          {packDetails &&
                            packDetails.acf.acf_product.list.map((el, idx) => {
                              return (
                                <li className="position-relative" key={idx}>
                                  <div className="position-absolute d-flex align-items-center svg-container">
                                    <CheckIcon />
                                  </div>
                                  <span
                                    className="d-block"
                                    dangerouslySetInnerHTML={{
                                      __html: el.item,
                                    }}
                                  ></span>
                                </li>
                              );
                            })}
                        </ul>
                        {/* <div className="text-center">
                          <Link
                            to="#"
                            className="d-inline-flex btn btn-secondary"
                          >
                            SZCZEGÓŁY PAKIETU
                          </Link>
                        </div> */}
                      </div>
                    </div>
                  </div>
                );
              })}

            {/* <div className="card acount-plan new-package-card"> TODO WHEN FUTURE SUBSCRIPTIONS FIXED
              <div className="card__details">
                <div className="card__details__wrapper">
                  <PlusSquareIcon />

                  <div>
                    <p className="text-center package-header">
                      Opłać już teraz następny pakiet, aby:
                    </p>

                    <ul className="p-0 m-0 list-unstyled card__list">
                      {newPackageAdvantages.map((item, i) => (
                        <li className="position-relative pl-20px" key={i}>
                          <div className="card__list__icon d-flex align-items-center">
                            <CheckIcon />
                          </div>
                          <span className="d-block advantage">{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <Button
                    variant="primary"
                    className="btn"
                    as={Link}
                    to={links.selectPackage}
                  >
                    KUP PAKIET
                  </Button>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <RouteHeading>HISTORIA TWOICH PAKIETÓW</RouteHeading>

        <div className="account">
          {isLoading && <Loader />}
          {!isLoading && packagesHistory && packagesHistory?.length > 0
            ? packagesHistory?.map((pack, i) => (
                <div key={i} className="history-wrapper">
                  <div className="history-wrapper__package-data">
                    <span className="title">{pack.line_items[0].name}</span>

                    <span className="date">
                      {pack.start_date_gmt && formatDate(pack.start_date_gmt)} -{" "}
                      {pack.next_payment_date_gmt &&
                        formatDate(pack.next_payment_date_gmt)}
                    </span>
                  </div>

                  <div className="history-wrapper__actions">
                    {/* <Link to="#" className="d-inline-flex btn btn-secondary">
                      SZCZEGÓŁY PAKIETU
                    </Link> */}

                    {/* <Button as={Link} to={links.selectPackage}>
                      Kup ponownie
                    </Button> */}
                  </div>
                </div>
              ))
            : "Brak danych"}
        </div>
      </div>
    </>
  );
};

export default PackagesPage;
