import { Splide, SplideSlide } from "@splidejs/react-splide";
// import "@splidejs/react-splide/dist/css/themes/splide-default.min.css";
import React, { useEffect, useState, useRef } from "react";
import {
  SliderStarIcon,
  GoogleIcon,
  CarouselArrowIcon,
} from "@/assets/images/svg/Icons";
import PageHeading from "@/components/headings/PageHeading";

import "./GoogleOpinions.scss";

const GoogleOpinions = () => {
  const splideRef = useRef<Splide | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const numberOfSlides = 10;

  const splideOptions = {
    arrows: false,
    pagination: false,
    classes: {
      arrows: "slider__arrows",
      arrow: "slider__arrow",
      prev: "slider__arrow--prev",
      next: "slider__arrow--next",
    },
  };

  const handlePrevClick = () => {
    if (splideRef.current?.splide) {
      splideRef.current.splide.go("<");
    }
  };

  const handleNextClick = () => {
    if (splideRef.current?.splide) {
      splideRef.current.splide.go(">");
    }
  };

  useEffect(() => {
    const splide = splideRef.current;
    if (splide?.splide) {
      const handleActive = (slide: { index: React.SetStateAction<number> }) => {
        setActiveIndex(slide.index);
      };
      splide.splide.on("active", handleActive);
      return () => {
        splide?.splide?.off("active");
      };
    }
  }, []);

  const selectSlide = (index: number) => {
    if (splideRef.current?.splide) {
      splideRef.current.splide.go(index);
    }
  };

  return (
    <section className="google-opinions">
      <div className="container">
        <div className="position-relative google-opinions__wrapper">
          <div className="google-opinions__col google-opinions__col--left">
            <header className="column__header">
              <span className="d-inline-block font-italic">
                DZIĘKUJE ZA ZAUFANIE
              </span>
              <PageHeading variant="h2">CO MÓWIĄ INNI?</PageHeading>
            </header>
            <div className="column__content">
              <p className="mb-0">
                Przez lata pomogłem setkom osób takich jak Ty osiągnąć ich
                fitnesowe cele, plany i marzenia. Zresztą, nie musisz wierzyć
                nam na słowo - zobacz co mówią moi podopieczni!
              </p>
            </div>
            <footer className="column__footer">
              <span>+140 opinii na Google</span>
              <div className="d-inline-block mx-0 svg-container">
                <SliderStarIcon />
              </div>
              <span>
                śr. ocena&nbsp;<strong>5,0</strong>
              </span>
            </footer>
          </div>
          <div className="google-opinions__col google-opinions__col--right">
            <Splide ref={splideRef} options={splideOptions}>
              <SplideSlide>
                <div className="slide__wrapper">
                  <header className="d-flex justify-content-between align-items-center slide__header">
                    <div className="d-flex align-items-center stars">
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                    </div>
                    <div className="icon">
                      <GoogleIcon />
                    </div>
                  </header>
                  <div className="slide__content">
                    <p className="my-0">
                      Trening z Michałem to przepaść w porównaniu do treningów z
                      innymi personalnymi trenerami . Mialem już 3 podejścia .
                      Forma w jakiej prowadzi cała godzinę zajęcie , wiedza
                      merytoryczna i praktyczna jaka się dzieli , jest
                      niesamowita . Po 3 godzinach ja byłem 3 miesiące
                      technicznie do przodu jak ostatnim razem kiedy
                      współpracowałem z trenerem. Bardzo polecam.
                    </p>
                  </div>
                  <footer className="slide__footer">
                    <span className="slide__author">Waldemar Łątka</span>
                  </footer>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="slide__wrapper">
                  <header className="d-flex justify-content-between align-items-center slide__header">
                    <div className="d-flex align-items-center stars">
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                    </div>
                    <div className="icon">
                      <GoogleIcon />
                    </div>
                  </header>
                  <div className="slide__content">
                    <p className="my-0">
                      Agnieszka jest moja trenerka od 3 lat. Schudłam i zrobiłam
                      z nią formę zycia. Mam 35 i czuje się lepiej niż wtedy
                      kiedy miałam 20. Bardzo jestem wdzięczna ze trafiłam na
                      akedemie jaka stworzyli Aga z Michałem. Mega pozytywni
                      ludzie, profesjonaliści w każdym tego słowa znaczeniu.
                      Nawet kiedy zdarzy mi się przyjść leniwa to Aga daje mi
                      taki wycisk aby właśnie udowodnić mi, ze to tylko mija
                      głowa tak myśli. Mega mega mega pozytywny zespół super
                      profesjonalistów. Od marca wkracza do Akademi mój mąż pod
                      skrzydła Miska.
                    </p>
                  </div>
                  <footer className="slide__footer">
                    <span className="slide__author">Renata Paradowska</span>
                  </footer>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="slide__wrapper">
                  <header className="d-flex justify-content-between align-items-center slide__header">
                    <div className="d-flex align-items-center stars">
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                    </div>
                    <div className="icon">
                      <GoogleIcon />
                    </div>
                  </header>
                  <div className="slide__content">
                    <p className="my-0">
                      Polecam treningi z Aga. Nie 100 a 1000 procent
                      zaangażowania. Totalny wycisk, zero jakiś Gierek i zabaw.
                      Do Agi przychodzisz na trening w konkretnym celu i 60
                      minut treningu masz właśnie absolutnego poświęcenia i
                      maksymalnego zaangażowania. Mega polecam. A trenujemy już
                      od ponad roku i nie zwolnię swojego miejsca przez
                      najbliższe kilka lat.
                    </p>
                  </div>
                  <footer className="slide__footer">
                    <span className="slide__author">Maciej Sowa</span>
                  </footer>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="slide__wrapper">
                  <header className="d-flex justify-content-between align-items-center slide__header">
                    <div className="d-flex align-items-center stars">
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                    </div>
                    <div className="icon">
                      <GoogleIcon />
                    </div>
                  </header>
                  <div className="slide__content">
                    <p className="my-0">
                      Trafiliśmy z żona pod skrzydła Michała na początku roku ,
                      za namowa naszych znajomych , którzy już korzystali z ich
                      opcji „12tygodniowej metamorfozy”. Nie mogliśmy trafic
                      lepiej. Bardzo prosty system treningowy plus naprawdę
                      przejrzysta dieta z podpowiedziami jak nie dać ciała w
                      trakcie tego procesu :) . U mnie -7kg , u żony poleciało
                      -9 kg w ciągu trzech miesięcy . Bardzo polecamy kazdemu ,
                      kto próbował już się odchudzać lecz bezskutecznie . 5
                      gwiazdek
                    </p>
                  </div>
                  <footer className="slide__footer">
                    <span className="slide__author">Krzysztof Trawiński</span>
                  </footer>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="slide__wrapper">
                  <header className="d-flex justify-content-between align-items-center slide__header">
                    <div className="d-flex align-items-center stars">
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                    </div>
                    <div className="icon">
                      <GoogleIcon />
                    </div>
                  </header>
                  <div className="slide__content">
                    <p className="my-0">
                      Bardzo polecam Michała jako trenera osobistego. Trening
                      prowadzi w taki sposób ze nigdy nie odczuwam nudy a wręcz
                      nie mogę doczekać się kolejnego. Wcześniej już trenowałam
                      z kilkoma trenerami ale zawsze po kilku treningach
                      rezygnowałam, nie czułam się odpowiednio zmotywowana i
                      byłam znudzona, z Michałem nie ma tego problemu, wspaniale
                      motywuje do dalszego działania i sprawia że chce trenować
                      więcej i więcej.
                    </p>
                  </div>
                  <footer className="slide__footer">
                    <span className="slide__author">Angelika Sadoń-Łój</span>
                  </footer>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="slide__wrapper">
                  <header className="d-flex justify-content-between align-items-center slide__header">
                    <div className="d-flex align-items-center stars">
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                    </div>
                    <div className="icon">
                      <GoogleIcon />
                    </div>
                  </header>
                  <div className="slide__content">
                    <p className="my-0">
                      Agnieszka jest profesjonalnym trenerem z z fachową wiedzą.
                      Wykazuje dużo cierpliwości i zaraża motywacją do pracy.
                      Każdy z nią trening jest dostosowany do moich potrzeb i
                      możliwości. Kobieta z niesamowitą energią życiową. Gorąco
                      polecam
                    </p>
                  </div>
                  <footer className="slide__footer">
                    <span className="slide__author">Marcel Kotwica</span>
                  </footer>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="slide__wrapper">
                  <header className="d-flex justify-content-between align-items-center slide__header">
                    <div className="d-flex align-items-center stars">
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                    </div>
                    <div className="icon">
                      <GoogleIcon />
                    </div>
                  </header>
                  <div className="slide__content">
                    <p className="my-0">
                      Z czystym sercem polecam profesjonalizm Michała. Pomimo
                      tego, że jestem kompletnym laikiem jeśli chodzi o
                      siłownię, z jego strony mogłem liczyć na bardzo dobre
                      podejście do mojej osoby, pomoc podczas treningu. Dzięki
                      jego planu treningowemu zacząłem czuć mięśnie o których
                      istnieniu nie miałem pojęcia. Polecam !!!
                    </p>
                  </div>
                  <footer className="slide__footer">
                    <span className="slide__author">Dander 1991</span>
                  </footer>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="slide__wrapper">
                  <header className="d-flex justify-content-between align-items-center slide__header">
                    <div className="d-flex align-items-center stars">
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                    </div>
                    <div className="icon">
                      <GoogleIcon />
                    </div>
                  </header>
                  <div className="slide__content">
                    <p className="my-0">
                      Trenuję z Michałem już 3. Miesiąc. Tak profesjonalnego
                      podejścia, wspaniałej atmosfery w życiu się nie
                      spodziewałam. Dopasowywane treningi, ciągła motywacja,
                      dzięki niemu przestałam myśleć o aktywności fizycznej jak
                      o karze, czekam z niecierpliwością na każdy trening!
                      Oprócz tego jest to wspaniały, ciepły i wyrozumiały
                      człowiek. Polecam z całego serca.
                    </p>
                  </div>
                  <footer className="slide__footer">
                    <span className="slide__author">Anna Kozek</span>
                  </footer>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="slide__wrapper">
                  <header className="d-flex justify-content-between align-items-center slide__header">
                    <div className="d-flex align-items-center stars">
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                    </div>
                    <div className="icon">
                      <GoogleIcon />
                    </div>
                  </header>
                  <div className="slide__content">
                    <p className="my-0">
                      Ćwiczę z Michałem już od pewnego czasu i jestem zachwycona
                      efektami, wiedzą oraz cierpliwością. Polecam każdemu, kto
                      chciałby zacząć przygodę z siłownią, a nie wie jak się za
                      to zabrać.
                    </p>
                  </div>
                  <footer className="slide__footer">
                    <span className="slide__author">Aleksandra kowal</span>
                  </footer>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="slide__wrapper">
                  <header className="d-flex justify-content-between align-items-center slide__header">
                    <div className="d-flex align-items-center stars">
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                      <div className="svg-container">
                        <SliderStarIcon />
                      </div>
                    </div>
                    <div className="icon">
                      <GoogleIcon />
                    </div>
                  </header>
                  <div className="slide__content">
                    <p className="my-0">
                      Z Michałem mam okazje trenować od kilku miesięcy. Jest
                      sportowcem, profesjonalistą i prawdziwym pasjonatem. Jego
                      atutem jest wiedza i kompetencje którymi wyróżnia się
                      wśród innych trenerów. Podchodzi indywidualnie do każdego
                      podopiecznego oraz angażuje się w każdy treningi przez co
                      współpraca z nim daje dużo przyjemności... a co
                      najważniejsze, praca w takiej atmosferze oraz z tak
                      doświadczoną osobą przekłada się na oczekiwane efekty-
                      Polecam!!!
                    </p>
                  </div>
                  <footer className="slide__footer">
                    <span className="slide__author">Marek B</span>
                  </footer>
                </div>
              </SplideSlide>
            </Splide>
            <div className="position-relative d-flex justify-content-center align-items-center slider__navigation">
              <div className="slider__arrows">
                <button
                  className={`position-absolute px-0 slider__arrow slider__arrow--prev ${
                    activeIndex === 0 ? "slider__arrow--disabled" : ""
                  }`}
                  onClick={handlePrevClick}
                >
                  <CarouselArrowIcon />
                </button>
                <button
                  className={`position-absolute px-0 slider__arrow slider__arrow--next ${
                    activeIndex === numberOfSlides - 1
                      ? "slider__arrow--disabled"
                      : ""
                  }`}
                  onClick={handleNextClick}
                >
                  <CarouselArrowIcon />
                </button>
              </div>
              <div className="slider__pagination">
                {Array.from({ length: numberOfSlides }, (_, index) => (
                  <button
                    key={index}
                    className={`px-0 item ${
                      index === activeIndex ? "active" : ""
                    }`}
                    onClick={() => selectSlide(index)}
                  >
                    <span className="d-inline-block rounded-circle"></span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GoogleOpinions;
