import PageHeading from "@/components/headings/PageHeading";
import { DetailHero, DetailHero2 } from "@/assets/images/svg/Icons";
import "./Hero.scss";

const Hero = () => {
  return (
    <section className="hero-subpage">
      <div className="container">
        <div className="position-relative hero-subpage__wrapper">
          <div className="pulse">
            <DetailHero />
          </div>
          <div className="pulse">
            <DetailHero2 />
          </div>
          <div className="hero-subpage__content">
            <span className="d-block font-italic text-center">
              FAJNIE, ŻE JESTEŚ
            </span>
            <PageHeading variant="h1">POZNAJMY SIĘ</PageHeading>
            <p className="text-center">
              Pasja do sportów fitness towarzyszy nam od zawsze. Wiem jak ciężko
              w tej branży trzeba pracować na sukces. I z chęcią podzielę się z
              Wami tą wiedzą.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
