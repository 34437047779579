import PageHeading from "@/components/headings/PageHeading";
import { DetailHero, DetailHero2 } from "@/assets/images/svg/Icons";
import "./Hero.scss";

const Hero = () => {
  return (
    <section className="hero-subpage">
      <div className="container">
        <div className="position-relative hero-subpage__wrapper">
          <div className="pulse">
            <DetailHero />
          </div>
          <div className="pulse">
            <DetailHero2 />
          </div>
          <div className="hero-subpage__content">
            <span className="d-block font-italic text-center">
              JESTEM Z WAS DUMNY!
            </span>
            <PageHeading variant="h1">
              <span>WASZE</span> METAMORFOZY
            </PageHeading>
            <p className="text-center">
              Za każdym z tych zdjęć kryje się niesamowita historia odwagi,
              dyscypliny i wewnętrznej siły. Dziękuje Wam za zaufanie.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
