import { FC, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { fetchMessages, postMessage, User } from "@/actions/wp";
import { ChatFillIcon } from "@/assets/images/svg/Icons";
import Loader from "@/components/Loader/Loader";
import { useWpSelector } from "@/selectors";
import "./UserMessages.scss";
import { useUploadAudioMessageMutation } from "@/api/wpApi";
import AddMessage from "./AddMessage";
import { useForm } from "react-hook-form";
import { useToast } from "@/components/ToastContext/ToastContext";
import { isAfter, parse } from "date-fns";

interface IUserMessages {
  id: number | null;
  client?: User;
}

export type FormValues = {
  text?: string;
  audio_url?: string | null;
};

const UserMessages: FC<IUserMessages> = ({ id, client }) => {
  const { currentUser } = useWpSelector();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { messages } = useWpSelector();
  const listRef = useRef<HTMLUListElement>(null);
  const [mediaBlobUrl, setMediaBlobUrl] = useState<string | null>(null);
  const [uploadAudioMessage] = useUploadAudioMessageMutation();
  const [clearAudioCallback, setClearAudioCallback] = useState<
    (() => void) | null
  >(null);
  const { enqueueToast } = useToast();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: { text: "", audio_url: null },
  });

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (id !== null) {
      dispatch(
        fetchMessages(String(id), (loading) => {
          setIsLoading(loading);
        }),
      );

      interval = setInterval(() => {
        dispatch(
          fetchMessages(String(id), (loading) => {
            setIsLoading(loading);
          }),
        );
      }, 100000); // Powtarzaj co 10 minuty
    }

    return () => clearInterval(interval);
  }, [dispatch, id]);

  useEffect(() => {
    if (listRef.current) {
      const scrollableList = listRef.current;
      scrollableList.scrollTop = scrollableList.scrollHeight;
    }
  }, [messages, isLoading]);

  const sortMsg = messages?.sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateA.getTime() - dateB.getTime();
  });

  const handleUpload = async (blobUrl: string) => {
    if (!blobUrl) return null;

    try {
      const response = await fetch(blobUrl);
      if (!response.ok)
        throw new Error(`Failed to fetch blob: ${response.status}`);

      const blob = await response.blob();
      const file = new File([blob], `record_${Date.now()}.mp3`, {
        type: "audio/mp3",
      });

      const formData = new FormData();
      formData.append("audio", file);

      const uploadResponse = await uploadAudioMessage(formData).unwrap();
      return uploadResponse.audio_url;
    } catch (err) {
      console.error("Upload failed:", err);
      return null;
    }
  };

  const clearAudio = () => {
    if (clearAudioCallback) {
      clearAudioCallback();
    }
  };

  const submitForm = async (values: FormValues) => {
    try {
      if (!values.text && !values.audio_url && !mediaBlobUrl) {
        return;
      }

      if (mediaBlobUrl) {
        const uploadedAudioUrl = await handleUpload(mediaBlobUrl);
        values.audio_url = uploadedAudioUrl as unknown as null;
      }

      await new Promise((resolve, reject) => {
        dispatch(
          postMessage(
            {
              receiverId: id!,
              senderId: currentUser?.id!,
              text: values.audio_url ? "" : values.text?.trim()!,
              audio_url: values.audio_url,
            },
            (res: any) => {
              if (res.status < 300) {
                values.audio_url = null;
                reset();
                setMediaBlobUrl(null);
                clearAudio();
                dispatch(fetchMessages(String(id), () => {}));
                resolve(res);
              } else {
                reject(new Error("Message sending failed"));
              }
            },
          ),
        );
      });
    } catch (error) {
      enqueueToast({
        type: "error",
        message: "Wystąpił błąd podczas wysyłania wiadomości",
      });
    }
  };

  const userRegistered = client?.user_registered;
  const userDate = parse(userRegistered!, "dd.MM.yyyy", new Date());

  const comparisonDate = parse("31.03.2025", "dd.MM.yyyy", new Date());
  const isRegisteredLaterThanComparison = isAfter(userDate, comparisonDate);

  return (
    <div className="message_wr_admin">
      {id ? (
        <div className="message_content_admin">
          <ul className="message_content_admin-list" ref={listRef}>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {isRegisteredLaterThanComparison && (
                  <>
                    <li className={"message__author"}>
                      <span>
                        Cześć :) Ciesze się, że wybrałeś moją platformę „Health
                        And Body Academy” do pomocy w prowadzeniu zdrowego stylu
                        życia i przygody z siłownią. Gwarantuje Ci , że
                        trzymając się zasad współpracy ze mną - stosując dietę ,
                        plan treningowy, cotygodniowe raporty i moje wskazówki -
                        osiągniesz sukces.
                      </span>
                    </li>

                    <li className={"message__author"}>
                      <span>
                        Zdrowo Schudniesz, nabierzesz masy mięśniowej, poprawisz
                        samopoczucie i zdrowie. Wszystko w zależności od
                        wybranych dla siebie celów i priorytetów.
                      </span>
                    </li>

                    <li className={"message__author"}>
                      <span>
                        Masz teraz dostęp do super prostego kalkulatora posiłków
                        , który szybko podpowie Ci jakich makroskladnikow użyć
                        do skomponowania idealnego posiłku dla Ciebie .
                        Przygotowujesz je na swój własnych sposob , według
                        własnych preferencji smakowych . Możesz te wybrane przez
                        siebie zestawienia produktów / dania / piec , smażyć ,
                        gotować , stosować ulubioną obróbkę termiczną lub
                        poprostu przygotowywać z nich sałatki . Z dobrej jakości
                        pieczywem robić smaczne kanapki. Ty wybierasz sobie ile
                        posiłków w ciągu dnia chcesz jeść tak żeby było Ci
                        wygodnie „trzymać dietę”.
                      </span>
                    </li>

                    <li className={"message__author"}>
                      <span>
                        Proszę wyślij mi teraz swoje aktualne zdjęcia „przód ,
                        tył , bok” . Miejsce na mnie znajdziesz właśnie w
                        zakładce „RAPORT”. Ułatwi mi to odpowiedni dobór ćwiczeń
                        pod Twoje indywidualne cele / pod Twoją sylwetke . Podaj
                        wagę , wiek, wzrost i cel współpracy ze mną. -{">"}{" "}
                        wypalenie tłuszczu, zwiększenie masy mięśniowej, poprawa
                        kondycji, rekonwalescencja po urazie/wypadku,
                        rekompozycja ciała. Będę mógł precyzyjnie i dokładnie
                        zaproponować Ci podaż makroskladników w diecie / kalorii
                        w ciągu dnia .
                      </span>
                    </li>

                    <li className={"message__author"}>
                      <span>
                        Proszę Cię (nie jest to obowiązkowe ) / jeśli masz
                        ochotę na 100% pomoc z mojej strony / zrób pakiet
                        podstawowych badań z krwi takich jak : Cukier /
                        morfologia krwi / profil tarczycowy tsh ft3 ft4 / profil
                        lipidowy / ALAT ASPAT bilirubina / (Opcjonalnie
                        testosteron , estradiol , prolaktyna ) . Po otrzymaniu
                        wyników - wyślij niezwłocznie - pomoże mi to
                        ustosunkować się do Twojego indywidualnego przypadku i
                        dobrać , jeśli to potrzeba , odpowiednie suplementy ,
                        konkretny trening i proporcje w makroskladnikow w diecie
                      </span>
                    </li>

                    <li className={"message__author"}>
                      <span>
                        Przed Tobą kawał fajnej sportowej przygody. Będę Cię
                        wspierać na tej nowej drodze, pomagać Ci przejść przez
                        ewentualne trudności , podpowiem ciekawe rozwiązania ,
                        pomogę uzyskać zdrowie oraz wymarzoną formę . Od teraz
                        działamy razem , więc mamy przynajmniej dwa razy większe
                        szanse na sukces. Jestem do Twojej dyspozycji .
                      </span>
                    </li>
                  </>
                )}

                {sortMsg?.map((msg) => {
                  const deletedMsg = !msg.text && !msg.audio_url;

                  return (
                    <li
                      key={msg.id}
                      className={`${
                        +msg.senderId !== id
                          ? "message__author"
                          : "message__caller"
                      } ${msg.audio_url ? "message-audio" : ""} ${deletedMsg ? "deleted" : ""}`}
                    >
                      {deletedMsg && <span>Usunięto..</span>}

                      {msg.text &&
                        msg.text.split("\n").map((item, key) => (
                          <span key={key}>
                            {item}
                            <br />
                          </span>
                        ))}

                      {msg.audio_url && (
                        <audio controls>
                          <source src={msg.audio_url} type="audio/mp3" />
                        </audio>
                      )}
                    </li>
                  );
                })}
              </>
            )}
          </ul>

          <form
            onSubmit={handleSubmit(submitForm)}
            className="message__form_wrapper"
          >
            <AddMessage
              setMediaBlobUrl={setMediaBlobUrl}
              setClearAudioCallback={setClearAudioCallback}
              control={control}
            />

            <div className="submit-btn-wrapper">
              <Button
                className="send_button"
                variant="primary"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <span className="spinner-grow spinner-grow-sm" />
                ) : (
                  "WYŚLIJ"
                )}
              </Button>
            </div>
          </form>
        </div>
      ) : (
        <div className="cap">
          <ChatFillIcon />
        </div>
      )}
    </div>
  );
};

export default UserMessages;
