import RootLayout from "@/layouts/RootLayout";
import "./SignIn.scss";
import SignInForm from "./SignInForm";

const SignIn = () => {
  return (
    <RootLayout>
      <section className="position-relative overflow-hidden form-register login_wr">
        <h2 className="form-heading text-center login-heading">ZALOGUJ SIĘ</h2>
        <SignInForm />
      </section>
    </RootLayout>
  );
};

export default SignIn;
